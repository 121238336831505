import {
  EmployeeByIdExtended,
  ServerExtendedServiceType,
  ServerSalarySettingServiceInsertInput,
  ServerSalarySettingType,
  useCreateEmployeeSalarySettings,
  useFetchAllExtendedServices,
  useFetchCountSalarySettingsByEmployeeId,
  useFetchCurrentBranchTimezone,
  useFetchSalarySettingsById,
} from '@expane/data'
import { createCurrentDate, replaceDateTimezone, startOfDay } from '@expane/date'
import { RATE_OPTIONS } from '@expane/logic/salarySettings'
import { CloseButton, Dialog, Modal, usePopupOpenState, useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { SalarySettingDialogPlaceholder } from 'widgets/SalarySettingsDialogs/SalarySettingDialogPlaceholder'
import {
  PREFERRED_PAYMENT_OPTIONS,
  SalarySettingFormValues,
  transformEmployeeToSalarySettingServicesForForm,
  transformSalarySettingServicesForForm,
} from './logic'
import { SalarySetting } from './SalarySetting'

interface EmployeeSalarySettingDialogProps {
  salarySettingId?: number
  employee: EmployeeByIdExtended
  isCreate: boolean
  closeDialog: () => void
}

const EmployeeSalarySettingDialog: FC<EmployeeSalarySettingDialogProps> = observer(
  ({ salarySettingId, employee, closeDialog, isCreate }) => {
    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: salarySettingById, isLoading: isLoadingSalarySettingById } =
      useFetchSalarySettingsById(salarySettingId, timezone)
    const { data: services, isLoading: isLoadingServices } = useFetchAllExtendedServices(
      branchId,
      'all',
    )

    const { data: countSalarySettings } = useFetchCountSalarySettingsByEmployeeId(
      employee.id,
      branchId,
    )

    const isFirstSalarySetting = !countSalarySettings

    const isLoading = (!isCreate && isLoadingSalarySettingById) || isLoadingServices || !timezone
    const isNoData = (!isCreate && !salarySettingById) || !services || !branchId

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (!isLoading && isNoData) return null

    return (
      <Modal close={closeDialog}>
        <Dialog>
          {isLoading ? (
            <SalarySettingDialogPlaceholder closeDialog={closeDialog} isCreate={isCreate} />
          ) : (
            <SalarySettingDialogLogic
              services={services ?? []}
              salarySetting={salarySettingById}
              timezone={timezone}
              isCreate={isCreate}
              employee={employee}
              closeDialog={closeDialog}
              branchId={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                branchId!
              }
              areEmployeesFirstSalarySetting={isFirstSalarySetting ?? false}
            />
          )}
        </Dialog>
      </Modal>
    )
  },
)

interface SalarySettingDialogLogicProps
  extends Omit<EmployeeSalarySettingDialogProps, 'salarySettingId'> {
  salarySetting: ServerSalarySettingType | undefined
  services: ServerExtendedServiceType[]
  timezone: string
  branchId: number
  areEmployeesFirstSalarySetting: boolean
}

const SalarySettingDialogLogic: FC<SalarySettingDialogLogicProps> = ({
  services,
  salarySetting,
  employee,
  isCreate,
  closeDialog,
  timezone,
  branchId,
  areEmployeesFirstSalarySetting,
}) => {
  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const { mutateAsync: createEmployeeSalarySetting } = useCreateEmployeeSalarySettings()

  const salarySettingServices = isCreate
    ? transformEmployeeToSalarySettingServicesForForm(employee)
    : salarySetting
    ? transformSalarySettingServicesForForm(salarySetting)
    : []

  const { control, handleSubmit, setValue, resetField, clearErrors } =
    useForm<SalarySettingFormValues>({
      defaultValues: {
        start: salarySetting ? salarySetting.start : startOfDay(createCurrentDate(timezone)),
        end: salarySetting?.end ?? undefined,
        rateType: salarySetting
          ? salarySetting.rateType === 1
            ? RATE_OPTIONS[0]
            : RATE_OPTIONS[1]
          : RATE_OPTIONS[0],
        rateValue: salarySetting?.rateValue ? salarySetting.rateValue.toString() : '0',
        referralPercentage: salarySetting?.referralPercentage
          ? salarySetting.referralPercentage.toString()
          : '0',
        preferredPayment: salarySetting?.employee.preferredPayment
          ? salarySetting.employee.preferredPayment.toString()
          : PREFERRED_PAYMENT_OPTIONS[0].id,
        salarySettingServices,
      },
    })

  const mutateSalarySetting = async data => {
    const salarySettingServicesInsertInput: ServerSalarySettingServiceInsertInput[] =
      data.salarySettingServices.map(item => ({
        serviceId: item.serviceId,
        type: item.type,
        value: Number(item.value),
        dependsOnRealizedValue: item.dependsOnRealizedValue,
        fallbackType: item.dependsOnRealizedValue && item.fallbackType ? item.fallbackType : null,
        fallbackValue:
          item.dependsOnRealizedValue && item.fallbackValue ? Number(item.fallbackValue) : null,
      }))

    const result = await createEmployeeSalarySetting({
      employeeId: employee.id,
      rateType: data.rateType.id,
      rateValue: Number(data.rateValue),
      referralPercentage: Number(data.referralPercentage),
      start: replaceDateTimezone(data.start, timezone),
      salarySettingServices: { data: salarySettingServicesInsertInput },
      branchId,
    })

    if (result.insertSalarySetting) {
      openSnackbar(t('salary.setUpSuccessfully'), 'success')
    } else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const submitHandler: SubmitHandler<SalarySettingFormValues> = async data => {
    const { rateValue, salarySettingServices, referralPercentage } = data

    const areAllSettingsServiceWithoutValue = salarySettingServices.every(
      salaryRate => salaryRate.value === '0',
    )

    if (areAllSettingsServiceWithoutValue && rateValue === '0' && referralPercentage === '0') {
      showConfirmation({
        title: t('saveConfirmation'),
        description: t('salarySetting.confirmation'),
        onConfirm: () => mutateSalarySetting(data),
      })
    } else await mutateSalarySetting(data)
  }

  return (
    <>
      <Dialog.Title>{t('salarySetting.name')}</Dialog.Title>
      <Dialog.Body className="w-288 h-120">
        <SalarySetting
          timezone={timezone}
          services={services}
          control={control}
          setValue={setValue}
          resetField={resetField}
          disabled={!isCreate}
          isCreate={isCreate}
          clearErrors={clearErrors}
          employeeId={employee.id}
          areEmployeesFirstSalarySetting={areEmployeesFirstSalarySetting}
        />
      </Dialog.Body>
      <Dialog.Footer>
        {isCreate && <SaveButton onClick={handleSubmit(submitHandler)} />}
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>

      {confirmationModal}
    </>
  )
}

interface OpenSalarySettingDialogProps {
  salarySettingId?: number
  employee: EmployeeByIdExtended
}

export const useOpenSalarySettingDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogSalarySettingId = useRef<number | undefined>()
  const dialogEmployee = useRef<EmployeeByIdExtended>()
  const dialogIsCreate = useRef<boolean>(false)

  const openEditSalarySettingDialog = ({
    salarySettingId,
    employee,
  }: OpenSalarySettingDialogProps &
    Required<Pick<OpenSalarySettingDialogProps, 'salarySettingId'>>) => {
    dialogSalarySettingId.current = salarySettingId
    dialogEmployee.current = employee
    dialogIsCreate.current = false
    openPopup()
  }

  const openCreateSalarySettingDialog = ({ employee }: OpenSalarySettingDialogProps) => {
    dialogSalarySettingId.current = undefined
    dialogEmployee.current = employee
    dialogIsCreate.current = true
    openPopup()
  }

  const salarySettingDialog =
    isOpen && dialogEmployee.current ? (
      <EmployeeSalarySettingDialog
        salarySettingId={dialogSalarySettingId.current}
        employee={dialogEmployee.current}
        closeDialog={closePopup}
        isCreate={dialogIsCreate.current}
      />
    ) : null

  return { openEditSalarySettingDialog, openCreateSalarySettingDialog, salarySettingDialog }
}
