import { useFetchBranches, useFetchEmployeeSettingsByEmployeeId } from '@expane/data'
import { useAreManyEmployeesAllowed } from '@expane/logic/billing'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { useUpdateColorThemeOnLoadApp } from '@expane/logic/theme'
import { Spinner } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { BrowserHistory } from 'history'
import i18next from 'i18next'
import { useCheckBrowser } from 'logic/hooks/useCheckBrowser'
import { useMobileViewport } from 'logic/hooks/useMobileViewport'
import { observer } from 'mobx-react-lite'
import { FC, PropsWithChildren, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Router, useLocation, useNavigate } from 'react-router-dom'
import {
  AreManyEmployeesAllowed,
  CreateBusinessType,
  Modules,
  Permissions,
  routes,
} from 'routes/logic'
import { BrowserHistoryListener } from 'services/navigation'
import { reportError, SentryRoutes } from 'services/sentry'
import { useTelephonySocket } from 'services/telephony'
import { store } from 'store'
import { useOpenClientDialogOnCall } from 'widgets/ClientDialog'
import { useOpenIncomingCallDialog } from 'widgets/IncomingCallDialog'
import { TopBar } from 'widgets/TopBar'
import { GetFeatureFlagFunc, useFeatureFlags } from '@expane/logic/featureFlags'

export const CustomRouter: FC<PropsWithChildren<{ history: BrowserHistory }>> = ({
  children,
  history,
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}

export const useChangeBrowserTitle = () => {
  const location = useLocation()

  const allRoutes = [
    ...routes.getAllFlatRoutes(null, null, null),
    ...routes.getSettingsRoutes({ permissions: null, enabledModules: null }),
    ...routes.getCreateBusinessStepsRoutes(CreateBusinessType.extended, null, null),
  ]

  const route = allRoutes.find(el => el.path === location.pathname)
  const title = route?.parentRoute
    ? `${i18next.t(route.parentRoute.name)} > ${i18next.t(route.name)}`
    : i18next.t(route?.name ?? '')

  useEffect(() => {
    const prevTitle = document.title
    if (title) document.title = title

    return () => {
      if (prevTitle !== title) document.title = prevTitle
    }
  }, [title])
}

const useRedirectToErrorPage = (
  permissions: Permissions,
  enabledModules: Modules,
  areManyEmployeesAllowed: AreManyEmployeesAllowed,
  getFeatureFlag: GetFeatureFlagFunc,
) => {
  const location = useLocation()
  const navigate = useNavigate()

  const allowedRoutes = [
    ...routes.getAllFlatRoutes(
      permissions,
      enabledModules,
      areManyEmployeesAllowed,
      getFeatureFlag,
    ),
    ...routes.getSettingsRoutes({ permissions, enabledModules, getFeatureFlag }),
    ...routes.getCreateBusinessStepsRoutes(
      CreateBusinessType.extended,
      enabledModules,
      areManyEmployeesAllowed,
      getFeatureFlag,
    ),
  ]

  if (!permissions) return

  if (allowedRoutes.some(r => r.path === location?.pathname)) return

  navigate('/404', { replace: true })
}

const useRedirects = ({
  isAuthorised,
  isEmployeeMode,
  isServerOffline,
  isRefererMode,
}: {
  isAuthorised: boolean
  isEmployeeMode: boolean
  isServerOffline: boolean
  isRefererMode: boolean
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const pathsForRedirect = useMemo(
    () => ({
      offline: '/offline',
      clientMode: '/client-mode',
    }),
    [],
  )

  const fromPath = useRef<string>('/')

  useEffect(() => {
    // Здесь обновляем ссылку на страницу каждый раз при смене страницы
    // Нужно для дальнейшего возврата на эту страницу
    // Дополнительно проверяем, чтобы не записывались ссылки на которые мы делаем редирект
    if (
      !isServerOffline &&
      fromPath.current !== location.pathname &&
      !Object.values(pathsForRedirect).includes(location.pathname)
    ) {
      fromPath.current = location.pathname
      return
    }
    // Редирект на офлайн страницу
    if (isServerOffline) {
      navigate(pathsForRedirect.offline, { replace: true })
      return
    }
    // Редирект из офлайн страницы на сохраненную в fromPath
    if (!isServerOffline && location.pathname === pathsForRedirect.offline) {
      navigate(fromPath.current, { replace: true })
      return
    }
    // Редирект, если режим клиента
    if (isAuthorised && !isEmployeeMode) {
      navigate(pathsForRedirect.clientMode, { replace: true })
      return
    }
    if (isRefererMode) {
      navigate(pathsForRedirect.clientMode, { replace: true })
      return
    }
    // Редирект из страницы режима клиента на сохраненную в fromPath
    if (isAuthorised && isEmployeeMode && location.pathname === pathsForRedirect.clientMode) {
      navigate(fromPath.current, { replace: true })
    }
  }, [
    location.pathname,
    isServerOffline,
    isAuthorised,
    isEmployeeMode,
    pathsForRedirect,
    navigate,
    isRefererMode,
  ])
}

export const AppRoutes: FC = observer(() => {
  const isAuthorised = store.me.isAuthorised
  const isEmployeeMode = store.me.isEmployeeMode
  const isServerOffline = store.serverStatus.offline
  const isRefererMode = store.me.isRefererMode

  const { data: myPermissions } = useFetchMyPermissions(isAuthorised)
  const { enabledModules } = useBusinessModulesSettings({
    enabled: isAuthorised,
  })
  const { areManyEmployeesAllowed } = useAreManyEmployeesAllowed({ enabled: isAuthorised })
  const { getFeatureFlag } = useFeatureFlags()

  const isMobile = useMobileViewport()
  useUpdateColorThemeOnLoadApp(reportError)
  useCheckBrowser()
  useChangeBrowserTitle()
  useRedirectToErrorPage(myPermissions, enabledModules, areManyEmployeesAllowed, getFeatureFlag)
  useRedirects({ isAuthorised, isEmployeeMode, isServerOffline, isRefererMode })

  const { openCreateClientDialogOnCall, openEditClientDialogOnCall, clientDialog } =
    useOpenClientDialogOnCall()
  const { incomingCallDialog, openIncomingCallDialog, closeIncomingCallDialog } =
    useOpenIncomingCallDialog({
      openEditDialog: openEditClientDialogOnCall,
      openCreateDialog: openCreateClientDialogOnCall,
    })
  useTelephonySocket({
    onCall: openIncomingCallDialog,
    onCallStop: closeIncomingCallDialog,
  })

  // sync with mobile app
  const { i18n } = useTranslation()

  const { data: myEmployeeSettings } = useFetchEmployeeSettingsByEmployeeId(store.me.employeeId)
  useEffect(() => {
    if (myEmployeeSettings?.lang) {
      i18n.changeLanguage(myEmployeeSettings.lang)
    }
  }, [i18n, myEmployeeSettings?.lang])

  const { data: branches } = useFetchBranches()
  useEffect(() => {
    if (!store.branch.branchId && branches && isAuthorised) {
      store.branch.setBranchId(branches[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.branch.branchId, branches, isAuthorised])

  const appRoutes = useMemo(
    () => (
      <>
        {routes
          .getAllRoutes(myPermissions, enabledModules, areManyEmployeesAllowed)
          .map(({ path, element: Component, children, isPrivate, name }) => (
            <Route
              key={path ?? name}
              path={path}
              element={
                <RouteElementWrapper isPrivate={isPrivate}>
                  <Component />
                </RouteElementWrapper>
              }
              children={children?.map(({ path, element: Component, isPrivate }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <RouteElementWrapper isPrivate={isPrivate}>
                      <Component />
                    </RouteElementWrapper>
                  }
                />
              ))}
            />
          ))}

        <Route path="/" element={<Navigate to="/bookings" replace />} />
        <Route path="/settings" element={<Navigate to="/settings/main" replace />} />
        <Route
          path="/create-business"
          element={<Navigate to="/create-business/authentication" replace />}
        />
      </>
    ),

    [myPermissions, enabledModules, areManyEmployeesAllowed],
  )

  return (
    <>
      <BrowserHistoryListener />

      {isEmployeeMode && !isMobile && <TopBar />}
      <div
        className={
          'bg-main w-screen min-w-256 mx-auto flex flex-1 items-stretch flex-col overflow-auto isolate'
        }
      >
        <SentryRoutes>
          {appRoutes}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </SentryRoutes>
      </div>

      {incomingCallDialog}
      {clientDialog}
    </>
  )
})

export const RouteElementWrapper: FC<PropsWithChildren<{ isPrivate: boolean }>> = observer(
  ({ children, isPrivate }) => {
    const location = useLocation()
    const navigate = useNavigate()

    if (store.me.isAuthorised) return <>{children}</>

    if (store.auth.isWaitingForAuthAfterAuthPopup) return <Spinner expandCentered />

    if (store.auth.isInitialAuthStateChangedCalled) {
      if (isPrivate) {
        navigate('/auth', {
          state: {
            from: location.pathname,
            errorMessage: location.state?.errorMessage,
          },
        })
        return null
      } else {
        return <>{children}</>
      }
    }

    return <Spinner expandCentered />
  },
)
