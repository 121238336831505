import { gql } from 'graphql-request'
import { leadFragment } from '../lead/lead.fragments'
import { cardFragment, giftCardFragment, subscriptionFragment } from '../card/card.fragments'

export const clientBriefFragment = gql`
  fragment clientBriefType on client {
    id
    firstName
    lastName
    birthDate
    email
    photo
    clientTags {
      tag {
        id
        name
        color
      }
    }
  }
`

export const clientBriefWithTagFragment = gql`
  fragment clientBriefWithTagType on client {
    id
    firstName
    lastName
    photo
    leadId
    clientSettings {
      preferredConnections
    }
    clientTags {
      tag {
        id
        name
        color
      }
    }
    createdAt
  }
`

export const clientBriefForCalendarFragment = gql`
  ${clientBriefWithTagFragment}
  fragment clientBriefForCalendarType on client {
    ...clientBriefWithTagType
    birthDate
  }
`

export const clientByIdFragment = gql`
  ${leadFragment}
  fragment clientByIdType on client {
    birthDate
    firstName
    gender
    id
    lastName
    middleName
    photo
    maximumDebt
    email
    managingEmployeeId
    calendarId
    clientTags {
      tag {
        id
        name
        color
      }
    }
    lead {
      ...leadType
    }
    archivedInBranches {
      branchId
    }
    clientRelationsWhereClientIsFirst {
      secondClient {
        id
        firstName
        lastName
      }
      type
    }
    clientRelationsWhereClientIsSecond {
      firstClient {
        id
        firstName
        lastName
      }
      type
    }
    clientSettings {
      preferredConnections
      notificationSettings
    }
    hasTelegram
    hasViber
  }
`
export const clientBriefsAndCardsFragment = gql`
  ${clientBriefFragment}
  ${cardFragment}
  fragment clientBriefsAndCardsType on client {
    ...clientBriefType
    cards(
      where: { cardTemplate: { branchId: { _eq: $branchId } }, canceledDate: { _is_null: true } }
    ) {
      ...cardType
    }
  }
`

export const myClientFragment = gql`
  ${subscriptionFragment}
  ${giftCardFragment}
  fragment myClientType on client {
    id
    firstName
    lastName
    birthDate
    photo
    phone
    additionalInformation
    email
    clientSettings {
      lang
      notificationSettings
      preferredConnections
    }
    subscriptions: cards(
      where: {
        # Абонемент може бути доступний для всіх філій
        cardTemplate: {
          _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
        }
        canceledDate: { _is_null: true }
        type: { _eq: 1 }
      }
    ) {
      ...subscriptionType
    }
    giftCards: cards(
      where: {
        cardTemplate: { branchId: { _eq: $branchId } }
        canceledDate: { _is_null: true }
        type: { _eq: 2 }
      }
    ) {
      ...giftCardType
    }
    calendarId
    hasViber
    hasTelegram
  }
`

export const clientWithSubscriptionsFragment = gql`
  ${subscriptionFragment}
  ${clientByIdFragment}
  fragment clientWithSubscriptionsType on client {
    ...clientByIdType
    # fetch only subscriptions
    subscriptions: cards(
      where: {
        cardTemplate: { branchId: { _eq: $branchId } }
        canceledDate: { _is_null: true }
        type: { _eq: 1 }
      }
    ) {
      ...subscriptionType
    }
  }
`
