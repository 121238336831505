import { RATE_OPTIONS } from '@expane/logic/salarySettings'
import {
  Checkbox,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  InputLabel,
  PlaceholderInput,
  RadioGroupButtons,
  Spinner,
} from '@expane/ui'
import { translateData } from 'logic/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PlaceholderHorizontalButtonSwitch } from 'ui/PlaceholderContent'
import { ApplyButton, SaveButton } from 'widgets/Buttons'
import { PREFERRED_PAYMENT_OPTIONS } from 'widgets/SalarySettingsDialogs/logic'

export const SalarySettingDialogPlaceholder: FC<
  CommonPlaceholderDialogProps & { type?: 'single' | 'group' | 'selectedGroup'; isCreate: boolean }
> = ({ isCreate, closeDialog, type = 'single' }) => {
  const { t } = useTranslation()

  const bodyStyle = `w-288 ${type === 'single' ? 'h-120' : 'h-138'}`

  return (
    <>
      <Dialog.Title>{t('salarySetting.name')}</Dialog.Title>
      <Dialog.Body className={bodyStyle}>
        {type !== 'single' && (
          <PlaceholderInput label={t('employees.name')} className="w-1/2 mb-2" />
        )}
        <div className="h-120">
          {type === 'selectedGroup' ? (
            <Spinner expandCentered />
          ) : (
            <>
              <div className="flex pb-4">
                <PlaceholderInput label={t('startDate')} className="w-40 mr-2" />
                {!isCreate && <PlaceholderInput label={t('endDate')} className="w-40 mr-2" />}

                <RadioGroupButtons
                  label={t('salary.rateType')}
                  options={translateData(RATE_OPTIONS, t)}
                  value={RATE_OPTIONS[0]}
                  onChange={() => {
                    return
                  }}
                  className="w-30 pr-2"
                  disabled
                />

                <PlaceholderInput label={t('amount')} className="w-40 pr-2" />

                <PlaceholderHorizontalButtonSwitch
                  label={t('salary.preferredPayment')}
                  className="ml-auto"
                  options={translateData(PREFERRED_PAYMENT_OPTIONS, t)}
                />
              </div>
              {isCreate && (
                <div className="flex items-center justify-end">
                  <InputLabel label={`${t('servicesAll')}: `} />

                  <PlaceholderInput className="w-64 mx-2" />
                  <PlaceholderInput className="w-20 mr-2" width="small" />
                  <Checkbox label={t('fromRealized')} checked={false} disabled />
                  <PlaceholderInput className="w-64 mx-2" />
                  <Input className="w-20 mr-2" />

                  <ApplyButton type="outline" disabled />
                </div>
              )}
              <div className="pl-2 h-80">
                <Spinner expandCentered />
              </div>
            </>
          )}
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
