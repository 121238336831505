import { CancelButton, CloseButton, Dialog, Modal, Textarea, usePopupOpenState } from '@expane/ui'
import { useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type ConfirmationAndReasonArgs = {
  description?: string
  onConfirm: (reason: string | null) => void
  onDeny?: () => void
}
export const useShowCancelBookingPopup = () => {
  const { t } = useTranslation()

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const confirmationDescription = useRef('')
  const onConfirmFunction = useRef<(reason: string | null) => void | undefined>()
  const onDenyFunction = useRef<() => void | undefined>()

  const { handleSubmit, register, reset } = useForm<{ reason: string }>()

  const showConfirmCancelBooking = ({
    onConfirm,
    onDeny,
    description,
  }: ConfirmationAndReasonArgs) => {
    onConfirmFunction.current = onConfirm
    if (onDeny) onDenyFunction.current = onDeny
    confirmationDescription.current = description ?? t('cancelBooking.description')
    openPopup()
  }

  const confirmAction: SubmitHandler<{ reason: string }> = ({ reason }) => {
    closePopup()
    onConfirmFunction.current?.(reason || null)
    reset()
  }

  const denyAction = () => {
    closePopup()
    onDenyFunction.current?.()
    reset()
  }

  const cancelBookingModal = isOpen ? (
    <Modal close={closePopup}>
      <Dialog>
        <Dialog.Title>{t('cancelBooking.title')}</Dialog.Title>
        <Dialog.Body>
          <Textarea
            label={confirmationDescription.current}
            placeholder={t('placeholders.reason')}
            {...register('reason')}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <CancelButton onClick={handleSubmit(confirmAction)} />

          <CloseButton onClick={denyAction} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  ) : null

  return {
    cancelBookingModal,
    showConfirmCancelBooking,
  }
}
