import { gql } from 'graphql-request'

export const promoCodeFragment = gql`
  fragment promoCodeType on promoCode {
    id
    createdAt
    description
    endAt
    term
    discount
    businessPromoCodes {
      businessId
    }
    referrer {
      id
      name
      email
    }
  }
`
