import { FC, useState } from 'react'
import { HorizontalTabs } from 'ui/HorizontalTabs'
import { SocialMediaLinks } from 'pages/SettingsPage/BusinessLandingSettings/Blocks/SocialMediaLinks'
import { NewOnlineBookingURLs } from 'pages/SettingsPage/BusinessLandingSettings/OnlineBookingURLs'
import { UISettings } from 'pages/SettingsPage/BusinessLandingSettings/Blocks/UISettings'
import { SaveButton } from 'widgets/Buttons'
import { BusinessLandingSettingsFormData } from 'pages/SettingsPage/BusinessLandingSettings/logic'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useUpdateFile } from '@expane/logic/file'
import { BusinessLandingSettingsType, useUpdateBusinessLandingSettings } from '@expane/data'
import { useSnackbar } from '@expane/widgets'
import { useTranslation } from 'react-i18next'
import { Instruction } from 'pages/SettingsPage/BusinessLandingSettings/Instruction'
import {
  instructionTabs,
  InstructionTabs,
} from 'pages/SettingsPage/BusinessLandingSettings/Instruction/Tabs'

export const BusinessLandingsLogic: FC<{
  businessLandingSettings: BusinessLandingSettingsType
}> = ({ businessLandingSettings }) => {
  const updatePhoto = useUpdateFile()
  const { mutateAsync: mutateUpdateBusinessLandingSettings } = useUpdateBusinessLandingSettings()

  const { t } = useTranslation()

  const [openSnackBar] = useSnackbar()

  const [activeTabId, setActiveTabId] = useState(tabs[0].id)
  const [instructionActiveTabId, setInstructionActiveTabId] = useState(instructionTabs[0].id)

  const { control, handleSubmit, formState, reset, getFieldState } =
    useForm<BusinessLandingSettingsFormData>({
      defaultValues: {
        bannerTitle: businessLandingSettings.bannerTitle ?? '',
        bannerDescription: businessLandingSettings.bannerDescription ?? '',
        bannerImage: businessLandingSettings.bannerImage ?? '',
        path: businessLandingSettings.path ?? '',
        images: businessLandingSettings.images.map(url => ({ url })),
        facebook: businessLandingSettings.facebook ?? '',
        instagram: businessLandingSettings.instagram ?? '',
        telegram: businessLandingSettings.telegram ?? '',
        viber: businessLandingSettings.viber ?? '',
        theme: businessLandingSettings.theme ?? 'primary',
      },
    })

  const submitHandler: SubmitHandler<BusinessLandingSettingsFormData> = async data => {
    const bannerImage = await updatePhoto({
      prevFile: businessLandingSettings?.bannerImage ?? undefined,
      file: data.bannerImage,
    })

    // TODO: Подивитись чи не викликає проблеми такий флоу, і якщо треба покращити (заливати напряму в GCS SignedUrl)
    // TODO: Додати в GCF в scheduler перевірку файлів які не використовуються і видаляти їх
    // Бо з таким флоу у нас старі файли залишаються у сховищі
    const images: string[] = []

    if (getFieldState('images').isDirty) {
      // Завантажуємо по черзі всі файли
      for (const image of data.images) {
        // Оновлюємо тільки якщо тут новий файл
        if (image.url && image.url.includes('base64')) {
          const url = await updatePhoto({
            file: image.url,
          })

          if (url) images.push(url)
          // інакше просто залишаємо ставрий url
        } else if (image.url) {
          images.push(image.url)
        }
      }
    }

    const result = await mutateUpdateBusinessLandingSettings({
      id: businessLandingSettings.id,
      serverBusinessLandingSetInput: {
        path: data.path || null,
        bannerTitle: data.bannerTitle,
        bannerDescription: data.bannerDescription,
        bannerImage,
        facebook: data.facebook,
        viber: data.viber,
        instagram: data.instagram,
        telegram: data.telegram,
        theme: data.theme,
        ...(images.length > 0 ? { images } : undefined),
      },
    })

    if (result.updateBusinessLandingById.id) {
      // Обновляем значение после загрузки
      reset({
        bannerTitle: data.bannerTitle,
        bannerDescription: data.bannerDescription,
        bannerImage: bannerImage,
        path: data.path,
        images:
          images.length > 0
            ? images.map(url => ({ url }))
            : businessLandingSettings.images.map(url => ({ url })), // Новые или прежние изображения
        facebook: data.facebook,
        instagram: data.instagram,
        telegram: data.telegram,
        viber: data.viber,
        theme: data.theme,
      })
      openSnackBar(t('changesSaved'), 'success', 3000)
    } else openSnackBar(t('submitError'), 'error', 3000)
  }

  const isEnabled = businessLandingSettings.enabled

  return (
    <div>
      {isEnabled ? (
        <>
          <div className={'flex justify-between'}>
            <SaveButton
              onClick={handleSubmit(submitHandler)}
              disabled={formState.isSubmitting || !formState.isDirty}
              spinner={formState.isSubmitting}
              className={'self-end'}
            />

            <div className={'flex-1 ml-4'}>
              <HorizontalTabs
                tabs={tabs.map(tab => ({ ...tab, label: t(tab.label) }))}
                activeTabId={activeTabId}
                setActiveTabId={async id => {
                  if (formState.isDirty) await handleSubmit(submitHandler)() // Сабмит формы перед переключением таба
                  setActiveTabId(id) // Переключаем таб только после успешного сабмита
                }}
                title={''}
              />
            </div>
          </div>

          <div className={'pt-4'}>
            {activeTabId === tabs[0].id && (
              <UISettings control={control} disabled={formState.isSubmitting} />
            )}
            {activeTabId === tabs[1].id && (
              <SocialMediaLinks control={control} disabled={formState.isSubmitting} />
            )}
            {activeTabId === tabs[2].id && (
              <NewOnlineBookingURLs
                businessLandingSettings={businessLandingSettings}
                control={control}
                disabled={formState.isSubmitting}
              />
            )}
          </div>
        </>
      ) : (
        <div>
          <InstructionTabs
            activeTabId={instructionActiveTabId}
            setActiveTabId={setInstructionActiveTabId}
          />

          <Instruction activeTabId={instructionActiveTabId} />
        </div>
      )}
    </div>
  )
}

const tabs = [
  {
    id: 0,
    label: 'businessLandingSettings.design',
  },
  {
    id: 1,
    label: 'businessLandingSettings.socMedia',
  },
  { id: 3, label: 'businessLandingSettings.links' },
]
