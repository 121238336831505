import { gql } from 'graphql-request'
import { request, useMutation, reportGqlError } from '../../api'
import { ServerDeleteFileResult, ServerUploadFileResult } from '../../generated/graphql-types'

export function useUploadFile() {
  return useMutation(
    async ({
      base64File,
      isDocument,
    }: {
      base64File: string
      isDocument?: boolean
    }): Promise<{ uploadFile: ServerUploadFileResult }> => {
      return request(
        gql`
          mutation ($base64File: String!, $isDocument: Boolean) {
            uploadFile(base64File: $base64File, isDocument: $isDocument) {
              url
            }
          }
        `,
        { base64File, isDocument },
      )
    },
    { onError: reportGqlError },
  )
}

export function useDeleteFile() {
  return useMutation(
    async ({ url }: { url: string }): Promise<{ deleteFile: ServerDeleteFileResult }> => {
      return request(
        gql`
          mutation ($url: String!) {
            deleteFile(url: $url) {
              ok
              message
            }
          }
        `,
        { url },
      )
    },
    { onError: reportGqlError },
  )
}
