import { gql } from 'graphql-request'
import { scheduleFragment } from '../schedule/schedule.fragments'

export const branchFinancialSettingsFragment = gql`
  fragment branchFinancialSettingsType on branch {
    id
    currency
    displayCoins
  }
`

export const branchBriefFragment = gql`
  fragment branchBriefType on branch {
    id
    name
    timezone
    business {
      id
    }
  }
`

export const branchFragment = gql`
  ${scheduleFragment}
  ${branchFinancialSettingsFragment}
  ${branchBriefFragment}
  fragment branchType on branch {
    ...branchBriefType
    description
    createdAt
    geocode
    address
    phone
    ...branchFinancialSettingsType
    defaultAccount {
      id
    }
    defaultStorage {
      id
    }
    locationId
    schedule {
      ...scheduleType
    }
  }
`

export const anonymouslyBranchFragment = gql`
  fragment anonymouslyBranchType on branch {
    id
    name
    timezone
    address
    phone
    currency
    geocode
    scheduleData
  }
`
