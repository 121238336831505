import {
  BookingInsertInput,
  BookingUnion,
  invalidateBookings,
  UpdateBookingType,
  useCreateBookings,
  useCreateUnitedBooking,
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
  useFetchLocations,
  useUpdateBookings,
  validateBookings,
} from '@expane/data'
import { zonedTimeToUtc } from '@expane/date'
import { generateMessageAfterValidationForMultiBooking } from '@expane/logic/bookingChecks'
import { useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { prepareBookingData } from 'logic/booking'
import { Control, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import {
  BookingMultiServicesDialogFormValues,
  MultiServiceDto,
} from 'widgets/BookingMultiServicesDialog/index'
import { ConfirmationDescription } from 'widgets/ConfirmationDescription'
import { getIsBookingDone } from '@expane/logic/booking'

interface UseSubmitBookingArgs {
  isCreate: boolean
  // if not create, we have unitedBookingId
  unitedBookingId: number | undefined
  dayStart: number
  dayEnd: number
  control: Control<BookingMultiServicesDialogFormValues>
  initialBookings: Array<BookingUnion>
}

export const useSubmitMultiServicesBooking = ({
  isCreate,
  unitedBookingId,
  control,
  initialBookings,
}: UseSubmitBookingArgs) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!

  const { data: locations } = useFetchLocations(branchId)
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)

  const { mutateAsync: createMultiServiceBooking } = useCreateUnitedBooking()
  const { mutateAsync: updateBookings } = useUpdateBookings()
  const { mutateAsync: createBookings } = useCreateBookings()

  const { t } = useTranslation()

  const { dirtyFields } = useFormState({ control })

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const [openSnackbar] = useSnackbar()

  const submitBooking = async (
    data: BookingMultiServicesDialogFormValues,
    onSuccess: (bookingIds?: Array<number>) => void,
  ) => {
    if (!branchId || !timezone) {
      openSnackbar(t('submitError'), 'error')
      return
    }

    const needToValidate =
      isCreate ||
      Boolean(dirtyFields.multiServicesDto) ||
      Boolean(dirtyFields.clientId) ||
      Boolean(dirtyFields.date)

    const confirmValidation = async () => {
      if (isCreate && data.clientId) {
        try {
          const preparedData = getBookingsDtoForCreating({
            multiServices: data.multiServicesDto,
            clientId: data.clientId,
            note: data.note,
            branchId,
            timezone,
          })

          const unitedBooking = await createMultiServiceBooking(preparedData)

          openSnackbar(t('booking.createSuccess'), 'success')
          onSuccess(unitedBooking?.insertUnitedBooking?.bookings?.map(({ id }) => id))
        } catch (error) {
          openSnackbar(t('submitError'), 'error')
        }
      } else if (!isCreate && data.clientId) {
        try {
          const bookingsDtoForUpdate = getBookingsDtoForUpdate({
            initialBookings,
            data,
            branchId,
            timezone,
          })

          updateBookings(bookingsDtoForUpdate)

          // у только что добавленных букингов не будет id
          const addedNewBookings = data.multiServicesDto.filter(booking => !booking.bookingId)

          let addedIds: Array<number> = []

          if (addedNewBookings?.length) {
            const transformedNewBookings = getBookingsDtoForCreating({
              multiServices: addedNewBookings,
              note: data.note,
              clientId: data.clientId,
              branchId,
              timezone,
            })

            const result = await createBookings(
              transformedNewBookings.map(booking => ({
                ...booking,
                unitedId: unitedBookingId,
                branchId,
              })),
            )

            addedIds = result?.insertBookings?.returning?.map(({ id }) => id) ?? []
          }

          openSnackbar(t('booking.updateSuccess'), 'success')
          const initialBookingIds = initialBookings.map(({ id }) => id)
          const totalBookingIds = [...initialBookingIds, ...addedIds]

          invalidateBookings({
            date: data.multiServicesDto[0].startDate,
            oldDate: initialBookings[0].startDate,
            ids: initialBookingIds,
            branchId,
          })

          onSuccess(totalBookingIds)
        } catch (error) {
          openSnackbar(t('submitError'), 'error')
        }
      }
    }

    if (!needToValidate) {
      confirmValidation()
      return
    }

    const validationResults = await validateBookings(
      data.multiServicesDto.map(booking => ({
        isGroupBooking: false,
        bookingId: booking.bookingId ?? null,
        clientId: data.clientId ?? null,
        clientIds: null,
        serviceIds: booking.services.map(({ id }) => id),
        serviceId: null,
        employeeId: booking.employeeId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        locationId: booking.locationId!,
        startDate: booking.startDate,
        duration: Number(booking.duration),
        branchId,
      })),
    )

    const warningMessages = generateMessageAfterValidationForMultiBooking(
      {
        validationResults,
        multiServicesDto: data.multiServicesDto,
        employees: employees ?? [],
        locations: locations ?? [],
      },
      t,
    )

    if (warningMessages.length === 0) await confirmValidation()
    else
      showConfirmation({
        title: isCreate ? t('creating') : t('editing'),
        onConfirm: confirmValidation,
        description: (
          <ConfirmationDescription
            text={warningMessages}
            question={t(isCreate ? 'booking.createConfirmation' : 'booking.updateConfirmation')}
          />
        ),
      })
  }

  return { submitBooking, confirmationModal }
}

const getBookingsDtoForUpdate = ({
  initialBookings,
  data,
  branchId,
  timezone,
}: {
  initialBookings: Array<BookingUnion>
  data: BookingMultiServicesDialogFormValues
  branchId: number
  timezone: string
}) => {
  const bookingsDtoForUpdate: Array<UpdateBookingType> = []

  for (const initialBooking of initialBookings) {
    const initialBookingServices = !initialBooking.isGroupBooking
      ? initialBooking.bookingServices
      : []
    const initialBookingProducts = initialBooking?.bookingProducts ?? []

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const updatedBooking = getBookingFromMultiServicesDto(data, initialBooking.id)!

    const extractedServices = extractItemsFromFolders(updatedBooking?.services ?? [])
    const serviceIds: number[] = extractedServices.map(service => service.id)

    const {
      bookingServicesToBeRemovedIds,
      bookingServiceInsertInput,
      bookingProductsToBeRemovedIds,
      bookingProductInsertInput,
    } = prepareBookingData({
      initialBookingServices,
      serviceIds,
      initialBookingProducts,
      consumables: updatedBooking.consumables,
      bookingId: initialBooking.id,
    })

    if (!getIsBookingDone(initialBooking)) {
      bookingsDtoForUpdate.push({
        branchId,
        id: initialBooking.id,
        bookingSetInput: {
          startDate: zonedTimeToUtc(updatedBooking.startDate, timezone),
          clientId: data.clientId,
          employeeId: updatedBooking.employeeId ?? null,
          locationId: updatedBooking.locationId,
          duration: Number(updatedBooking.duration),
          note: data.note,
        },
        oldStartDate: initialBooking.startDate,
        bookingServicesToBeRemovedIds,
        bookingServiceInsertInput,
        bookingProductsToBeRemovedIds,
        bookingProductInsertInput,
      })
    }
  }

  return bookingsDtoForUpdate
}

const getBookingFromMultiServicesDto = (
  data: BookingMultiServicesDialogFormValues,
  bookingId: number,
) => {
  const { multiServicesDto } = data

  return multiServicesDto.find(booking => booking.bookingId === bookingId)
}

const getBookingsDtoForCreating = ({
  multiServices,
  note,
  clientId,
  branchId,
  timezone,
}: {
  multiServices: MultiServiceDto[]
  note: string
  clientId: number
  branchId: number
  timezone: string
}): BookingInsertInput[] =>
  multiServices.map(booking => {
    const extractedServices = extractItemsFromFolders(booking.services)

    return {
      duration: Number(booking.duration),
      employeeId: booking.employeeId,
      locationId: booking.locationId,
      bookingServices: {
        data: extractedServices.map(service => ({ serviceId: service.id })),
      },
      startDate: zonedTimeToUtc(booking.startDate, timezone),
      note,
      clientId,
      isGroupBooking: false,
      bookingProducts: {
        data: booking.consumables.map(product => ({
          ...product,
          quantity: Number(product.quantity),
        })),
      },
      branchId,
    }
  })
