import {
  ServerProductType,
  ServerStorageType,
  useCreateInventory,
  useFetchCurrentBranchTimezone,
  useFetchProductGroups,
  useFetchProducts,
  useFetchStorages,
  useGetBranchDefaultStorageId,
  useGetInventoryNumber,
  useGetLeftovers,
} from '@expane/data'
import { createCurrentDate, zonedTimeToUtc } from '@expane/date'
import { convertUnitValueToServer } from '@expane/logic/product'
import { findById } from '@expane/logic/utils'
import { CloseButton, Dialog, Modal, usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Control, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { extractItemsFromFolders, transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { ProductTreeMenuItem } from 'ui/TreeMenu/logic.product'
import { SaveButton } from 'widgets/Buttons'
import { ChooseProductDialog, ChooseProductDialogPlaceholder } from 'widgets/ChooseProductDialog'
import { InventoryCreateInfo } from 'widgets/InventoryCreateDialog/InventoryCreateInfo'
import { InventoryCreateList } from 'widgets/InventoryCreateDialog/InventoryCreateList'

export type InventoryProductDto = Partial<
  Pick<ServerProductType, 'id' | 'vendorCode' | 'name' | 'costPrice' | 'price' | 'unit'>
> &
  Pick<ServerProductType, 'vendorCode' | 'name'> & {
    actualQuantity: string
  }

export interface InventoryCreateDialogFormValues {
  storageId: number
  inventoryNumber: string
  inventoryDate: Date
  products: InventoryProductDto[]
}

export interface InventoryCreateProps {
  control: Control<InventoryCreateDialogFormValues>
}

export const InventoryCreateDialog: FC<DialogProps> = observer(({ closeDialog, id }) => {
  const { t } = useTranslation()

  const [selectedProducts, setSelectedProducts] = useState<ProductTreeMenuItem[]>([])

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: storages, isLoading: isLoadingStorages } = useFetchStorages(branchId)
  const { data: defaultStorageId, isLoading: isLoadingDefaultStorageId } =
    useGetBranchDefaultStorageId(branchId)
  const { data: inventoryNumber, isLoading: isLoadingInventoryNumber } =
    useGetInventoryNumber(branchId)

  const { data: productGroups, isLoading: isLoadingProductGroups } = useFetchProductGroups(branchId)
  const { data: products, isLoading: isLoadingProducts } = useFetchProducts(branchId)

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const isLoading =
    isLoadingProducts ||
    isLoadingProductGroups ||
    isLoadingInventoryNumber ||
    isLoadingStorages ||
    isLoadingDefaultStorageId ||
    !timezone
  const isNoData =
    !storages || !inventoryNumber || !productGroups || !products || !defaultStorageId || !branchId

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
  if (!isLoading && isNoData) return null

  const treeItems = transformDataForTreeMenu<ProductTreeMenuItem>(productGroups, products)

  return (
    <>
      <Modal
        close={closeDialog}
        confirm={() => {
          if (selectedProducts.length !== 0) openPopup()
        }}
      >
        <Dialog>
          {isLoading ? (
            <ChooseProductDialogPlaceholder
              closeDialog={closeDialog}
              title={t('selectionOfProducts.inventory')}
            />
          ) : (
            <ChooseProductDialog
              closeDialog={closeDialog}
              items={treeItems}
              selectedItems={selectedProducts}
              onSelectedItems={setSelectedProducts}
              title={t('selectionOfProducts.inventory')}
              onChoose={openPopup}
            />
          )}
        </Dialog>
      </Modal>

      {isOpen && !isNoData && !isLoading ? (
        <InventoryDialogLogic
          productsForDefaultFormValues={transformItemsForDefaultFormValues(
            extractItemsFromFolders(selectedProducts),
            products,
          )}
          storageId={id}
          products={products}
          inventoryNumber={inventoryNumber}
          storages={storages}
          defaultStorageId={defaultStorageId}
          closeDialog={closePopup}
          closeParentDialog={closeDialog}
          timezone={timezone}
          branchId={branchId}
        />
      ) : null}
    </>
  )
})

const transformItemsForDefaultFormValues = (
  items: { id: number }[],
  products: ServerProductType[],
): InventoryProductDto[] => {
  const itemsForDefaultValues: InventoryProductDto[] = []

  for (const item of items) {
    const product = findById(item.id, products)

    if (product)
      itemsForDefaultValues.push({
        id: product.id,
        name: product.name,
        costPrice: product.costPrice,
        vendorCode: product.vendorCode,
        unit: product.unit,
        price: product.price,
        actualQuantity: '',
      })
  }

  return itemsForDefaultValues
}

interface InventoryDialogLogicProps {
  storages: ServerStorageType[]
  defaultStorageId: number
  products: ServerProductType[]
  productsForDefaultFormValues: InventoryProductDto[]
  inventoryNumber: number
  storageId: number | undefined
  closeDialog: () => void
  closeParentDialog: () => void
  timezone: string
  branchId: number
}

const InventoryDialogLogic: FC<InventoryDialogLogicProps> = ({
  storageId,
  storages,
  defaultStorageId,
  products,
  inventoryNumber,
  closeDialog,
  closeParentDialog,
  productsForDefaultFormValues,
  timezone,
  branchId,
}) => {
  const { t } = useTranslation()

  const { control, handleSubmit, setValue, formState } = useForm<InventoryCreateDialogFormValues>({
    defaultValues: {
      storageId,
      inventoryDate: createCurrentDate(timezone),
      inventoryNumber: inventoryNumber.toString(),
      products: productsForDefaultFormValues,
    },
  })

  const { mutateAsync: createInventory } = useCreateInventory()

  const [openSnackBar] = useSnackbar()

  const watchedDate = useWatch({ control, name: 'inventoryDate' })
  const watchedStorageId = useWatch({ control, name: 'storageId' })

  const { data: productLeftovers, isLoading: isLoadingLeftovers } = useGetLeftovers(
    branchId,
    watchedStorageId,
    // проверка дат накладных для подсчета остатков на сервере происходит по UTC
    zonedTimeToUtc(watchedDate, timezone),
  )
  const leftovers = watchedStorageId && productLeftovers ? productLeftovers : undefined

  const onSubmit: SubmitHandler<InventoryCreateDialogFormValues> = async data => {
    const { storageId, inventoryDate, inventoryNumber, products } = data

    const result = await createInventory({
      storageId,
      inventoryDate,
      number: inventoryNumber,
      branchId,
      inventoryProducts: {
        data: products
          .filter(p => p.id !== undefined)
          .map(product => ({
            productId: product.id,
            actualQuantity: product.unit
              ? convertUnitValueToServer(Number(product.actualQuantity), product.unit)
              : 0,
            calculatedQuantity: product.id ? leftovers?.[product.id] ?? 0 : 0,
            price: product.costPrice,
          })),
      },
    })

    if (result?.insertInventory) openSnackBar(t('inventory.successfullyCreated'), 'success', 3000)
    else {
      openSnackBar(t('submitError'), 'error', 3000)
      reportError(new Error('Error while trying to create inventory'), 'error', {
        data,
        result,
      })
    }

    closeDialog()
    closeParentDialog()
  }

  return (
    <Modal
      close={closeDialog}
      confirm={() => {
        if (!formState.isSubmitting && formState.isDirty) handleSubmit(onSubmit)()
      }}
    >
      <Dialog>
        <Dialog.Title>{t('inventory.name')}</Dialog.Title>
        <Dialog.Body className="w-256">
          <div className="flex flex-col h-96">
            <InventoryCreateInfo
              storages={storages}
              defaultStorageId={defaultStorageId}
              control={control}
              timezone={timezone}
            />

            <InventoryCreateList
              control={control}
              products={products}
              leftovers={leftovers}
              setValue={setValue}
              branchId={branchId}
              isLoadingLeftovers={isLoadingLeftovers}
            />
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <SaveButton
            onClick={handleSubmit(onSubmit)}
            disabled={formState.isSubmitting || !formState.isDirty}
            spinner={formState.isSubmitting}
            isCreate
          />
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
