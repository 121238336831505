import { safeWebLocalStorage } from '@expane/logic/safeWebLocalStorage'
import { reportError } from 'services/sentry'

const BOOKING_PRESET_VERSION = 'v2'
const getLocalStorageKey = (branchId: number) =>
  `${BOOKING_PRESET_VERSION}/${BOOKING_CALENDAR_PRESETS}/${branchId}`

export const getBookingCalendarPresetsFromLocalStorage = (branchId: number): TabPreset[] | null => {
  const localStorageKey = getLocalStorageKey(branchId)
  const presets = safeWebLocalStorage.getItem(localStorageKey)
  if (presets === null) return null

  let parsedPresets
  try {
    parsedPresets = JSON.parse(presets)
  } catch (error) {
    reportError(error as Error, 'error', { parsedPresets, presets })
  }

  if (
    Array.isArray(parsedPresets) &&
    parsedPresets.every(preset => {
      // check that preset is an object
      if (typeof preset !== 'object' || Array.isArray(preset) || preset === null) return false

      if (preset.type !== 'locations' && preset.type !== 'employees') return false
      if (!preset.id || !preset.name) return false

      return true
    })
  ) {
    return parsedPresets
  }

  safeWebLocalStorage.removeItem(localStorageKey)
  return null
}

export const filterPresetsWithArchivedEmployees = (
  presets: TabPreset[],
  allEmployees: { id: number }[],
  allEmployeeGroups: { id: number }[],
) => {
  const employeesHashMap = allEmployees.reduce((hashMap, employee) => {
    if (!Object.hasOwn(hashMap, employee.id)) hashMap[employee.id] = true
    return hashMap
  }, {} as Record<number, true>)
  const employeeGroupHashMap = allEmployeeGroups.reduce((hashMap, employeeGroup) => {
    if (!Object.hasOwn(hashMap, employeeGroup.id)) hashMap[employeeGroup.id] = true
    return hashMap
  }, {} as Record<number, true>)

  return presets.filter(preset => {
    if (preset.type === 'employees') {
      return !preset.employeeId || employeesHashMap[preset.employeeId]
    }

    const allEmployeesAreValid =
      !preset.employees ||
      preset.employees.every(employeeItem => {
        if (employeeItem.isFolder) return employeeGroupHashMap[employeeItem.id]

        return employeesHashMap[employeeItem.id]
      })
    return allEmployeesAreValid
  })
}

export const setNewPresetsToLocalStorage = (newPresets: TabPreset[], branchId: number) => {
  const localStorageKey = getLocalStorageKey(branchId)
  safeWebLocalStorage.setItem(localStorageKey, JSON.stringify(newPresets))
}

const BOOKING_CALENDAR_PRESETS = 'bookingCalendarPresets'

type LocationTabPreset = {
  id: number
  name: string
  type: 'locations'
  shrinkColumns?: boolean
  employees?: { id: number; isFolder: boolean }[] | undefined
  locations?: { id: number }[] | undefined
}

type EmployeeTabPreset = {
  id: number
  name: string
  type: 'employees'
  shrinkColumns?: boolean
  employeeId?: number | undefined
  locations?: { id: number }[] | undefined
}

export type TabPreset = LocationTabPreset | EmployeeTabPreset
