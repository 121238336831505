import {
  BranchWithSchedule,
  ServerProductType,
  useBranchImportProducts,
  useFetchBranches,
  useFetchProductGroups,
  useFetchProducts,
} from '@expane/data'
import {
  CloseButton,
  Dialog,
  Modal,
  Paper,
  SelectDropdown,
  Spinner,
  Switch,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useBranchImportState } from 'logic/hooks/useBranchImportState'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeMultiTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { ImportButton } from 'widgets/Buttons'
import { prepareDtoForBranchImportProducts } from './logic'
import { ImportProductsDialogPlaceholder } from './Placeholder'

type DialogProps = {
  closeDialog: () => void
}

const BranchImportProductsDialog: FC<DialogProps> = ({ closeDialog }) => {
  const { data: branches, isLoading } = useFetchBranches()

  useErrorOpeningDialog(!isLoading && !branches, closeDialog)
  if (isLoading) return <ImportProductsDialogPlaceholder closeDialog={closeDialog} />
  if (!branches) return null

  return <LoadedBranchImportProductsDialog closeDialog={closeDialog} branches={branches} />
}

type ProductForImportTreeItem = TreeMenuItem & ServerProductType

const LoadedBranchImportProductsDialog: FC<{
  branches: BranchWithSchedule[]
  closeDialog: () => void
}> = ({ closeDialog, branches }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!

  const { selectedBranchId, setSelectedBranchId, branchesWithoutCurrent } =
    useBranchImportState(branches)

  const { t } = useTranslation()

  const { data: products, isFetching: isFetchingProducts } = useFetchProducts(selectedBranchId)
  const { data: productGroups, isFetching: isFetchingProductGroups } =
    useFetchProductGroups(selectedBranchId)
  const isFetchingTreeItems = isFetchingProductGroups || isFetchingProducts

  const [ignoreGroups, setIgnoreGroups] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState<ProductForImportTreeItem[]>([])

  const { mutateAsync: importProducts, isLoading: isCreatingProducts } = useBranchImportProducts()

  const [showSnackbar] = useSnackbar()

  const handleSubmit = async () => {
    const dto = prepareDtoForBranchImportProducts(selectedProducts, ignoreGroups)
    try {
      await importProducts({ ...dto, toBranchId: branchId })
      showSnackbar(t('menu.branchImport.success'), 'success')
    } catch (error) {
      showSnackbar(t('submitError'), 'error')
    }
    closeDialog()
  }

  const treeItems = transformDataForTreeMenu<ProductForImportTreeItem>(productGroups, products)

  const areThereGroups = Boolean(productGroups?.length)

  return (
    <Modal close={closeDialog} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('importProducts.name')}</Dialog.Title>

        <Dialog.Body className="h-120 flex flex-col">
          {branchesWithoutCurrent.length > 1 ? (
            <SelectDropdown
              items={branchesWithoutCurrent}
              value={selectedBranchId}
              onSelectChange={value => {
                setSelectedProducts([])
                setSelectedBranchId(value)
              }}
              label={t('branch.name')}
              className="mb-2"
            />
          ) : null}
          {areThereGroups && (
            <Switch
              containerClassName="mb-2"
              onChange={isChecked => {
                setIgnoreGroups(isChecked)
                setSelectedProducts([])
                if (isChecked)
                  showSnackbar(t('importProducts.ignoreProductGroupsWarning'), 'warning', 5000)
              }}
              checked={ignoreGroups}
              label={t('importProducts.ignoreProductGroups')}
            />
          )}

          <Paper className={'flex-1 py-4 px-2 overflow-y-scroll'}>
            {isFetchingTreeItems ? (
              <Spinner expandCentered />
            ) : (
              <TreeMenu
                items={treeItems}
                type="MultiPickMode"
                selected={selectedProducts}
                onSelected={item =>
                  setSelectedProducts(onChangeMultiTreeMenu(item, selectedProducts, treeItems))
                }
                className="mr-2"
              />
            )}
          </Paper>
        </Dialog.Body>

        <Dialog.Footer>
          <ImportButton
            onClick={handleSubmit}
            disabled={selectedProducts.length === 0 || isCreatingProducts}
            spinner={isCreatingProducts}
          />

          <CloseButton onClick={closeDialog} disabled={isCreatingProducts} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export const useOpenImportProductsDialog = () => {
  const { closePopup, openPopup, isOpen } = usePopupOpenState()

  const branchImportProductsDialog = isOpen ? (
    <BranchImportProductsDialog closeDialog={closePopup} />
  ) : null

  return { branchImportProductsDialog, openBranchImportProductsDialog: openPopup }
}
