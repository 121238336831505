import { safeWebLocalStorage } from '@expane/logic/safeWebLocalStorage'
import { makeAutoObservable } from 'mobx'

const getInitialValue = <T = boolean>(key: string): T | undefined => {
  const item = safeWebLocalStorage.getItem(key)

  if (item === null) return undefined
  return JSON.parse(item) as T
}
export class BookingCalendarSettingsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isFirstNameShown: boolean = getInitialValue('isFirstNameShown') ?? true
  setIsFirstNameShown = (isShown: boolean) => {
    this.isFirstNameShown = isShown
    safeWebLocalStorage.setItem('isFirstNameShown', JSON.stringify(isShown))
  }

  isLastNameShown: boolean = getInitialValue('isLastNameShown') ?? true
  setIsLastNameShown = (isShown: boolean) => {
    this.isLastNameShown = isShown
    safeWebLocalStorage.setItem('isLastNameShown', JSON.stringify(isShown))
  }

  isAgeShown: boolean = getInitialValue('isAgeShown') ?? false
  setIsAgeShown = (isShown: boolean) => {
    this.isAgeShown = isShown
    safeWebLocalStorage.setItem('isAgeShown', JSON.stringify(isShown))
  }

  isPhoneShown: boolean = getInitialValue('isPhoneShown') ?? false
  setIsPhoneShown = (isShown: boolean) => {
    this.isPhoneShown = isShown
    safeWebLocalStorage.setItem('isPhoneShown', JSON.stringify(isShown))
  }

  isStartTimeShown: boolean = getInitialValue('isStartTimeShown') ?? true
  setIsStartTimeShown = (isShown: boolean) => {
    this.isStartTimeShown = isShown
    safeWebLocalStorage.setItem('isStartTimeShown', JSON.stringify(isShown))
  }

  isEndTimeShown: boolean = getInitialValue('isEndTimeShown') ?? false
  setIsEndTimeShown = (isShown: boolean) => {
    this.isEndTimeShown = isShown
    safeWebLocalStorage.setItem('isEndTimeShown', JSON.stringify(isShown))
  }

  isServiceShown: boolean = getInitialValue('isServiceShown') ?? false
  setIsServiceShown = (isShown: boolean) => {
    this.isServiceShown = isShown
    safeWebLocalStorage.setItem('isServiceShown', JSON.stringify(isShown))
  }

  isCalendarAlwaysOpen: boolean = getInitialValue('isCalendarAlwaysOpen') ?? false
  setIsCalendarAlwaysOpen = (isOpen: boolean) => {
    this.isCalendarAlwaysOpen = isOpen
    safeWebLocalStorage.setItem('isCalendarAlwaysOpen', JSON.stringify(isOpen))
  }

  isCommentIconShown: boolean = getInitialValue('isCommentIconShown') ?? true
  setIsCommentIconShown = (isShown: boolean) => {
    this.isCommentIconShown = isShown
    safeWebLocalStorage.setItem('isCommentIconShown', JSON.stringify(isShown))
  }

  isEmployeesSortedByGroups: boolean = getInitialValue('isEmployeesSortedByGroups') ?? false
  setIsEmployeesSortedByGroups = (isSorted: boolean) => {
    this.isEmployeesSortedByGroups = isSorted
    safeWebLocalStorage.setItem('isEmployeesSortedByGroups', JSON.stringify(isSorted))
  }

  isLocationsSortedByGroups: boolean = getInitialValue('isLocationsSortedByGroups') ?? false
  setIsLocationsSortedByGroups = (isSorted: boolean) => {
    this.isLocationsSortedByGroups = isSorted
    safeWebLocalStorage.setItem('isLocationsSortedByGroups', JSON.stringify(isSorted))
  }

  locationColumnWidth: number =
    getInitialValue<number>('locationColumnWidth') ?? DEFAULT_COLUMN_WIDTH

  setLocationColumnWidth = (width: number) => {
    if (width > MAX_COLUMN_WIDTH || width < MIN_COLUMN_WIDTH)
      throw new Error('Width is out of range! Only numbers from 12 to 32 are allowed')
    this.locationColumnWidth = width
    safeWebLocalStorage.setItem('locationColumnWidth', JSON.stringify(width))
  }

  employeeColumnWidth: number =
    getInitialValue<number>('employeeColumnWidth') ?? DEFAULT_COLUMN_WIDTH

  setEmployeeColumnWidth = (width: number) => {
    if (width > MAX_COLUMN_WIDTH || width < MIN_COLUMN_WIDTH)
      throw new Error('Width is out of range! Only numbers from 12 to 32 are allowed')
    this.employeeColumnWidth = width
    safeWebLocalStorage.setItem('employeeColumnWidth', JSON.stringify(width))
  }
}
const DEFAULT_COLUMN_WIDTH = 20
export const MIN_COLUMN_WIDTH = 12
export const MAX_COLUMN_WIDTH = 32
