import { addMinutes, compareAsc, getTimeFromDate, parse, subDays } from '@expane/date'
import {
  checkCellIsDisabledByDynamicSchedule,
  checkCellIsDisabledByRegularSchedule,
} from 'logic/calendar'
import {
  BookingUnion,
  ExtendedEmployee,
  ScheduleDto,
  ScheduleType,
  ServerLocationWithServiceIdsType,
  WeeklySchedule,
} from '@expane/data'
import {
  checkBookingsOverlap,
  checkLocationIsAvailableForClient,
  checkLocationIsAvailableForEmployee,
} from '@expane/logic/bookingChecks'
import { transformPersonName } from '@expane/logic/utils'
import { TFunction } from 'i18next'
import { BookingUpdateData } from 'store/UnitedBookingUpdate'
import {
  getIsWorkingDayFromDynamicSchedule,
  getIsWorkingDayFromIntervalSchedule,
  getWeekDay,
} from '@expane/logic/calendar'
import { getCurrentEmployeeSchedule } from '@expane/logic/employeeSchedule'

export const filterEmployeesWhoCanWorkOnLocations = (
  employees: ExtendedEmployee[],
  locations: ServerLocationWithServiceIdsType[],
) => {
  const serviceIds = locations.reduce((ids, location) => {
    ids.push(...location.serviceLocations.map(sL => sL.serviceId))
    return ids
  }, [] as number[])

  return employees.filter(employee =>
    employee.serviceEmployees.some(sE => serviceIds.includes(sE.service.id)),
  )
}

export const filterEmployeesWorksToday = (employees: ExtendedEmployee[], currentDate: Date) => {
  return employees.filter(employee => {
    const employeeSchedule = getCurrentEmployeeSchedule(employee.employeeSchedules, currentDate)
    if (!employeeSchedule) return false

    if (employeeSchedule.isDynamic)
      return getIsWorkingDayFromDynamicSchedule(employeeSchedule.dynamicDates, currentDate)

    const { schedule } = employeeSchedule

    if (schedule.type === ScheduleType.INTERVAL) {
      return getIsWorkingDayFromIntervalSchedule(
        { ...schedule, type: ScheduleType.INTERVAL },
        employeeSchedule.startDate,
        currentDate,
      )
    }
    if (schedule.type === ScheduleType.WEEKLY) {
      const currentWeekDayNumber = getWeekDay(currentDate)
      return schedule.data[currentWeekDayNumber] !== null
    }
    return true
  })
}

export type WeeklyColumnData = {
  id: number
  date: Date
}

export function generateWeeklyColumns(date: Date) {
  const columns: Array<WeeklyColumnData> = []
  const fixedDate = subDays(date, 1)

  for (let i = 1; i < 8; i++) {
    const currentDate = parse(i.toString(), 'i', fixedDate)
    columns.push({
      id: i,
      date: currentDate,
    })
  }

  return columns
}

type Card = CardWithType & PositionInfo

export type CardPositionDto = {
  indexInTimeFrame: number
  countOfCards: number
}

type PositionInfo = { positionInfo: CardPositionDto }

export type Booking = BookingUnion & PositionInfo
export type BookingUpdateDataWithPosition = BookingUpdateData & PositionInfo

type EmployeeTimeOff = {
  id: number
  type?: number | null
  start: Date
  end: Date
  timeOffReason: {
    name: string
  }
}

export type TimeOff = EmployeeTimeOff & PositionInfo

type CardWithType =
  | (BookingUnion & { cardsType: CardsType.booking })
  | (EmployeeTimeOff & { cardsType: CardsType.timeOff })
  | (BookingUpdateDataWithPosition & { cardsType: CardsType.unitedBookingUpdate })

export enum CardsType {
  booking = 'booking',
  timeOff = 'timeOff',
  unitedBookingUpdate = 'unitedBookingUpdate',
}

const DEFAULT_INDEX_IN_TIME_FRAME = 0
const DEFAULT_COUNT_OF_BOOKINGS = 1

function addCardsType(
  bookings: BookingUnion[],
  timeOffs: EmployeeTimeOff[] = [],
  unitedBookingUpdateData: BookingUpdateData[] = [],
) {
  const bookingsCards = bookings.map(booking => ({ ...booking, cardsType: CardsType.booking }))
  const timeOffsCards = timeOffs.map(timeOff => ({ ...timeOff, cardsType: CardsType.timeOff }))
  const updateDataCards = unitedBookingUpdateData.map(booking => ({
    ...booking,
    cardsType: CardsType.unitedBookingUpdate,
  }))

  return [...bookingsCards, ...timeOffsCards, ...updateDataCards] as CardWithType[]
}

export function markPositionForCards({
  bookings,
  unitedBookingUpdateData,
  timeOffs,
}: {
  bookings: BookingUnion[]
  timeOffs?: EmployeeTimeOff[]
  unitedBookingUpdateData?: BookingUpdateData[]
}): Array<Card> {
  const cardsWithType = addCardsType(bookings, timeOffs, unitedBookingUpdateData)
  // сортируем по дате и добавляем дефолтное значение инфы о расположении
  const sortedCards = cardsWithType
    .sort((a, b) => {
      const dateLeft = a.cardsType === CardsType.timeOff ? a.start : a.startDate
      const dateRight = b.cardsType === CardsType.timeOff ? b.start : b.startDate

      return compareAsc(dateLeft, dateRight)
    })
    .map(card => ({
      ...card,
      positionInfo: {
        indexInTimeFrame: DEFAULT_INDEX_IN_TIME_FRAME,
        countOfCards: DEFAULT_COUNT_OF_BOOKINGS,
      },
    }))

  sortedCards.forEach((card, index) => {
    if (index === 0) return

    const cardStart = card.cardsType === CardsType.timeOff ? card.start : card.startDate
    const cardEnd =
      card.cardsType === CardsType.timeOff
        ? card.end
        : addMinutes(card.startDate, Number(card.duration))

    for (let i = index - 1; i >= 0; i--) {
      const cardToCompare = sortedCards[i]
      const cardToCompareStartDate =
        cardToCompare.cardsType === CardsType.timeOff
          ? cardToCompare.start
          : cardToCompare.startDate
      const cardToCompareEndDate =
        cardToCompare.cardsType === CardsType.timeOff
          ? cardToCompare.end
          : addMinutes(cardToCompare.startDate, Number(cardToCompare.duration))

      // ищем ближайший букинг, с которым есть пересечение
      const isBookingsOverlap = checkBookingsOverlap(
        { startDate: cardStart, endDate: cardEnd },
        { startDate: cardToCompareStartDate, endDate: cardToCompareEndDate },
      )
      if (!isBookingsOverlap) continue

      // если найденный букинг со стандартными значениями - просто даём им пополам места
      if (
        cardToCompare.positionInfo.countOfCards === DEFAULT_COUNT_OF_BOOKINGS &&
        cardToCompare.positionInfo.indexInTimeFrame === DEFAULT_INDEX_IN_TIME_FRAME
      ) {
        cardToCompare.positionInfo = { indexInTimeFrame: 0, countOfCards: 2 }
        card.positionInfo = { indexInTimeFrame: 1, countOfCards: 2 }
        break
      }

      // если найденный букинг подразумевает, что спереди есть место для ещё одного букинга -
      // ставим букинг туда
      if (
        cardToCompare.positionInfo.indexInTimeFrame <
        cardToCompare.positionInfo.countOfCards - 1
      ) {
        card.positionInfo = {
          indexInTimeFrame: cardToCompare.positionInfo.indexInTimeFrame + 1,
          countOfCards: cardToCompare.positionInfo.countOfCards,
        }
        break
      }

      // ищем наиболее отдалённый букинг без пересечения
      const mostDistantBookingWithoutOverlap = getMostDistantCardWithoutOverlap({
        indexToStart: i - 1,
        cards: sortedCards,
        countOfCards: cardToCompare.positionInfo.countOfCards,
        timeOfCardToAdd: {
          startDate: cardStart,
          endDate: cardEnd,
        },
      })
      // если нашли - берём его расположение
      if (mostDistantBookingWithoutOverlap) {
        card.positionInfo = { ...mostDistantBookingWithoutOverlap.positionInfo }
        break
      }

      // если не нашли - подвигаем все последние букинги, с которы есть пересечение
      if (
        cardToCompare.positionInfo.indexInTimeFrame ===
        cardToCompare.positionInfo.countOfCards - 1
      ) {
        card.positionInfo = {
          indexInTimeFrame: cardToCompare.positionInfo.indexInTimeFrame + 1,
          countOfCards: cardToCompare.positionInfo.countOfCards + 1,
        }
        incrementCountOfBookings({ bookings: sortedCards, indexToStart: i })
        break
      }

      card.positionInfo = {
        countOfCards: cardToCompare.positionInfo.countOfCards,
        indexInTimeFrame: cardToCompare.positionInfo.indexInTimeFrame + 1,
      }
      break
    }
  })

  return sortedCards
}

const getMostDistantCardWithoutOverlap = ({
  indexToStart,
  cards,
  countOfCards,
  timeOfCardToAdd,
}: {
  cards: Card[]
  countOfCards: number
  indexToStart: number
  timeOfCardToAdd: {
    startDate: Date
    endDate: Date
  }
}) => {
  let result: Card | undefined

  for (let i = indexToStart; i >= 0; i--) {
    const card = cards[i]
    // если количество букингов в группе другое - значит группа уже другая
    if (card.positionInfo.countOfCards !== countOfCards) break

    const cardStartDate = card.cardsType === CardsType.timeOff ? card.start : card.startDate
    const cardEndDate =
      card.cardsType === CardsType.timeOff
        ? card.end
        : addMinutes(card.startDate, Number(card.duration))

    if (
      !checkBookingsOverlap(timeOfCardToAdd, {
        startDate: cardStartDate,
        endDate: cardEndDate,
      })
    )
      result = card

    // если букинг был первый в группе - дальше искать не надо
    if (card.positionInfo.indexInTimeFrame === 0) break
  }

  return result
}

const incrementCountOfBookings = ({
  bookings,
  indexToStart,
}: {
  bookings: Card[]
  indexToStart: number
}) => {
  const newCountOfCards = bookings[indexToStart].positionInfo.countOfCards + 1

  for (let i = indexToStart; i >= 0; i--) {
    const booking = bookings[i]
    booking.positionInfo.countOfCards = newCountOfCards

    if (booking.positionInfo.indexInTimeFrame === 0) break
  }
}

export type GetWarningsForCellArgs = {
  locations: ServerLocationWithServiceIdsType[]
  employees: ExtendedEmployee[]
  bookings: BookingUnion[]
  branchSchedule: WeeklySchedule | undefined
  cellDate: Date
  isEditingAllowed: boolean
}

type WarningsForCell = {
  editing?: boolean
  branch?: boolean
  locationClient?: Array<string>
  locationEmployee?: Array<string>
  employee?: Array<string>
  timeOff?: Array<string>
}

const CELL_DURATION = 30

export const getWarningsForCell = ({
  cellDate,
  branchSchedule,
  bookings,
  locations,
  employees,
  isEditingAllowed,
}: GetWarningsForCellArgs): WarningsForCell => {
  if (!isEditingAllowed) return { editing: true }

  const result: WarningsForCell = {}
  const cellHour = getTimeFromDate(cellDate)
  const cellEndDate = addMinutes(cellDate, CELL_DURATION)

  if (
    checkCellIsDisabledByRegularSchedule({
      schedule: branchSchedule,
      startHour: cellHour,
      currentWeekDate: cellDate,
    })
  ) {
    result.branch = true
  }

  const checkEmployeesInLocation = Boolean(employees.length)

  locations.forEach(location => {
    if (
      !checkLocationIsAvailableForClient({
        locationId: location.id,
        maxClients: location.maxClients,
        allBookings: bookings,
        startDate: cellDate,
        endDate: cellEndDate,
      })
    ) {
      if (result.locationClient) {
        result.locationClient = [...result.locationClient, location.name]
      } else {
        result.locationClient = [location.name]
      }
    }

    if (
      checkEmployeesInLocation &&
      location.maxEmployees > 0 &&
      !checkLocationIsAvailableForEmployee({
        locationId: location.id,
        maxEmployees: location.maxEmployees,
        allBookings: bookings,
        startDate: cellDate,
        endDate: cellEndDate,
      })
    ) {
      if (result.locationEmployee) {
        result.locationEmployee = [...result.locationEmployee, location.name]
      } else {
        result.locationEmployee = [location.name]
      }
    }
  })

  employees.forEach(employee => {
    const employeeSchedule = getCurrentEmployeeSchedule(employee.employeeSchedules, cellDate)
    if (!employeeSchedule) {
      result.employee = [transformPersonName(employee)]
      return
    }

    const isCellDisabledBySchedule = employeeSchedule.isDynamic
      ? checkCellIsDisabledByDynamicSchedule({
          employeeSchedule,
          startHour: cellHour,
          currentWeekDate: cellDate,
        })
      : checkCellIsDisabledByRegularSchedule({
          schedule: employeeSchedule.schedule as ScheduleDto,
          startHour: cellHour,
          currentWeekDate: cellDate,
          scheduleStartDate: employeeSchedule.startDate ?? undefined,
        })
    if (isCellDisabledBySchedule) {
      const employeeName = transformPersonName(employee)
      if (result.employee) {
        result.employee = [...result.employee, employeeName]
      } else {
        result.employee = [employeeName]
      }
    }
  })

  return result
}

export const getCellWarningMessage = (warningDto: WarningsForCell, t: TFunction): string | null => {
  if (warningDto.editing) return t('cellWarning.editing')

  if (warningDto.branch) return t('cellWarning.branch')

  let message = ``
  const warningTitles = Object.keys(warningDto)

  for (const warningTitle of warningTitles) {
    const causesOfWarning = warningDto[warningTitle]
    const causesList = Array.isArray(causesOfWarning) ? causesOfWarning.join('|') : undefined

    if (causesList) message = `${message}${t(`cellWarning.${warningTitle}`)}: ${causesList}; `
  }

  return message.length ? message : null
}
