import { useTranslation } from 'react-i18next'
import { useGetIsDarkMode } from '@expane/logic/theme'

export const SocialMediaInstruction = () => {
  const { t } = useTranslation()

  const isDarkMode = useGetIsDarkMode()

  return (
    <div>
      <p>{t('landingSettingsInstruction.socMedia.description')}</p>

      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/socMedia_dark.png' : '/img/landing/socMedia.png'}
        alt="search"
      />

      <p className={'mt-4'}>{t('landingSettingsInstruction.socMedia.settingsPreview')}</p>

      <p className={imgDescrStyle}>{t('landingSettingsInstruction.socMedia.landingPreview.1')}</p>

      <img
        className={imgStyle}
        src={
          isDarkMode
            ? '/img/landing/socMediaLandingUp_dark.png'
            : '/img/landing/socMediaLandingUp.png'
        }
        alt="search"
      />

      <p className={imgDescrStyle}>{t('landingSettingsInstruction.socMedia.landingPreview.2')}</p>
      <img
        className={imgStyle}
        src={
          isDarkMode
            ? '/img/landing/socMediaLandingDown_dark.png'
            : '/img/landing/socMediaLandingDown.png'
        }
        alt="search"
      />
    </div>
  )
}

const imgStyle = 'rounded-lg max-w-4xl mt-2'
const imgDescrStyle = 'mt-4 italic text-sm '
