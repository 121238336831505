import { gql } from 'graphql-request'

export const referrerFragment = gql`
  fragment referrerType on referrer {
    id
    name
    email
    percentageReward
    createdAt
    maxDiscount
  }
`
