import { useThemeMode } from '@expane/logic/theme'
import { ReportErrorFunc } from '@expane/logic/utils'
import { FC } from 'react'
import { IoMoonOutline, IoSunnyOutline } from 'react-icons/io5'
import { Switch } from '../Switch'

export const ThemeModeSwitch: FC<{
  reportError: ReportErrorFunc
  containerClassName?: string
  className?: string
}> = ({ reportError, containerClassName = '', className = '' }) => {
  const { themeMode, updateThemeMode } = useThemeMode(reportError)

  const sunnyStyle = themeMode === 'dark' ? 'text-gray-500' : 'text-primary-500'
  const moonStyle = themeMode === 'dark' ? 'text-primary-500' : 'text-primary-100'

  return (
    <div className={`flex items-center ${containerClassName}`}>
      <IoSunnyOutline size="2rem" className={sunnyStyle} />
      <Switch
        checked={themeMode === 'dark'}
        onChange={isChecked => {
          updateThemeMode(isChecked ? 'dark' : 'light')
        }}
        containerClassName={`mx-2 ${className}`}
        size="big"
      />
      <IoMoonOutline size="2rem" className={moonStyle} />
    </div>
  )
}
