import { useDeleteFile, useUploadFile } from '@expane/data'

export const useUpdateFile = () => {
  const { mutateAsync: uploadFile } = useUploadFile()
  const { mutateAsync: deleteFile } = useDeleteFile()

  async function getUpdateFile({
    prevFile,
    file,
    isDocument,
  }: {
    prevFile?: string
    file?: string
    isDocument?: boolean
  }) {
    if (file === prevFile) return file

    if (prevFile) {
      await deleteFile({ url: prevFile })
    }

    if (file?.includes('base64')) {
      const data = await uploadFile({ base64File: file, isDocument })
      return data.uploadFile.url
    }
  }

  return getUpdateFile
}

export async function blobToBase64(blob: Blob) {
  return await new Promise<string>(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })
}

export const KB = 1024
export const MB = 1024 * KB

export const formatFileSize = (bytes: number) => {
  if (bytes < MB) {
    return (bytes / KB).toFixed(1) + ' KB' // Виводить розмір в кілобайтах з двома десятковими знаками
  } else {
    return (bytes / MB).toFixed(1) + ' MB' // Виводить розмір в мегабайтах з двома десятковими знаками
  }
}
