import { TRANSACTION_TYPES } from '@expane/data'
import { TreeMenuItem } from 'ui/TreeMenu'

// список типов транзакций, который не используем для фильтров
const TRANSACTION_TYPES_TO_NOT_SHOW_IN_FINANCES = [
  TRANSACTION_TYPES.activateGiftCard.id,
  TRANSACTION_TYPES.salaryRate.id,
  TRANSACTION_TYPES.openWorkingShift.id,
  TRANSACTION_TYPES.closeWorkingShift.id,
  TRANSACTION_TYPES.referralPercentage.id,
]

const getTransactionTypesToDisplay = () => {
  const entriesTypes = Object.entries(TRANSACTION_TYPES)

  return Object.fromEntries(
    entriesTypes.filter(
      // type[1] - это value объекта
      type => !TRANSACTION_TYPES_TO_NOT_SHOW_IN_FINANCES.some(id => id === type[1].id),
    ),
  )
}

// список payment вариаций транзакций, который используем для фильтров
export const getTransactionPaymentVariationsToDisplay = () =>
  Object.values(TRANSACTION_TYPES.payment.variations)

export const transformTransactionTypesToTreeMenu = (): TreeMenuItem[] => {
  const treeMenu: TreeMenuItem[] = []

  const transactionTypesToDisplay = getTransactionTypesToDisplay()

  // формируем "папку оплаты"
  treeMenu.push({
    id: transactionTypesToDisplay.payment.id,
    name: transactionTypesToDisplay.payment.name,
    isFolder: true,
    nodes: [
      {
        id: transactionTypesToDisplay.payment.id,
        name: transactionTypesToDisplay.payment.name,
        parentId: transactionTypesToDisplay.payment.id,
        isFolder: false,
      },
      {
        id: transactionTypesToDisplay.paymentInCredit.id,
        name: transactionTypesToDisplay.paymentInCredit.name,
        parentId: transactionTypesToDisplay.payment.id,
        isFolder: false,
      },
    ],
  })

  // формируем "папку депозитов"
  treeMenu.push({
    id: transactionTypesToDisplay.deposit.id,
    name: transactionTypesToDisplay.deposit.name,
    isFolder: true,
    nodes: [
      {
        id: transactionTypesToDisplay.deposit.id,
        name: transactionTypesToDisplay.deposit.name,
        parentId: transactionTypesToDisplay.deposit.id,
        isFolder: false,
      },
      {
        id: transactionTypesToDisplay.repaymentDeposit.id,
        name: transactionTypesToDisplay.repaymentDeposit.name,
        parentId: transactionTypesToDisplay.deposit.id,
        isFolder: false,
      },
    ],
  })

  // все остальные типы просто трасформируем в TreeMenu
  for (const type in transactionTypesToDisplay) {
    if (
      !(
        transactionTypesToDisplay[type].id === transactionTypesToDisplay.payment.id ||
        transactionTypesToDisplay[type].id === transactionTypesToDisplay.paymentInCredit.id ||
        transactionTypesToDisplay[type].id === transactionTypesToDisplay.repaymentDeposit.id ||
        transactionTypesToDisplay[type].id === transactionTypesToDisplay.deposit.id
      )
    ) {
      treeMenu.push({
        id: transactionTypesToDisplay[type].id,
        name: transactionTypesToDisplay[type].name,
      })
    }
  }

  return treeMenu
}
