import { ScheduleDtoWithBranchIdAndEmployee, useFetchBranches } from '@expane/data'

export type PropsWithBranchId<P = unknown> = P & { branchId: number | undefined }

export const useCheckIsOneBranchInBusiness = () => {
  const { data: branches, isLoading } = useFetchBranches()

  const isOneBranchInBusiness = branches ? branches.length === 1 : false

  return { isOneBranchInBusiness, isLoading }
}

export const checkIsBranchSchedule = (schedule: ScheduleDtoWithBranchIdAndEmployee | undefined) =>
  Boolean(schedule?.branch.length)
