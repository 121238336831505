import {
  EmployeeByIdExtended,
  EmployeeGroupWithSchedule,
  ServerClientBriefType,
  ServerExtendedServiceType,
  ServerWaitingListType,
  useCreateWaitingList,
  useUpdateWaitingList,
  WeeklySchedule,
} from '@expane/data'
import { createCurrentDate, isBefore, zonedTimeToUtc } from '@expane/date'
import { checkArrayNotEmpty } from '@expane/logic/form'
import { permissions } from '@expane/logic/permission'
import { SCHEDULE_RANGE_MAX, SCHEDULE_RANGE_MIN, SCHEDULE_RANGE_STEP } from '@expane/logic/schedule'
import {
  Button,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  InputLabel,
  Modal,
  PlaceholderDialog,
  PlaceholderInput,
  PlaceholderTextarea,
  SelectDropdown,
  SelectDropDownItem,
  Textarea,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { transformPersonsForSelect } from 'logic/utils'
import React, { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoTrashBin } from 'react-icons/io5'
import { store } from 'store'
import { Range } from 'ui/Range'
import { RangeDatePicker } from 'ui/RangeDatePicker'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders, groupInitialTreeItems } from 'ui/TreeMenu/logic.common'
import {
  convertEmployeeGroupsToTreeItems,
  transformEmployeeIdToTreeItem,
} from 'ui/TreeMenu/logic.employee'
import { TreeSelect } from 'ui/TreeSelect'
import { SaveButton } from 'widgets/Buttons'
import { ClientDialog } from 'widgets/ClientDialog'
import {
  convertResponsibleEmployeesToTreeItems,
  defineWorkHours,
  getTimeForRange,
  setTime,
  transformWaitingListServiceDefaultValueTreeItem,
} from './logic'
import {
  WatchingListServicesEmployeeInput,
  WatchingListServicesEmployeeInputPlaceholder,
} from './WatchingListServicesEmployeeInput'

export type WaitingListFormValues = {
  clientId: number
  newClientFirstName: string
  newClientLastName: string
  newClientPhone: string
  service: TreeMenuItem
  employeeId: number | undefined
  date: Date[]
  timing: number[]
  description: string
  responsibleEmployees: TreeMenuItem[]
}

interface WaitingListDialogLogicProps {
  closeDialog: () => void
  isCreate: boolean
  myPermissions: string[]
  waitingListById: ServerWaitingListType | undefined
  services: ServerExtendedServiceType[]
  clients: ServerClientBriefType[]
  employeeGroups: EmployeeGroupWithSchedule[]
  branchSchedule: WeeklySchedule
  myEmployee: EmployeeByIdExtended
  timezone: string
}

export const WaitingListDialogLogic: FC<WaitingListDialogLogicProps> = ({
  closeDialog,
  isCreate,
  myPermissions,
  waitingListById,
  clients,
  employeeGroups,
  services,
  branchSchedule,
  myEmployee,
  timezone,
}) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()

  const workHours = waitingListById
    ? [getTimeForRange(waitingListById.start), getTimeForRange(waitingListById.end)]
    : defineWorkHours(branchSchedule, createCurrentDate(timezone))
  const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(employeeGroups)

  const { formState, control, handleSubmit, setValue } = useForm<WaitingListFormValues>({
    defaultValues: {
      clientId: waitingListById?.client.id,
      service: transformWaitingListServiceDefaultValueTreeItem(services, waitingListById?.service),
      employeeId: waitingListById?.employee?.id,
      date: [
        waitingListById?.start ?? createCurrentDate(timezone),
        waitingListById?.end ?? createCurrentDate(timezone),
      ],
      timing: workHours,
      description: waitingListById?.description ?? undefined,
      responsibleEmployees: waitingListById
        ? groupInitialTreeItems(
            convertResponsibleEmployeesToTreeItems(waitingListById),
            employeeTreeMenuItems,
          )
        : transformEmployeeIdToTreeItem(myEmployee.id, employeeGroups),
    },
  })

  const {
    dialog: clientDialog,
    openEditDialog: openEditClientDialog,
    openCreateDialog: openCreateClientDialog,
  } = useOpenDialog(ClientDialog)

  const { mutateAsync: updateWaitingList } = useUpdateWaitingList()
  const { mutateAsync: createWaitingList } = useCreateWaitingList()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const mutateWaitingList: SubmitHandler<WaitingListFormValues> = async data => {
    const startDate = setTime(data.date[0], data.timing[0])
    const endDate = setTime(data.date[1], data.timing[1])

    const isStartDateBeforeNow = isBefore(startDate, createCurrentDate(timezone))
    const isEndDateBeforeNow = isBefore(endDate, createCurrentDate(timezone))

    const startDateToZonedTime = zonedTimeToUtc(startDate, timezone)
    const endDateToZonedTime = zonedTimeToUtc(endDate, timezone)

    if (isStartDateBeforeNow && isEndDateBeforeNow)
      openSnackbar(t('waitingList.timeError'), 'error')
    else {
      const responsibleEmployeesWithoutGroups = data.responsibleEmployees
        ? extractItemsFromFolders(data.responsibleEmployees as TreeMenuItem[])
        : []
      const responsibleEmployeesIds = responsibleEmployeesWithoutGroups.map(employee => ({
        employeeId: employee.id,
      }))

      if (isCreate) {
        const result = await createWaitingList({
          waitingListInsertInput: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            branchId: branchId!,
            clientId: data.clientId,
            serviceId: data.service.id,
            employeeId: data.employeeId,
            start: startDateToZonedTime,
            end: endDateToZonedTime,
            description: data.description ? data.description : undefined,
            responsibleEmployees: { data: responsibleEmployeesIds },
          },
          clientInsertInput: data.clientId
            ? undefined
            : {
                firstName: data.newClientFirstName,
                lastName: data.newClientLastName,
                phone: data.newClientPhone,
              },
        })

        if (result?.insertWaitingList?.id) {
          openSnackbar(t('waitingList.createdSuccessfully'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      } else if (waitingListById) {
        const result = await updateWaitingList({
          id: waitingListById.id,
          waitingListSetInput: {
            clientId: data.clientId,
            serviceId: data.service.id,
            employeeId: data.employeeId,
            start: startDateToZonedTime,
            end: endDateToZonedTime,
            description: data.description ? data.description : undefined,
          },
          serverWaitingListEmployeeInsertInput: responsibleEmployeesIds.map(({ employeeId }) => ({
            employeeId,
            waitingListId: waitingListById.id,
          })),
        })

        if (result?.updateWaitingListById?.id) {
          openSnackbar(t('waitingList.updatedSuccessfully'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      }

      closeDialog()
    }
  }

  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const cancelExistingWaitingBooking = async () => {
    if (!waitingListById) return

    const result = await updateWaitingList({
      id: waitingListById.id,
      waitingListSetInput: { closedAt: createCurrentDate(timezone) },
    })

    if (result?.updateWaitingListById?.id) {
      openSnackbar(t('cancelBooking.success'), 'success')
    } else {
      openSnackbar(t('submitError'), 'error')
    }
    closeDialog()
  }

  const handleCancelWaitingBooking = () => {
    showConfirmation({
      title: t('cancelBooking.title'),
      description: t('cancelBooking.description'),
      onConfirm: cancelExistingWaitingBooking,
    })
  }

  const clientsForSelect: SelectDropDownItem[] = transformPersonsForSelect(clients, true)

  const isEditingAllowed = myPermissions.includes(permissions.waitingList.set)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateWaitingList)()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>{t('waitingList.name')}</Dialog.Title>

          <Dialog.Body className="w-176">
            <Controller
              control={control}
              name="clientId"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <SelectDropdown
                  label={t('client.name')}
                  value={value}
                  items={clientsForSelect}
                  placeholder={t('placeholders.defaultSelect')}
                  required
                  noDataMessage={t('noClient')}
                  className="w-1/2 pr-2"
                  onSelectChange={onChange}
                  disabled={!isEditingAllowed}
                  errorMessage={{
                    isShown: Boolean(formState.errors.clientId),
                    text: t('formError.required'),
                  }}
                  onEditClick={openEditClientDialog}
                  onPlusClick={() => openCreateClientDialog(id => setValue('clientId', id))}
                />
              )}
            />

            <WatchingListServicesEmployeeInput
              control={control}
              services={services}
              disabled={!isEditingAllowed}
            />

            <div className="flex w-full">
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <InputLabel label={t('dateTitle')} />
                    <RangeDatePicker
                      timezone={timezone}
                      value={value}
                      onChange={onChange}
                      className="w-56"
                      disabled={!isEditingAllowed}
                    />
                  </div>
                )}
              />

              <div className="grow ml-3 mr-2">
                <p className="block mb-4 text-sm leading-3 font-medium text-label-color">
                  {t('convenientTime')}
                </p>

                <Controller
                  name="timing"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Range
                      className="w-full"
                      markType="time"
                      min={SCHEDULE_RANGE_MIN}
                      max={SCHEDULE_RANGE_MAX}
                      step={SCHEDULE_RANGE_STEP}
                      values={value}
                      onChange={onChange}
                      labeled
                      markSeparators
                      placeholderValues={workHours}
                      disabled={!isEditingAllowed}
                    />
                  )}
                />
              </div>
            </div>

            <Controller
              control={control}
              name="responsibleEmployees"
              rules={{ validate: checkArrayNotEmpty }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TreeSelect
                  type="MultiPickMode"
                  errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                  required
                  label={t('responsibleEmployees.name')}
                  placeholder={t('choose')}
                  items={employeeTreeMenuItems as TreeMenuItem[]}
                  className="mt-4"
                  onSelected={onChange}
                  selected={value as TreeMenuItem[]}
                  hint={t('responsibleEmployees.hint')}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={t('description')}
                  placeholder={t('placeholders.serviceDescription')}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {isEditingAllowed && (
              <SaveButton
                onClick={handleSubmit(mutateWaitingList)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />

            {isEditingAllowed && !isCreate && (
              <Button
                type="danger"
                className="mr-auto"
                onClick={handleCancelWaitingBooking}
                disabled={formState.isSubmitting}
                Icon={IoTrashBin}
              >
                {t('cancelBooking.title')}
              </Button>
            )}
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {clientDialog}
      {confirmPopup}
      {confirmationModal}
    </>
  )
}

export const WaitingListDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('waitingList.name')} className="w-176" closeDialog={closeDialog}>
      <PlaceholderInput label={t('client.name')} className="w-1/2 pr-2 pb-4" />

      <WatchingListServicesEmployeeInputPlaceholder />

      <div className="flex w-full">
        <div>
          <PlaceholderInput label={t('dateTitle')} className="w-56" />
        </div>

        <div className="grow ml-3 mr-2">
          <p className="block mb-4 text-sm leading-3 font-medium text-label-color">
            {t('convenientTime')}
          </p>

          <Range
            className="w-full"
            markType="time"
            min={SCHEDULE_RANGE_MIN}
            max={SCHEDULE_RANGE_MAX}
            step={SCHEDULE_RANGE_STEP}
            values={[9, 18]}
            placeholderValues={[9, 18]}
            onChange={() => {
              return
            }}
            labeled
            markSeparators
            disabled
          />
        </div>
      </div>

      <PlaceholderInput label={t('responsibleEmployees.name')} className="mt-4 mb-4" />

      <PlaceholderTextarea label={t('description')} />
    </PlaceholderDialog>
  )
}
