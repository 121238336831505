import { useTranslation } from 'react-i18next'
import { useGetIsDarkMode } from '@expane/logic/theme'

export const LinksInstruction = () => {
  const { t } = useTranslation()

  const isDarkMode = useGetIsDarkMode()

  return (
    <div>
      <p className={'mt-4'}>{t('landingSettingsInstruction.links.description')}</p>

      <p>{t('landingSettingsInstruction.links.creation.title')}</p>
      <p className={'mt-2'}>{t('landingSettingsInstruction.links.creation.1')}</p>
      <p>{t('landingSettingsInstruction.links.creation.2')}</p>
      <p>{t('landingSettingsInstruction.links.creation.3.title')}</p>

      <ul className={'list-disc pl-4 mb-4'}>
        <li>{t('landingSettingsInstruction.links.creation.3.1')}</li>
        <li>{t('landingSettingsInstruction.links.creation.3.2')}</li>
        <li>{t('landingSettingsInstruction.links.creation.3.3')}</li>
        <li>{t('landingSettingsInstruction.links.creation.3.4')}</li>
        <li>{t('landingSettingsInstruction.links.creation.3.5')}</li>
        <li>{t('landingSettingsInstruction.links.creation.3.6')}</li>
      </ul>

      <p>{t('landingSettingsInstruction.links.creation.4')}</p>

      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/links1_dark.png' : '/img/landing/links1.png'}
        alt="search"
      />
      <img
        className={imgStyle}
        src={isDarkMode ? '/img/landing/links2_dark.png' : '/img/landing/links2.png'}
        alt="search"
      />
    </div>
  )
}

const imgStyle = 'rounded-lg max-w-4xl mt-2'
