import { EmployeeGroupWithSchedule, ServerLocationWithServiceIdsType } from '@expane/data'
import { safeWebLocalStorage } from '@expane/logic/safeWebLocalStorage'
import { TreeMenuItem } from 'ui/TreeMenu'
import { findTreeMenuItem } from 'ui/TreeMenu/logic.common'

const SELECTED_EMPLOYEE_LOCAL_STORAGE_KEY = 'calendar/selectedEmployeeId'
export const getSelectedEmployeeLocalStorageKey = (branchId: number) => {
  return `${SELECTED_EMPLOYEE_LOCAL_STORAGE_KEY}/${branchId}`
}
export const removeArchivedEmployeeFromCalendarSelect = (employeeId: number, branchId: number) => {
  const localStorageKey = getSelectedEmployeeLocalStorageKey(branchId)
  const rawEmployeeIdFromLocalStorage = safeWebLocalStorage.getItem(localStorageKey)
  if (!rawEmployeeIdFromLocalStorage) return

  try {
    const employeeIdFromLocalStorage = JSON.parse(rawEmployeeIdFromLocalStorage)

    if (employeeIdFromLocalStorage === employeeId) safeWebLocalStorage.removeItem(localStorageKey)
  } catch (error) {
    safeWebLocalStorage.removeItem(localStorageKey)
  }
}

export const filterEmployeesWhoHaveServices = (employeeGroups: EmployeeGroupWithSchedule[]) => {
  const result: EmployeeGroupWithSchedule[] = []

  for (const employeeGroup of employeeGroups) {
    if (employeeGroup.employees.length === 0) continue

    const filteredEmployees = employeeGroup.employees.filter(
      employee => employee.serviceEmployees.length > 0,
    )

    if (filteredEmployees.length > 0)
      result.push({ ...employeeGroup, employees: filteredEmployees })
  }

  return result
}

export const getFullInfoForEmployees = (
  presetEmployees: { id: number; isFolder: boolean }[],
  allEmployees: TreeMenuItem[],
) => {
  const result: TreeMenuItem[] = []

  presetEmployees.forEach(({ id, isFolder }) => {
    const fullInfo = findTreeMenuItem(id, isFolder, allEmployees)

    if (fullInfo) result.push(fullInfo)
  })

  return result
}

export const getFullInfoForLocations = (
  presetLocations: {
    id: number
  }[],
  allLocations: ServerLocationWithServiceIdsType[],
) => {
  const result: ServerLocationWithServiceIdsType[] = allLocations.filter(location =>
    presetLocations.some(presetLocations => presetLocations.id === location.id),
  )

  return result
}
