import { utcToZonedTime } from '@expane/date'
import { queryClient } from '../../api'
import { TRANSACTIONS_QUERY_KEY } from './queryKeys'

export const invalidateTransactions = () => {
  queryClient.invalidateQueries({
    predicate: query => query.queryKey.includes(TRANSACTIONS_QUERY_KEY),
  })
}

export const TRANSACTION_TYPES = {
  operation: { id: 1, name: 'transactionNames.operation' }, // перемещение между счетами
  payment: {
    id: 2,
    name: 'transactionNames.payment',
    variations: {
      service: { id: 1, name: 'transactionNames.service' },
      product: { id: 2, name: 'transactionNames.product' },
      subscription: { id: 3, name: 'transactionNames.subscription' },
      giftCard: { id: 4, name: 'transactionNames.card' },
    },
  }, // оплата услуг клиентом
  deposit: {
    id: 3,
    name: 'transactionNames.deposit',
    variations: {
      depositDuringPayment: { id: 1, name: 'transactionNames.deposit' },
      depositWithoutPayment: { id: 2, name: 'transactionNames.deposit' },
    },
  }, // внесение средств на счет клиента
  refund: {
    id: 4,
    name: 'transactionNames.refund',
    variations: {
      refundFromAccount: { id: 1, name: 'transactionNames.refundToClient' }, // возврат клиенту при котором деньги снимутся со счета
      refundToClientAccount: { id: 2, name: 'transactionNames.refundToClientAccount' }, // возврат клиенту при котором деньги НЕ снимутся со счета и пополниться счет клиента
      refundBySubscription: { id: 3, name: 'transactionNames.refundBySubscription' }, // возврат услуги по абонементу
      refundPaymentInCredit: { id: 4, name: 'transactionNames.refundPaymentInCredit' }, // возврат когда оплата была в кредит
    },
  },
  expenses: { id: 5, name: 'transactionNames.expenses' }, // Затраты бизнеса
  revenue: { id: 6, name: 'transactionNames.revenue' }, // Доход бизнеса
  // Зарплата. Указываются:
  // fromAccountId - с какого счёта выдаётся зарплата
  // employeeId - какому сотруднику выдаётся зарплата
  salary: { id: 9, name: 'transactionNames.salary' },
  productsPurchase: { id: 10, name: 'transactionNames.supplier' }, // транзакция оплаты товаров контрагенту
  transportCosts: { id: 11, name: 'transactionNames.transportCosts' }, // транспортные расходы на доставку товаров
  productsReturn: { id: 12, name: 'transactionNames.supplierReturn' }, // возврат товаров контрагенту
  activateGiftCard: { id: 13, name: 'transactionNames.certificate' }, // внесение средств на счет клиента по сертификату
  cashOut: { id: 14, name: 'transactionNames.cashOut' }, // вывод средств со счета клиента
  salaryRate: { id: 15, name: 'transactionNames.salaryRate' }, // Начисленная зарплатная ставка сотрудника.
  // Указывается: employeeId, startPeriod, endPeriod
  openWorkingShift: { id: 16, name: 'transactionNames.openingShift' },
  closeWorkingShift: { id: 17, name: 'transactionNames.closingShift' },
  referralPercentage: { id: 18, name: 'transactionNames.referral' }, // транзакция, которая входит в начисление зарплаты
  paymentInCredit: {
    id: 19,
    name: 'transactionNames.paymentInCredit',
    variations: {
      service: { id: 1, name: 'transactionNames.service' },
      product: { id: 2, name: 'transactionNames.product' },
      subscription: { id: 3, name: 'transactionNames.subscription' },
      giftCard: { id: 4, name: 'transactionNames.card' },
    },
  }, // оплата услуг в кредит
  repaymentDeposit: {
    id: 20,
    name: 'transactionNames.repaymentDeposit',
    variations: {
      // используется когда перед оплатой в кредит у клиента есть деньги на счету
      // этой транзакцией происходит погашение кредита сразу после оплаты в кредит
      repaymentDepositByClientBalance: {
        id: 1,
        name: 'transactionNames.repaymentDepositByClientBalance',
      },
      // погашение кредита деньгами
      repaymentDepositByMoney: { id: 2, name: 'transactionNames.repaymentDeposit' },
    },
  }, // депозиты погашения кредитов
} as const

export type TransactionToParse = {
  date?: string | Date | null
  startPeriod?: string | Date | null
  endPeriod?: string | Date | null
}
export const parseDatesInTransactionGqlResponse = <T extends TransactionToParse>(
  transaction: T,
  timezone: string,
) => {
  if (transaction.date) {
    transaction.date = utcToZonedTime(transaction.date, timezone)
  }
  if (transaction.startPeriod) {
    transaction.startPeriod = utcToZonedTime(transaction.startPeriod, timezone)
  }
  if (transaction.endPeriod) {
    transaction.endPeriod = utcToZonedTime(transaction.endPeriod, timezone)
  }

  return transaction
}
