import {
  BillingReminderNotificationType,
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
  NOTIFICATION_TYPE_VARIATION,
  NotificationOfBookingCreatedByClientType,
  NotificationUnionType,
  ReviewNotificationType,
  ToEmployeeNotificationType,
  useFetchLocationById,
  WaitingListNotificationType,
} from '@expane/data'
import { utcToZonedTime } from '@expane/date'
import { transformPersonName } from '@expane/logic/utils'
import { Checkbox, Tag, TagType } from '@expane/ui'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { Dispatch, FC, SetStateAction } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { EditButton } from 'ui/EditButton'
import { StarRating } from '@expane/nui'
import { Avatar } from 'ui/Avatar'
import { ReviewActions } from 'widgets/ReviewActions/'

interface NotificationItemProps {
  item: NotificationUnionType
  openBookingDialog: ((number) => void) | undefined
  selectedNotifications: Array<number>
  setNotifications: Dispatch<SetStateAction<Array<number>>>
  timezone: string
}

interface WaitingListNotificationItemProps
  extends Omit<NotificationItemProps, 'item' | 'openBookingDialog'> {
  notification: WaitingListNotificationType
}
interface BillingReminderNotificationItemProps
  extends Omit<NotificationItemProps, 'item' | 'openBookingDialog'> {
  notification: BillingReminderNotificationType
}
interface ReviewNotificationItemProps
  extends Omit<NotificationItemProps, 'item' | 'openBookingDialog'> {
  notification: ReviewNotificationType
}

interface NotificationOfBookingCreatedByClientItemProps
  extends Omit<NotificationItemProps, 'item'> {
  notification: NotificationOfBookingCreatedByClientType
}
interface ToEmployeeNotificationItemProps extends Omit<NotificationItemProps, 'item'> {
  notification: ToEmployeeNotificationType
}

export const NotificationItem: FC<NotificationItemProps> = ({
  item,
  openBookingDialog,
  setNotifications,
  selectedNotifications,
  timezone,
}) => (
  <>
    {item.type === NOTIFICATION_TYPE.waitingList && (
      <WaitingListNotificationItem
        notification={item}
        timezone={timezone}
        setNotifications={setNotifications}
        selectedNotifications={selectedNotifications}
      />
    )}
    {item.type === NOTIFICATION_TYPE.bookingCreatedByClient && (
      <NotificationOfBookingCreatedByClientItem
        timezone={timezone}
        notification={item}
        openBookingDialog={openBookingDialog}
        selectedNotifications={selectedNotifications}
        setNotifications={setNotifications}
      />
    )}
    {item.type === NOTIFICATION_TYPE.toEmployee && (
      <ToEmployeeNotificationItem
        timezone={timezone}
        notification={item}
        openBookingDialog={openBookingDialog}
        selectedNotifications={selectedNotifications}
        setNotifications={setNotifications}
      />
    )}
    {item.type === NOTIFICATION_TYPE.billingReminder && (
      <BillingNotificationItem
        timezone={timezone}
        notification={item}
        selectedNotifications={selectedNotifications}
        setNotifications={setNotifications}
      />
    )}
    {item.type === NOTIFICATION_TYPE.review && (
      <ReviewNotificationItem
        timezone={timezone}
        notification={item}
        selectedNotifications={selectedNotifications}
        setNotifications={setNotifications}
      />
    )}
  </>
)

type Period = { start: string; end: string }
type WaitingListMessage = { locationId: number; periods: Period[]; timezone: string }

interface NotificationItemHeaderProps {
  notification: NotificationUnionType
  selectedNotifications: Array<number>
  setNotifications: Dispatch<SetStateAction<Array<number>>>
}

const NotificationItemHeader: FC<NotificationItemHeaderProps> = ({
  notification,
  setNotifications,
  selectedNotifications,
}) => {
  const { t } = useTranslation()

  const dateFormatting = useDateFormatting()

  const isSelected = checkIsNotificationSelected(selectedNotifications, notification.id)

  const tagProps = getTagProps(notification, t)

  return (
    <div className="flex items-center border-b border-input-disabled-color pb-1 h-6">
      {notification.status !== NOTIFICATION_STATUS.read && (
        <Checkbox
          checked={isSelected}
          onChange={e => {
            e.stopPropagation()
            if (isSelected) {
              setNotifications(ids => [...ids.filter(id => id !== notification.id)])
            } else setNotifications(ids => [...ids, notification.id])
          }}
        />
      )}

      <div className="flex w-full items-center">
        {tagProps && <Tag {...tagProps} />}

        <p className="ml-auto text-xs text-gray-500 dark:text-gray-400 ml-1">
          {dateFormatting('dateTime', notification.sentAt)}
        </p>
      </div>
    </div>
  )
}

const WaitingListNotificationItem: FC<WaitingListNotificationItemProps> = ({
  notification,
  selectedNotifications,
  setNotifications,
  timezone,
}) => {
  const { t } = useTranslation()

  const parsedData = JSON.parse(notification.message) as WaitingListMessage[]

  const serviceName = notification.waitingList?.service.name ?? ''
  const clientName = notification.waitingList?.client
    ? transformPersonName(notification.waitingList.client)
    : ''

  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <div className="text-sm text-gray-600 dark:text-gray-300 py-1">
          <p className={textStyle}>
            <span className={labelStyle}>{`${t('client.name')}:`}</span>
            {clientName}
          </p>
          <p className={textStyle}>
            <span className={labelStyle}>{`${t('service.name')}:`}</span>
            {serviceName}
          </p>

          <div className={textStyle}>
            <span className={labelStyle}>{`${t('availableTime')}:`}</span>

            <ul>
              {parsedData.map((item, index) => (
                <PeriodsByLocation
                  timezone={timezone}
                  key={index}
                  locationId={item.locationId}
                  periods={item.periods}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </li>
  )
}

const PeriodsByLocation: FC<WaitingListMessage> = ({ locationId, periods, timezone }) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()
  const { data: location } = useFetchLocationById(locationId)

  const translatedPeriods = periods.map(({ start, end }) =>
    t('datePeriod', {
      start: dateFormatting('dateTime', utcToZonedTime(start, timezone)),
      end: dateFormatting('dateTime', utcToZonedTime(end, timezone)),
    }),
  )

  return (
    <li className={textStyle + ' pl-2'}>
      <p>
        <span className={labelStyle}>{t('inLocation')}: </span>
        {location?.name ?? ''}
      </p>
      <ul>
        {translatedPeriods.map((text, index) => (
          <li key={index} className="pl-2">
            {text}
          </li>
        ))}
      </ul>
    </li>
  )
}

const NotificationOfBookingCreatedByClientItem: FC<
  NotificationOfBookingCreatedByClientItemProps
> = ({ notification, selectedNotifications, setNotifications, openBookingDialog }) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()

  const clientName = notification.booking?.client
    ? transformPersonName(notification.booking.client)
    : ''

  const employeeName = notification.booking?.employee
    ? transformPersonName(notification.booking.employee)
    : ''

  const services = notification.booking?.bookingServices.map(({ service }) => service.name) ?? []

  const startDate = notification.booking?.startDate
    ? dateFormatting('dateTime', notification.booking.startDate)
    : ''

  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <div className="flex justify-between">
          <div className="text-sm text-gray-600 py-1">
            <p className={textStyle}>
              <span className={labelStyle}>{`${t('client.name')}:`}</span>
              {clientName}
            </p>
            <p className={textStyle}>
              <span className={labelStyle}>{`${t('employee.name')}:`}</span>
              {employeeName}
            </p>
            <p className={textStyle}>
              <span className={labelStyle}>{`${t(
                services.length > 1 ? 'services' : 'service.name',
              )}:`}</span>
              {services.join(', ')}
            </p>
            <p className={textStyle}>
              <span className={labelStyle}>{`${t('startDate')}: `}</span>
              {startDate}
            </p>
            <p className={textStyle}>
              <span className={labelStyle}>{`${t('duration')}: `}</span>
              {notification.booking?.duration ?? 0}
            </p>

            {notification.booking?.clientNote && (
              <p className={textStyle}>
                <span className={labelStyle}>{`${t('commentTitle')}: `}</span>
                {notification.booking.clientNote}
              </p>
            )}
          </div>

          <div className="mr-1">
            <EditButton
              onClick={() => {
                if (notification.booking?.id) openBookingDialog?.(notification.booking.id)
              }}
            />
          </div>
        </div>
      </div>
    </li>
  )
}

const BillingNotificationItem: FC<BillingReminderNotificationItemProps> = ({
  notification,
  selectedNotifications,
  setNotifications,
}) => {
  const { t } = useTranslation()

  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <div className="text-sm py-1">
          <p className={textStyle}>{t('billingReminder', { days: notification.message })}</p>
        </div>
      </div>
    </li>
  )
}

const ReviewNotificationItem: FC<ReviewNotificationItemProps> = ({
  notification,
  selectedNotifications,
  setNotifications,
}) => {
  const { t } = useTranslation()

  const review = notification.review

  const name = transformPersonName(review.client)

  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <p className="text-main-color text-sm my-1">{t('newReviewFromClient')}:</p>

        <div className="mb-2 border border-block-color p-3 rounded-2xl text-sm text-main-color">
          <div className="flex mb-1 justify-between">
            <StarRating rating={review.rating} size="18" />
            <ReviewActions review={review} size="small" />
          </div>

          <p className="mb-4">{review.text}</p>
          <div className="flex gap-2 items-center">
            <Avatar name={name} url={review.client.photo ?? undefined} />
            <p>{name}</p>
          </div>
        </div>
      </div>
    </li>
  )
}

const ToEmployeeNotificationItem: FC<ToEmployeeNotificationItemProps> = ({
  notification,
  selectedNotifications,
  setNotifications,
  openBookingDialog,
}) => {
  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <div className="flex justify-between">
          <div className="text-sm py-1">
            <p className={textStyle}>{notification.message}</p>
          </div>

          <div className="pt-1">
            <EditButton
              onClick={() => {
                if (notification.bookingId) openBookingDialog?.(notification.bookingId)
              }}
            />
          </div>
        </div>
      </div>
    </li>
  )
}

const notificationContainerStyle =
  'w-full pt-1 px-3 border-2 border-gray-100 dark:border-gray-500 rounded-lg'
const labelStyle = 'mr-1 text-gray-400 text-sm mb-1'
const textStyle = 'text-main-color font-medium text-sm mb-1'

const checkIsNotificationSelected = (notifications: Array<number>, id: number) =>
  notifications.includes(id)

const getTagProps = (notification: NotificationUnionType, t: TFunction): TagType | undefined => {
  if (notification.type === NOTIFICATION_TYPE.waitingList)
    return {
      name: t('notificationsTypes.freeTimeForBooking'),
      color: 'yellow-300',
    }
  if (notification.type === NOTIFICATION_TYPE.bookingCreatedByClient)
    return {
      name: t('notificationsTypes.bookingCreatedByTheClient'),
      color: 'indigo-400',
    }
  if (notification.type === NOTIFICATION_TYPE.billingReminder)
    return {
      name: t('notificationsTypes.billingReminder'),
      color: 'cyan-700',
    }

  if (notification.type === NOTIFICATION_TYPE.review)
    return {
      name: t('notificationsTypes.review'),
      color: 'amber-400',
    }

  if (notification.type === NOTIFICATION_TYPE.toEmployee) {
    if (notification.typeVariation === NOTIFICATION_TYPE_VARIATION.bookingUpdateTime)
      return {
        name: t('notificationsTypes.updateBookingTime'),
        color: 'yellow-600',
      }
    if (notification.typeVariation === NOTIFICATION_TYPE_VARIATION.bookingCancel)
      return {
        name: t('notificationsTypes.bookingCancel'),
        color: 'red-500',
      }
    if (notification.typeVariation === NOTIFICATION_TYPE_VARIATION.bookingInPlaceStatus)
      return {
        name: t('notificationsTypes.receivingOnInPlaceStatus'),
        color: 'green-400',
      }
    // notification.typeVariation === NOTIFICATION_TYPE_VARIATION.bookingInsert or !notification.typeVariation
    return {
      name: t('notificationsTypes.bookingInsert'),
      color: 'green-600',
    }
  }
}
