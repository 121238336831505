import {
  ScheduleDtoWithBranchIdAndEmployee,
  ScheduleType,
  useArchiveSchedule,
  useCreateSchedule,
  useUpdateSchedule,
  WeeklySchedule as WeeklyScheduleType,
} from '@expane/data'
import { checkIsBranchSchedule } from '@expane/logic/branch'
import { formatScheduleTemplateWeeklyData, ScheduleTemplateFormData } from '@expane/logic/schedule'
import { transformPersonName, translateItemsName } from '@expane/logic/utils'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  HorizontalButtonSwitch,
  Input,
  Modal,
  PlaceholderDialog,
  PlaceholderInput,
  Spinner,
  Tag,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { SYSTEM_TAG_COLOR } from 'logic/ui'
import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ArchiveButton, RestoreButton, SaveButton } from 'widgets/Buttons'
import { IntervalSchedule } from './IntervalSchedule'
import { WeeklySchedule } from './WeeklySchedule'

interface ScheduleDialogLogicProps {
  isCreate: boolean
  scheduleById: ScheduleDtoWithBranchIdAndEmployee | undefined
  branchSchedule: WeeklyScheduleType
  isEditingAllowed: boolean
  closeDialog: () => void
  isCreateNewBranchSchedule: boolean
  onCreate?: (id: number) => void
  timezone: string
}

export const ScheduleDialogLogic: FC<ScheduleDialogLogicProps> = ({
  isCreate,
  scheduleById,
  branchSchedule,
  isEditingAllowed,
  closeDialog,
  isCreateNewBranchSchedule,
  onCreate,
  timezone,
}) => {
  const { t } = useTranslation()

  const [activeType, setActiveType] = useState(scheduleById?.type ?? ScheduleType.WEEKLY)
  const { formState, handleSubmit, control, setValue, getValues } =
    useForm<ScheduleTemplateFormData>()

  const [openSnackbar] = useSnackbar()
  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const { mutateAsync: createMutation } = useCreateSchedule()
  const { mutateAsync: updateMutation } = useUpdateSchedule()

  const { mutateAsync: archiveMutation } = useArchiveSchedule()

  const CurrentTypeComponent = schedulesTypesOptions.find(
    tab => tab.id === activeType.toString(),
  )?.component

  const isBranchSchedule = checkIsBranchSchedule(scheduleById)

  const mutateSchedule: SubmitHandler<ScheduleTemplateFormData> = async formData => {
    const formattedData =
      activeType === ScheduleType.WEEKLY
        ? formatScheduleTemplateWeeklyData(formData)
        : {
            workDays: Number(formData.workDays),
            breakDays: Number(formData.breakDays),
            timing: formData.timing,
          }
    if (isCreate) {
      const result = await createMutation({
        data: formattedData,
        name: formData.scheduleName,
        type: activeType,
      })

      if (result?.insertSchedule?.id) {
        onCreate?.(result.insertSchedule.id)
        openSnackbar(t('schedule.createdSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else {
      const result = await updateMutation({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: scheduleById!.id,
        scheduleSetInput: {
          name: formData.scheduleName,
          type: activeType,
          data: formattedData,
        },
      })

      if (result?.updateScheduleById?.id) {
        openSnackbar(t('schedule.updatedSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const mutateArchiveSchedule = async (archived: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await archiveMutation({ id: scheduleById!.id, archived })

    if (result?.updateScheduleById?.id)
      openSnackbar(
        archived
          ? t('schedule.archivingSuccessful', { name: scheduleById?.name })
          : t('schedule.restorationSuccessful', { name: scheduleById?.name }),
        'success',
      )
    else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const { showArchiveConfirmationPopup, archiveConfirmationModal } =
    useShowArchiveConfirmationPopup(scheduleById?.name ?? '', () => mutateArchiveSchedule(true))

  const warningContent = (
    <div className="max-w-2xl">
      <p className="mb-2">{t('schedule.warning')}</p>
      {isBranchSchedule && (
        <p className="mb-2">
          {t('branches')}:{' '}
          <span className="italic">{scheduleById?.branch.map(({ name }) => name).join(', ')}</span>
        </p>
      )}
      {Boolean(scheduleById?.employeeSchedules.length) && (
        <p>
          {t('employees.name')}:{' '}
          <span className="italic">
            {scheduleById?.employeeSchedules
              .map(schedule => transformPersonName(schedule.employee))
              .join(', ')}
          </span>
        </p>
      )}
    </div>
  )

  const { warningModal, showWarningPopup } = useShowWarningPopup(t('warning'), warningContent)

  const handleOnArchive = () => {
    if (scheduleById?.branch.length || scheduleById?.employeeSchedules.length !== 0)
      showWarningPopup()
    else showArchiveConfirmationPopup()
  }

  const archived = Boolean(scheduleById?.archived)

  const showSaveButton = isEditingAllowed && !archived

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (showSaveButton && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateSchedule)()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>
            <div className="flex items-center gap-2">
              {t('schedule.name')}
              {(branchSchedule.id === scheduleById?.id || isCreateNewBranchSchedule) && (
                <Tag name={t('branchSchedule')} color={SYSTEM_TAG_COLOR} />
              )}
            </div>
          </Dialog.Title>

          <Dialog.Body className="flex min-w-256 h-138">
            <div className="border-r-2 pr-6 border-gray-100 dark:border-gray-600 w-1/3">
              <Controller
                name="scheduleName"
                control={control}
                rules={{ required: true }}
                defaultValue={scheduleById?.name ?? ''}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('title')}
                    placeholder={t('schedule.new')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.scheduleName),
                      text: t('formError.required'),
                    }}
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed || archived}
                    autoFocus
                  />
                )}
              />

              <HorizontalButtonSwitch
                value={activeType.toString()}
                label={t('type')}
                onChange={id => setActiveType(Number(id) as 1 | 2)}
                options={translateItemsName(schedulesTypesOptions, t)}
                disabled={
                  !isEditingAllowed || isBranchSchedule || archived || isCreateNewBranchSchedule
                }
              />
            </div>

            {CurrentTypeComponent && (
              <CurrentTypeComponent
                timezone={timezone}
                control={control}
                schedule={
                  scheduleById && activeType === scheduleById.type ? scheduleById : undefined
                }
                branchSchedule={isCreateNewBranchSchedule ? maxBranchSchedule : branchSchedule}
                setValue={setValue}
                getValues={getValues}
                disabled={!isEditingAllowed || archived}
              />
            )}
          </Dialog.Body>

          <Dialog.Footer>
            {showSaveButton && (
              <SaveButton
                onClick={handleSubmit(mutateSchedule)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />

            {isEditingAllowed &&
              !scheduleById?.branch.length &&
              !isCreate &&
              (scheduleById?.archived ? (
                <RestoreButton className="mr-auto" onClick={() => mutateArchiveSchedule(false)} />
              ) : (
                <ArchiveButton className="mr-auto" onClick={handleOnArchive} />
              ))}
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {archiveConfirmationModal}
      {warningModal}
      {confirmPopup}
    </>
  )
}

export const SchedulerDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog
      title={t('schedule.name')}
      className="flex min-w-256 h-138"
      closeDialog={closeDialog}
    >
      <div className="border-r-2 pr-6 border-gray-100 dark:border-gray-600 w-1/3">
        <PlaceholderInput label={t('title')} className="mb-4" />

        <HorizontalButtonSwitch
          value={undefined}
          label={t('type')}
          onChange={() => {
            return
          }}
          options={translateItemsName(schedulesTypesOptions, t)}
          disabled
        />
      </div>

      <div className="w-full">
        <Spinner expandCentered />
      </div>
    </PlaceholderDialog>
  )
}

const schedulesTypesOptions: Array<{
  id: string
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: FC<any>
}> = [
  {
    id: ScheduleType.WEEKLY.toString(),
    name: 'schedulesTypes.week',
    component: WeeklySchedule,
  },
  {
    id: ScheduleType.INTERVAL.toString(),
    name: 'schedulesTypes.interval',
    component: IntervalSchedule,
  },
]

const maxBranchSchedule: WeeklyScheduleType = {
  id: -1,
  name: '',
  type: ScheduleType.WEEKLY,
  archived: null,
  data: [
    [5, 23],
    [5, 23],
    [5, 23],
    [5, 23],
    [5, 23],
    [5, 23],
    [5, 23],
  ],
}
