import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { differenceInCalendarDays } from 'date-fns'
import { InfoTabClientSubscription } from 'widgets/ClientDialog/InfoTab/InfoTabClientSubscription'
import { useFetchMyPermissions } from 'gql/employee'
import { permissions } from '@expane/logic/permission'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import {
  ServerBriefClientDocumentType,
  useFetchBriefClientDocuments,
  useFetchClientBalance,
  useFetchClientById,
  useFetchCurrentBranchTimezone,
  useFetchLastVisitOfClient,
} from '@expane/data'
import { IoAlertCircleOutline, IoCalendarOutline } from 'react-icons/io5'
import { useBookingsSelectionDialog } from 'widgets/BookingsSelectionDialog'
import { createCurrentDate } from '@expane/date'
import { store } from 'store'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { observer } from 'mobx-react-lite'

interface BookingClientBlockWidgetProps {
  watchedClientId: number | null
  watchedDate: Date | undefined
  subscriptionBlockHeight?: 'normal' | 'small'
}

export const BookingClientBlockWidget: FC<BookingClientBlockWidgetProps> = observer(
  ({ watchedClientId, watchedDate, subscriptionBlockHeight = 'normal' }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()
    const formatDate = useDateFormatting()
    const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: watchedClient } = useFetchClientById(watchedClientId, timezone)
    const { data: watchedClientDocuments } = useFetchBriefClientDocuments(watchedClientId, timezone)
    const { data: lastVisit } = useFetchLastVisitOfClient(watchedClientId, timezone)
    const { data: clientBalance } = useFetchClientBalance(watchedClientId, branchId)

    const isActualExpirationDateDocument = checkIsActualExpirationDateDocument(
      watchedClientDocuments,
      timezone,
    )

    const { data: myPermissions } = useFetchMyPermissions()
    const isGetClientBalanceAllowed = myPermissions?.includes(permissions.transaction.get)
    const isCardsGetAllowed = myPermissions?.includes(permissions.card.get)
    const isBookingsSelectionDialogAllowed =
      myPermissions?.includes(permissions.transaction.set) &&
      myPermissions?.includes(permissions.booking.get)
    const isGetLastVisitAllowed = myPermissions?.includes(permissions.transaction.get)

    const { bookingSelectionDialog, openBookingsSelectionDialog } = useBookingsSelectionDialog()

    return (
      <div>
        {Boolean(watchedClientId) && (
          <div className="mb-2">
            <div className="flex items-center justify-between">
              {isGetClientBalanceAllowed && (
                <p className={labelClassName}>
                  {t('onAccount')}:
                  <span className={clientsInfoStyle}>{convertToMoneyCode(clientBalance ?? 0)}</span>
                </p>
              )}

              {isBookingsSelectionDialogAllowed && (
                <button
                  className={clientsBookingsButtonStyle}
                  onClick={() =>
                    openBookingsSelectionDialog({
                      clientId: watchedClientId,
                      date: watchedDate,
                    })
                  }
                >
                  <IoCalendarOutline className="mr-1" size="1rem" />
                  {t('bookingsOfClient')}
                </button>
              )}
            </div>

            <p className={labelClassName}>
              {t('lead')}:
              {watchedClient?.lead ? (
                <span className={clientsInfoStyle}>{watchedClient.lead.name}</span>
              ) : (
                <span className={clientsInfoStyle}>{t('none(lead)')}</span>
              )}
            </p>

            {isGetLastVisitAllowed && (
              <p className={labelClassName}>
                {t('lastVisit')}:
                <span className={clientsInfoStyle}>
                  {lastVisit ? formatDate('historyDate', lastVisit) : t('firstTime')}
                </span>
              </p>
            )}
          </div>
        )}

        {isActualExpirationDateDocument ? (
          <div className="flex mb-2">
            <IoAlertCircleOutline size="1rem" className="text-error-500 mr-1" />
            <p className="text-xs text-error-500">{t('documentExpires')}</p>
          </div>
        ) : null}

        {Boolean(watchedClientId) && isCardsGetAllowed && (
          <InfoTabClientSubscription clientId={watchedClientId} height={subscriptionBlockHeight} />
        )}
        {bookingSelectionDialog}
      </div>
    )
  },
)

const labelClassName = 'text-xs leading-3 font-medium text-gray-400'
const clientsInfoStyle = `text-sm text-main-color ml-2`
const clientsBookingsButtonStyle = `py-0.5 px-1 font-medium text-xs text-primary-500 border border-primary-500 
rounded-md leading-3 cursor-pointer flex items-center hover:text-primary-700`

const checkIsActualExpirationDateDocument = (
  watchedClientDocuments: ServerBriefClientDocumentType[] | undefined,
  timezone: string | undefined,
) => {
  if (!watchedClientDocuments) return false

  const actualExpirationDateDocuments: Array<{ startDate: Date; endDate: Date }> = []

  for (const { startDate, endDate } of watchedClientDocuments) {
    if (startDate && endDate) {
      if (
        differenceInCalendarDays(endDate, createCurrentDate(timezone)) <=
        DIFFERENCE_IN_DAYS_TO_SHOW_DOCUMENT_NOTIFICATION
      ) {
        actualExpirationDateDocuments.push({ startDate, endDate })
      }
    }
  }

  // сейчас сообщение с окончанием срока действия есть у тех, у кого добавлен хоть один документ
  return actualExpirationDateDocuments.length > 0 && watchedClientDocuments.length !== 0
}

const DIFFERENCE_IN_DAYS_TO_SHOW_DOCUMENT_NOTIFICATION = 5
