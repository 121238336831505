import { ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { IoAlertCircleOutline, IoCheckmarkDoneSharp, IoClose } from 'react-icons/io5'
import { Button } from '../components/Button'
import { Dialog } from '../components/Dialog'
import { Modal } from '../components/Modal'
import { usePopupOpenState } from './usePopupOpenState'

type CustomButtonProps = { text: string; Icon?: IconType }

export type ConfirmationArgs = {
  title: string
  description: string | ReactElement
  confirmButton?: CustomButtonProps
  cancelButton?: CustomButtonProps
  onConfirm: () => void
  onDeny?: () => void
}

export const useShowConfirmationPopup = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()
  const { t } = useTranslation()

  const confirmationTitle = useRef('')
  const confirmationDescription = useRef<string | ReactElement>('')
  const confirmationConfirmButton = useRef<CustomButtonProps | undefined>(undefined)
  const confirmationCancelButton = useRef<CustomButtonProps | undefined>(undefined)

  const onConfirmFunction = useRef(() => {
    return
  })
  const onDenyFunction = useRef<() => void | undefined>()

  const showConfirmation = ({
    title,
    description,
    confirmButton,
    cancelButton,
    onConfirm,
    onDeny,
  }: ConfirmationArgs) => {
    onConfirmFunction.current = onConfirm
    if (onDeny) onDenyFunction.current = onDeny
    confirmationTitle.current = title
    confirmationDescription.current = description

    confirmationConfirmButton.current = {
      text: confirmButton?.text ?? t('submit'),
      Icon: confirmButton?.Icon ?? IoCheckmarkDoneSharp,
    }
    confirmationCancelButton.current = {
      text: cancelButton?.text ?? t('cancel'),
      Icon: cancelButton?.Icon ?? IoClose,
    }
    openPopup()
  }

  const confirmAction = () => {
    if (isOpen) {
      closePopup()
      onConfirmFunction.current()
    }
  }

  const denyAction = () => {
    closePopup()
    onDenyFunction.current?.()
  }

  const confirmationModal = isOpen ? (
    <Modal close={denyAction} confirm={confirmAction}>
      <Dialog>
        <Dialog.Title>{confirmationTitle.current}</Dialog.Title>
        <Dialog.Body className="flex items-center max-w-2xl">
          <IoAlertCircleOutline size="4rem" className="mr-2 text-primary-500" />
          <div className="text-main-color whitespace-pre-wrap">
            {confirmationDescription.current}
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            type="primary"
            onClick={e => {
              e.stopPropagation()

              confirmAction()
            }}
            Icon={confirmationConfirmButton.current?.Icon}
          >
            {confirmationConfirmButton.current?.text}
          </Button>
          <Button
            type="outline"
            onClick={e => {
              e.stopPropagation()

              denyAction()
            }}
            Icon={confirmationCancelButton.current?.Icon}
          >
            {confirmationCancelButton.current?.text}
          </Button>
        </Dialog.Footer>
      </Dialog>
    </Modal>
  ) : null

  return {
    confirmationModal,
    showConfirmation,
  }
}
