import { useFetchBusinessesToSwitch } from '@expane/data'
import { Button } from '@expane/ui'
import { useModuleLazy } from 'logic/hooks/useModuleLazy'
import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoLogoAndroid,
  IoLogoApple,
  IoLogOutOutline,
  IoSwapHorizontalOutline,
} from 'react-icons/io5'
import { firebase } from 'services/firebase'
import { useOpenChooseBusinessDialog } from 'widgets/ChooseBusinessDialog'
import { getExpaneClientCabinetLink, storeLinks } from '@expane/logic/links'
import { config } from 'config'

export const ClientModePage: FC = () => {
  const { t } = useTranslation()

  const { data: businesses } = useFetchBusinessesToSwitch()
  const businessesAsEmployee = businesses?.filter(business => business.asEmployee)

  const [QRCode] = useModuleLazy(async () => await import('qrcode'))

  const { openChooseBusinessDialog, chooseBusinessDialog } = useOpenChooseBusinessDialog(true)

  const appStoreCanvasRef = useRef<HTMLCanvasElement>(null)
  const playStoreCanvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (appStoreCanvasRef.current && QRCode) {
      QRCode.toCanvas(appStoreCanvasRef.current, storeLinks.ios, {
        width: 256,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStoreCanvasRef.current, QRCode])

  useEffect(() => {
    if (playStoreCanvasRef.current && QRCode) {
      QRCode.toCanvas(playStoreCanvasRef.current, storeLinks.android, {
        width: 256,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playStoreCanvasRef.current, QRCode])

  const clientCabinetLink = getExpaneClientCabinetLink(config.ENV === 'development')

  return (
    <div className="flex-centered flex-col flex-1">
      <h2 className="w-3/4 text-xl text-center text-main-color">
        {t('clientModePage.1')}
        <a href={clientCabinetLink} className={'underline mx-1'}>
          {t('clientModePage.2')}
        </a>
        {t('clientModePage.3')}
      </h2>

      <div className="w-3/4 text-center mt-8">
        <div className="flex justify-center gap-4 mb-4">
          <div>
            <p className={qrCodeLabelStyle}>
              <IoLogoApple size="1.4rem" />
              App store
            </p>

            <div className={qrCodeContainerStyle}>
              <canvas ref={appStoreCanvasRef} />
            </div>
          </div>

          <div>
            <p className={qrCodeLabelStyle}>
              <IoLogoAndroid size="1.4rem" />
              Google Play
            </p>
            <div className={qrCodeContainerStyle}>
              <canvas ref={playStoreCanvasRef} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-center gap-4">
        {businessesAsEmployee && businessesAsEmployee.length ? (
          <Button
            className="w-fit"
            onClick={() => openChooseBusinessDialog()}
            Icon={IoSwapHorizontalOutline}
          >
            {t('continueAsEmployee.action')}
          </Button>
        ) : undefined}

        <Button className="w-44" onClick={firebase.signOut} type="outline" Icon={IoLogOutOutline}>
          {t('signOut')}
        </Button>
      </div>
      {chooseBusinessDialog}
    </div>
  )
}

const qrCodeLabelStyle = 'flex gap-1 items-center justify-center text-secondary-color mb-2 text-lg'
const qrCodeContainerStyle = 'w-64 rounded-md overflow-hidden border-block-color border-2'
