import { useSetCurrentBusinessAsEmployee } from '@expane/data'
import { Spinner } from '@expane/ui'
import { AuthFormWidget } from '@expane/widgets'
import { reloadApp } from 'logic/app'
import { sendAuthEmail } from 'logic/auth'
import { FC, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { firebase } from 'services/firebase'
import { history } from 'services/navigation'
import { reportError } from 'services/sentry'

export const AuthPageParams: FC = () => {
  const location = useLocation()
  const locationState = location.state as { from: string } | undefined
  const { businessId } = useParams<{ businessId: string }>()

  const { mutate, isLoading, isError } = useSetCurrentBusinessAsEmployee(reloadApp)

  useEffect(() => {
    const cb = async () => {
      if (isLoading) return

      const xHasuraClaims = await firebase.getXHasuraClaims()
      if (xHasuraClaims) {
        const businessIdClaims = xHasuraClaims?.['x-hasura-business-id']

        if (!businessIdClaims || businessIdClaims !== businessId) {
          mutate(Number(businessId))
        } else history.push({ pathname: '/' })
      }
    }

    cb().then()
  }, [businessId, isLoading, mutate])

  if (isError) {
    firebase.signOut()
  }

  return (
    <div className="flex-centered flex-1">
      {isLoading ? (
        <Spinner />
      ) : (
        <AuthFormWidget
          pathname={location.pathname}
          redirectedFrom={locationState?.from}
          reportError={reportError}
          signInWithEmailAndPassword={firebase.signInWithEmailAndPassword}
          safetyLoginWithPopup={firebase.safetyLoginWithPopup}
          sendAuthEmail={sendAuthEmail}
        />
      )}
    </div>
  )
}
