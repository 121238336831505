import {
  EmployeeByIdExtended,
  EmployeeGroupWithSchedule,
  ServerTransactionByEmployeeIdType,
  TRANSACTION_TYPES,
} from '@expane/data'
import { OWNER_ROLE_ID } from '../permission'
import { transformPersonName } from '../utils'

export const getEmployeeTransactionsToShowInHistory = (
  employeeTransactions: ServerTransactionByEmployeeIdType[] | undefined,
) =>
  employeeTransactions?.filter(
    transaction =>
      transaction.type === TRANSACTION_TYPES.payment.id ||
      transaction.type === TRANSACTION_TYPES.refund.id ||
      transaction.type === TRANSACTION_TYPES.paymentInCredit.id,
  )

export interface EmployeeHistoryTabItemDto {
  id: number
  createdAt: Date
  operationType: number
  name: string
  value: number
  isServicePaidByCard: boolean
}

export const transformEmployeeTransactionsForHistory = (
  transactions: ServerTransactionByEmployeeIdType[],
): EmployeeHistoryTabItemDto[] => {
  const transformedTransactions: EmployeeHistoryTabItemDto[] = []

  for (const transaction of transactions) {
    if (transaction.transactionsServices) {
      for (const transactionService of transaction.transactionsServices) {
        transformedTransactions.push({
          id: transformedTransactions.length,
          createdAt: transaction.date,
          name: transactionService.service.name,
          value: transactionService.price,
          isServicePaidByCard: Boolean(transaction.cardId),
          operationType: transaction.type,
        })
      }
    }
  }

  return transformedTransactions
}

export const countEmployeeHistoryTransactionsTotalSum = (
  transactionsWithEmployee: EmployeeHistoryTabItemDto[],
) =>
  transactionsWithEmployee.reduce((accumulator, value) => {
    if (
      value.operationType === TRANSACTION_TYPES.payment.id ||
      value.operationType === TRANSACTION_TYPES.paymentInCredit.id
    )
      accumulator += value.value
    if (value.operationType === TRANSACTION_TYPES.refund.id) accumulator -= value.value

    return accumulator
  }, 0)

export type ServiceIdsHashMap = { [serviceId: number]: true }
// Get ids of all services that were used in bookings with this employee
export const getServiceIdsThatWereUsedWithEmployeeHashMap = (
  employeeById: EmployeeByIdExtended | undefined,
) => {
  if (!employeeById) return {}
  const { bookings } = employeeById
  if (bookings.length === 0) return {}

  const serviceIdsHashMap: ServiceIdsHashMap = {}

  for (const booking of bookings) {
    if (
      booking.serviceId &&
      !Object.prototype.hasOwnProperty.call(serviceIdsHashMap, booking.serviceId)
    )
      serviceIdsHashMap[booking.serviceId] = true

    if (booking.bookingServices.length === 0) continue

    for (const bookingService of booking.bookingServices) {
      if (!Object.prototype.hasOwnProperty.call(serviceIdsHashMap, bookingService.serviceId))
        serviceIdsHashMap[bookingService.serviceId] = true
    }
  }

  return serviceIdsHashMap
}

export const getServiceIdDifferencesInEmployee = (
  employeeById: EmployeeByIdExtended,
  serviceIds: number[],
) => {
  const serviceIdsToDelete: number[] = []
  for (const { service } of employeeById.serviceEmployees) {
    if (!serviceIds.includes(service.id)) serviceIdsToDelete.push(service.id)
  }

  const serviceIdsToInsert: number[] = []
  for (const serviceId of serviceIds) {
    if (!employeeById.serviceEmployees.some(({ service }) => service.id === serviceId))
      serviceIdsToInsert.push(serviceId)
  }

  return { serviceIdsToDelete, serviceIdsToInsert }
}

export const getFilteredEmployeesGroups = (
  employeeById: EmployeeByIdExtended | undefined,
  employeeGroups: EmployeeGroupWithSchedule[],
) => {
  const isItOwner = Boolean(employeeById?.ownBusiness)

  return isItOwner
    ? employeeGroups
    : employeeGroups.filter(({ roleId }) => roleId !== OWNER_ROLE_ID)
}

export const getEmployeeWithSamePhoneNumberFullName = <
  T extends { phone: string | null; id: number; firstName: string; lastName?: string },
>(
  employees: T[],
  phone: string | null | undefined,
  employeeId?: number,
) => {
  const employeeWithSamePhoneNumber = phone
    ? employees.find(employee => employee.phone === phone)
    : null

  if (employeeId && employeeWithSamePhoneNumber) {
    return employeeWithSamePhoneNumber.id === employeeId
      ? {}
      : {
          name: transformPersonName(employeeWithSamePhoneNumber),
          id: employeeWithSamePhoneNumber.id,
        }
  }

  return employeeWithSamePhoneNumber
    ? { name: transformPersonName(employeeWithSamePhoneNumber), id: employeeWithSamePhoneNumber.id }
    : {}
}

export const getMyEmployeeGroupId = (
  myEmployeeId: number | null,
  employeeGroups: EmployeeGroupWithSchedule[] | undefined,
) => {
  const myEmployeeGroup = employeeGroups?.find(employeeGroup =>
    employeeGroup.employees.find(employee => employee.id === myEmployeeId),
  )

  return myEmployeeGroup?.id
}

export const filterEmployeesThatProvideTheseServices = <
  T extends { serviceEmployees: { serviceId: number }[] },
>(
  employees: T[],
  serviceIds: number[] | null,
) => {
  if (serviceIds === null || serviceIds.length === 0) return employees

  return employees.filter(employee =>
    serviceIds.every(serviceId =>
      employee.serviceEmployees.find(serviceEmployee => serviceEmployee.serviceId === serviceId),
    ),
  )
}
