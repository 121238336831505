import { utcToZonedTime } from '@expane/date'
import { queryClient } from '../../api'
import { MOVEMENTS_QUERY_KEY } from './queryKeys'
import { INVENTORIES_QUERY_KEY } from '../inventory/queryKeys'
import { parseDatesInTransactionGqlResponse } from '../transaction/logic'

export enum MOVEMENT_TYPE {
  ARRIVAL = 1,
  EXPENSE,
  WRITEOFF,
  MOVEMENT,
  CUSTOMER_RETURN,
  RETURN,
  POSTING,
  CONSUMABLES_WRITEOFF,
  CONSUMABLES_RETURN,
}

export const MOVEMENT_TYPES = {
  arrival: { id: MOVEMENT_TYPE.ARRIVAL, name: 'arrival.name' }, // Приход товаров от контагента
  expense: { id: MOVEMENT_TYPE.EXPENSE, name: 'expense' }, // Расход товаров при продаже
  writeOff: { id: MOVEMENT_TYPE.WRITEOFF, name: 'writeOff.name' }, // Списание товаров
  movement: { id: MOVEMENT_TYPE.MOVEMENT, name: 'movement.name' }, // Перемещение товаров
  customerReturn: { id: MOVEMENT_TYPE.CUSTOMER_RETURN, name: 'customerReturn' }, // при возврате от клиента оприходуем на склад
  // Возврат товаров контрагенту, Логика возврата на данный момент работает неправильно
  return: { id: MOVEMENT_TYPE.RETURN, name: 'returnToSupplier.name' },
  posting: { id: MOVEMENT_TYPE.POSTING, name: 'posting.name' }, // Оприходование товаров по инвентаризации
  consumablesWriteOff: { id: MOVEMENT_TYPE.CONSUMABLES_WRITEOFF, name: 'consumablesWriteOff' }, // Списание расходников
  consumablesReturn: { id: MOVEMENT_TYPE.CONSUMABLES_RETURN, name: 'consumablesReturn' }, // Возврат расходников
} as const

export type MovementToParse = {
  createdAt?: string | Date | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transactions?: any[] | null
}
export const parseDatesInMovementGqlResponse = <T extends MovementToParse>(
  movement: T,
  timezone: string,
) => {
  if (movement.createdAt) {
    movement.createdAt = utcToZonedTime(movement.createdAt, timezone)
  }
  if (movement.transactions) {
    movement.transactions = movement.transactions.map(transaction =>
      parseDatesInTransactionGqlResponse(transaction, timezone),
    )
  }
  return movement
}

export const invalidateMovements = () => {
  queryClient.invalidateQueries({
    predicate: query => query.queryKey.includes(MOVEMENTS_QUERY_KEY),
  })
  queryClient.invalidateQueries([INVENTORIES_QUERY_KEY])
}
