import { gql } from 'graphql-request'
import { queryClient, request, useMutation, reportGqlError } from '../api'
import { clientByIdQueryKeys } from './client/queryKeys'

export function useBootstrapCalendar(clientId: number) {
  return useMutation(
    async (): Promise<{ bootstrapCalendar: { message: string } }> => {
      return request(
        gql`
          mutation ($clientId: Int!) {
            bootstrapCalendar(clientId: $clientId) {
              message
            }
          }
        `,
        { clientId },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries(clientByIdQueryKeys(clientId)),
      onError: reportGqlError,
    },
  )
}
