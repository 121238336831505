import { BookingUnion, useUpdateBookingStatus } from '@expane/data'
import { BOOKING_STATUSES } from '@expane/logic/booking'
import {
  checkIsBookingAlreadyDoneStatus,
  checkIsBookingDoneStatusCanUpdate,
} from '@expane/logic/booking/status'
import { BookingPaymentData } from '@expane/logic/payment/booking'
import { useWebPersistedState } from '@expane/logic/useWebPersistedState'
import { findById } from '@expane/logic/utils'
import { Button, CloseButton, Dialog, useShowConfirmationPopup } from '@expane/ui'
import { FC, useMemo } from 'react'
import {
  Control,
  SubmitHandler,
  UseFormHandleSubmit,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { reportError } from 'services/sentry'
import { ButtonWithAltOptions } from 'ui/Button/ButtonWithAltOptions'
import { usePayBooking } from './usePayBooking'

type Props = {
  clientId: number
  bookingIds: number[]
  bookings?: BookingUnion[]
  control: Control<BookingPaymentData>
  handleSubmit: UseFormHandleSubmit<BookingPaymentData>
  timezone: string
  closeDialog: () => void
  closePopups: () => void
}

export const FooterPaymentDialogFooter: FC<Props> = ({
  closeDialog,
  closePopups,
  handleSubmit,
  control,
  clientId,
  bookingIds,
  bookings,
  timezone,
}) => {
  const { t } = useTranslation()

  const { warningModal, payBooking } = usePayBooking(clientId, bookingIds)

  const { mutateAsync: updateBookingStatus } = useUpdateBookingStatus()

  const { isSubmitting } = useFormState({ control })

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const watchedServices = useWatch({ control, name: 'servicesDto' })
  const watchedConsumables = useWatch({ control, name: 'consumablesDto' })
  const noItems = watchedServices.length === 0 && watchedConsumables.length === 0

  const options = useMemo(
    () => [
      {
        id: 1,
        label: t('payBooking'),
      },
      {
        id: 2,
        label: t('payAndDone'),
        fullLabel: t('payAndDoneHint'),
      },
    ],
    [t],
  )

  const { storage: optionId, updateStorage: setOptionId } = useWebPersistedState(
    'BookingPaymentOption',
    reportError,
    options[0].id,
  )

  const currentBooking = findById(bookingIds[0], bookings)

  // мы делаем обычную оплату без изменения статуса когда у нас оплата нескольких букингов,
  // или когда это групповой букинг
  const isPaymentWithoutUpdateStatus = bookingIds.length > 1 || currentBooking?.isGroupBooking

  // проверка или изменение статуса на "выполнено" не происходит до начала записи
  const isBookingDoneStatusCanUpdate = checkIsBookingDoneStatusCanUpdate(currentBooking, timezone)

  // проверка или одиночный букинг уже со статусом выполнено
  const isBookingAlreadyDoneStatus = checkIsBookingAlreadyDoneStatus(currentBooking)

  const submitPayment: SubmitHandler<BookingPaymentData> = async data => {
    const success = await payBooking(data)

    if (success) {
      const updateStatusToDone = options[1].id === optionId

      if (updateStatusToDone && isBookingDoneStatusCanUpdate && !isPaymentWithoutUpdateStatus)
        await updateBookingStatus({
          id: data.servicesDto[0].bookingId,
          status: BOOKING_STATUSES.done,
        })

      closeDialog()
    }
  }

  const handleOnClickWithAltOptions = () => {
    const payWithoutDoneStatus = optionId === options[0].id

    if (payWithoutDoneStatus) {
      handleSubmit(submitPayment)()
      return
    }

    if (isBookingAlreadyDoneStatus) {
      showConfirmation({
        title: t('warning'),
        description: (
          <>
            <p>{t('bookingPaymentChangeStatus.warningAlreadyDone')}</p>
            <p>{t('bookingPaymentChangeStatus.continue')}</p>
          </>
        ),
        onConfirm: handleSubmit(submitPayment),
      })
      return
    }

    if (!isBookingDoneStatusCanUpdate) {
      showConfirmation({
        title: t('warning'),
        description: (
          <>
            <p>{t('bookingPaymentChangeStatus.warning')}</p>
            <p>{t('bookingPaymentChangeStatus.continue')}</p>
          </>
        ),
        onConfirm: handleSubmit(submitPayment),
      })
      return
    }

    handleSubmit(submitPayment)()
  }

  return (
    <Dialog.Footer>
      {isPaymentWithoutUpdateStatus ? (
        <Button
          onClick={handleSubmit(submitPayment)}
          disabled={noItems || isSubmitting}
          spinner={isSubmitting}
          Icon={IoCashOutline}
        >
          {bookingIds.length > 1 ? t('payBookings') : t('payBooking')}
        </Button>
      ) : (
        <ButtonWithAltOptions
          // We are sure that there is an option because we have provided a fallback value
          optionId={
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            optionId!
          }
          setOptionId={setOptionId}
          disabled={noItems || isSubmitting}
          spinner={isSubmitting}
          options={options}
          onClick={handleOnClickWithAltOptions}
          pickedOptionLabelWidth="w-60"
        />
      )}

      <CloseButton onClick={closePopups} />
      {confirmationModal}
      {warningModal}
    </Dialog.Footer>
  )
}
