import {
  DayTiming,
  ExtendedEmployee,
  IntervalSchedule,
  ScheduleDto,
  ScheduleType,
  ServerDynamicScheduleDateType,
  ServerTimeOffType,
  WeeklySchedule,
} from '@expane/data'
import {
  areIntervalsOverlapping,
  differenceInCalendarDays,
  getDay,
  isSameDay,
  set,
} from '@expane/date'

export const getEmployeeTimeOffByDay = (
  timeOffsByEmployeeId: ServerTimeOffType[],
  currentDate: Date,
  dayTiming: DayTiming,
) => {
  if (timeOffsByEmployeeId.length === 0) return []

  const { startCurrentDay, endCurrentDay } = setStartAndEndDateByDayTiming(currentDate, dayTiming)

  return timeOffsByEmployeeId
    .filter(timeOff => {
      // оставляем отгулы только те которые пересекаются с рабочим временем по графику
      return areIntervalsOverlapping(timeOff, {
        start: startCurrentDay,
        end: endCurrentDay,
      })
    })
    .map(timeOff => {
      // проеряем начало и конец отгула, чтобы не выходили за пределы календаря
      const start = timeOff.start < startCurrentDay ? startCurrentDay : timeOff.start
      const end = timeOff.end > endCurrentDay ? endCurrentDay : timeOff.end

      return { ...timeOff, start, end }
    })
}

export const setStartAndEndDateByDayTiming = (currentDate: Date, dayTiming: DayTiming) => {
  const startCurrentDay = set(currentDate, {
    hours: Math.floor(dayTiming[0]),
    minutes: Number.isInteger(dayTiming[0]) ? 0 : 30,
    seconds: 0,
    milliseconds: 0,
  })
  const endCurrentDay = set(currentDate, {
    hours: Math.floor(dayTiming[1]),
    minutes: Number.isInteger(dayTiming[1]) ? 0 : 30,
    seconds: 0,
    milliseconds: 0,
  })

  return { startCurrentDay, endCurrentDay }
}

// Returns the number of week day
// 0 - Monday, 1 - Tuesday, 2 - Wednesday, 3 - Thursday, 4 - Friday, 5 - Saturday, 6 - Sunday
export const getWeekDay = (date: Date): number => {
  const weekDay = getDay(date)
  return weekDay === 0 ? 6 : weekDay - 1 // Sunday should be last
}

export const defineDayTiming = (schedule: ScheduleDto | undefined, date: Date): DayTiming => {
  const closedDayTiming = CLOSED_DAY_TIMING

  if (schedule?.type === ScheduleType.WEEKLY)
    return schedule?.data[getWeekDay(date)] ?? (closedDayTiming as DayTiming)
  return schedule?.data.timing ?? CLOSED_DAY_TIMING
}

export const CLOSED_DAY_TIMING: DayTiming = [0, 0]

export const getCalendarStartHour = (schedule: ScheduleDto | undefined): number => {
  if (schedule?.type === ScheduleType.WEEKLY)
    return Math.min(
      schedule?.data?.[0]?.[0] ?? 24,
      schedule?.data?.[1]?.[0] ?? 24,
      schedule?.data?.[2]?.[0] ?? 24,
      schedule?.data?.[3]?.[0] ?? 24,
      schedule?.data?.[4]?.[0] ?? 24,
      schedule?.data?.[5]?.[0] ?? 24,
      schedule?.data?.[6]?.[0] ?? 24,
    )

  return schedule?.data?.timing?.[0] ?? 24
}

export const getCalendarFinishHour = (schedule: ScheduleDto | undefined) => {
  if (schedule?.type === ScheduleType.WEEKLY)
    return Math.max(
      schedule.data?.[0]?.[1] ?? 0,
      schedule.data?.[1]?.[1] ?? 0,
      schedule.data?.[2]?.[1] ?? 0,
      schedule.data?.[3]?.[1] ?? 0,
      schedule.data?.[4]?.[1] ?? 0,
      schedule.data?.[5]?.[1] ?? 0,
      schedule.data?.[6]?.[1] ?? 0,
    )
  return schedule?.data?.timing?.[1] ?? 0
}

export const getIsWorkingDayFromIntervalSchedule = (
  schedule: Omit<IntervalSchedule, 'id' | 'name'>,
  scheduleStartDate: Date,
  currentDate: Date,
): boolean => {
  const difference = differenceInCalendarDays(currentDate, scheduleStartDate)
  if (difference < 0) return false
  return difference % (schedule.data.workDays + schedule.data.breakDays) < schedule.data.workDays
}

export const getIsWorkingDayFromWeeklySchedule = (schedule: WeeklySchedule, currentDate: Date) => {
  const weekDayNumber = getWeekDay(currentDate)
  return Boolean(schedule.data[weekDayNumber])
}

export const getIsWorkingDayFromDynamicSchedule = (
  dynamicDates: ServerDynamicScheduleDateType[],
  currentDate: Date,
) => dynamicDates.some(dynamicDate => isSameDay(currentDate, dynamicDate.date))

export const checkDayOff = (
  employee: ExtendedEmployee,
  currentDate: Date,
  dayTiming: DayTiming,
) => {
  const timeOffs = employee.timeoffs

  if (!timeOffs || timeOffs.length === 0) return false

  return isTimeOffAllDay({ timeOffs: employee.timeoffs, date: currentDate, dayTiming })
}

// TODO: extract to fragment
type TimeOff = {
  id: number
  start: Date
  end: Date
  employeeId: number
  timeOffReasonId: number
  timeOffReason: { name: string }
}

export const isTimeOffAllDay = ({
  timeOffs,
  date,
  dayTiming,
}: {
  timeOffs: TimeOff[]
  date: Date
  dayTiming: DayTiming
}) => {
  const { startCurrentDay, endCurrentDay } = setStartAndEndDateByDayTiming(date, dayTiming)

  return timeOffs.some(timeOff => {
    return timeOff.start <= startCurrentDay && timeOff.end >= endCurrentDay
  })
}
