import { utcToZonedTime } from '@expane/date'
import { MovementToParse, parseDatesInMovementGqlResponse } from '../movement/logic'

type InventoryToParse = {
  inventoryDate?: string | Date | null
  movements?: MovementToParse[] | null
}
export const parseDatesInInventoryGqlResponse = <T extends InventoryToParse>(
  inventory: T,
  timezone: string,
) => {
  if (inventory.inventoryDate) {
    inventory.inventoryDate = utcToZonedTime(inventory.inventoryDate, timezone)
  }
  if (inventory.movements) {
    inventory.movements = inventory.movements.map(movement =>
      parseDatesInMovementGqlResponse(movement, timezone),
    )
  }

  return inventory
}
