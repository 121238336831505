import { gql } from 'graphql-request'

export const timeOffBriefFragment = gql`
  fragment timeOffBriefType on timeOff {
    id
    start
    end
  }
`

export const timeOffFragment = gql`
  ${timeOffBriefFragment}
  fragment timeOffType on timeOff {
    ...timeOffBriefType
    employeeId
    timeOffReasonId
    timeOffReason {
      name
    }
  }
`

export const timeOffByIdFragment = gql`
  ${timeOffFragment}
  fragment timeOffByIdType on timeOff {
    ...timeOffType
    employee {
      firstName
      lastName
    }
    author {
      firstName
      lastName
    }
  }
`
