import {
  ServerTimeOffByIdType,
  useCancelTimeOff,
  useCreateTimeOff,
  useFetchBookingsOfDate,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchTimeOffById,
  useFetchTimeOffReasons,
  useUpdateTimeOffById,
} from '@expane/data'
import {
  addDays,
  addMinutes,
  createCurrentDate,
  DEFAULT_TIMEZONE,
  zonedTimeToUtc,
} from '@expane/date'
import { checkBookingsOverlap } from '@expane/logic/bookingChecks'
import { permissions } from '@expane/logic/permission'
import { findById, transformPersonName } from '@expane/logic/utils'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  PlaceholderInput,
  SelectDropdown,
  usePopupOpenState,
  useShortCut,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyEmployee, useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, RefObject, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { EditButton } from 'ui/EditButton'
import { RangeDatePicker } from 'ui/RangeDatePicker'
import { ArchiveButton, SaveButton } from 'widgets/Buttons'
import { TimeOffReasonDialog } from 'widgets/TimeOffReasonDialog'
import {
  checkIsTimeOffReasonAllowedForCreation,
  getTimeOffReasonEmployeeGroupNames,
} from '@expane/logic/timeOff'
import { getMyEmployeeGroupId } from '@expane/logic/employee'

interface Props {
  closeDialog: () => void
  initialTimeOffId?: number
  initialStartDate?: Date
  initialEndDate?: Date
  initialEmployeeId?: number
  isCreate: boolean
}

const TimeOffDialog: FC<Props> = observer(props => {
  const modalRef = useRef<HTMLDivElement>(null)

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: timeOffById, isLoading: isLoadingTimeOffById } = useFetchTimeOffById(
    props.initialTimeOffId,
    timezone,
  )
  const { data: timeOffReasons, isLoading: isLoadingTimeOffReasons } = useFetchTimeOffReasons()
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()
  const { data: myEmployee, isLoading: isLoadingMyEmployee } = useFetchMyEmployee(
    timezone,
    branchId,
  )
  const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
    timezone,
    branchId,
  )

  const isLoading =
    (!props.isCreate && isLoadingTimeOffById) ||
    isLoadingTimeOffReasons ||
    isLoadingMyPermissions ||
    isLoadingMyEmployee ||
    isLoadingEmployeeGroups
  const isNoData =
    (!props.isCreate && !timeOffById) ||
    !timezone ||
    !timeOffReasons ||
    !myPermissions ||
    !myEmployee ||
    !employeeGroups

  useErrorOpeningDialog(!isLoading && isNoData, props.closeDialog)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={props.closeDialog} ref={modalRef}>
      <Dialog>
        {isLoading ? (
          <TimeOffDialogPlaceholder closeDialog={props.closeDialog} />
        ) : (
          <LoadedTimeOffDialog
            {...props}
            timezone={timezone ?? DEFAULT_TIMEZONE}
            modalRef={modalRef}
          />
        )}
      </Dialog>
    </Modal>
  )
})

const LoadedTimeOffDialog: FC<Props & { timezone: string; modalRef: RefObject<HTMLDivElement> }> =
  observer(
    ({
      closeDialog,
      initialStartDate,
      initialEndDate,
      initialEmployeeId,
      initialTimeOffId,
      isCreate,
      timezone,
      modalRef,
    }) => {
      const branchId = store.branch.branchId
      const { t } = useTranslation()
      const { data: timeOffById } = useFetchTimeOffById(initialTimeOffId, timezone)

      const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { isSubmitting },
      } = useForm<TimeOffDialogData>({
        defaultValues: {
          period: getDefaultPeriod({
            initialEndDate,
            initialStartDate,
            timeOffById,
            isCreate,
            timezone,
          }),
          timeOffReasonId: isCreate ? undefined : timeOffById?.timeOffReasonId,
        },
      })

      const watchedPeriod = watch('period')
      const { data: bookingsOfPeriod } = useFetchBookingsOfDate(watchedPeriod, timezone, branchId)
      const bookingsWithThisEmployee = bookingsOfPeriod?.filter(
        booking => booking.employee?.id === initialEmployeeId,
      )

      const { data: myPermissions } = useFetchMyPermissions()
      const { data: myEmployee } = useFetchMyEmployee(timezone, branchId)
      const { data: timeOffReasons } = useFetchTimeOffReasons()
      const { data: employeeGroups } = useFetchEmployeesGroups(timezone, branchId)

      const author = isCreate ? myEmployee : timeOffById?.author

      const { mutateAsync: createTimeOff } = useCreateTimeOff()
      const { mutateAsync: updateTimeOffById } = useUpdateTimeOffById()
      const { mutateAsync: cancelTimeOff } = useCancelTimeOff()

      const { dialog: timeOffReasonDialog, openCreateDialog: openTimeOffReasonDialog } =
        useOpenDialog(TimeOffReasonDialog)

      const [openSnackbar] = useSnackbar()
      const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

      const mutateTimeOff: SubmitHandler<TimeOffDialogData> = data => {
        const start = zonedTimeToUtc(data.period[0], timezone)
        const end = zonedTimeToUtc(data.period[1], timezone)
        const thereAreBookings = bookingsWithThisEmployee?.some(booking =>
          checkBookingsOverlap(
            { startDate: start, endDate: end },
            {
              startDate: booking.startDate,
              endDate: addMinutes(booking.startDate, booking.duration),
            },
          ),
        )

        const timeOffReason = findById(data.timeOffReasonId, timeOffReasons)
        const myEmployeeGroupId = getMyEmployeeGroupId(store.me.employeeId, employeeGroups)

        const isTimeOffReasonAllowedForCreation = checkIsTimeOffReasonAllowedForCreation(
          timeOffReason,
          myEmployeeGroupId,
        )

        if (!isTimeOffReasonAllowedForCreation) {
          const groupNames = getTimeOffReasonEmployeeGroupNames(timeOffReason)

          openSnackbar(t('timeOff.timeOffReasonError', { name: groupNames }), 'error')

          return
        }

        const onConfirm = async () => {
          if (isCreate) {
            const result = await createTimeOff({
              start,
              end,
              timeOffReasonId: data.timeOffReasonId,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              employeeId: initialEmployeeId!,
            })

            if (result?.insertTimeOff.id) {
              openSnackbar(t('timeOff.createdSuccess'), 'success')
            } else {
              openSnackbar(t('submitError'), 'error')
            }
          } else {
            const result = await updateTimeOffById({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              id: initialTimeOffId!,
              timeOffsSetInput: {
                start,
                end,
                timeOffReasonId: data.timeOffReasonId,
              },
            })

            if (result?.updateTimeOffById.id) {
              openSnackbar(t('timeOff.updatedSuccess'), 'success')
            } else {
              openSnackbar(t('submitError'), 'error')
            }
          }
          closeDialog()
        }

        if (thereAreBookings)
          showConfirmation({
            title: isCreate ? t('creating') : t('editing'),
            onConfirm,
            description: `${t('bookingValidation.employeeIsBusy')}. ${
              isCreate ? t('timeOff.createConfirmation') : t('timeOff.updateConfirmation')
            }`,
          })
        else onConfirm()
      }

      const handleCancelTimeOff = async () => {
        if (initialTimeOffId) {
          const result = await cancelTimeOff({ id: initialTimeOffId })

          if (result?.updateTimeOffById.id) {
            openSnackbar(t('timeOff.cancelSuccess'), 'success')
          } else {
            openSnackbar(t('submitError'), 'error')
          }
          closeDialog()
        }
      }

      const isEditingAllowed = myPermissions?.includes(permissions.timeOff.set)
      const isTimeOffReasonEditingAllowed = myPermissions?.includes(permissions.timeOffReason.set)

      useShortCut(
        ['Enter'],
        () => {
          if (isEditingAllowed) handleSubmit(mutateTimeOff)()
        },
        modalRef.current,
      )

      return (
        <>
          <Dialog.Title>{t('timeOff.name')}</Dialog.Title>

          <Dialog.Body className="w-148">
            <div className="flex">
              <Input
                containerClassName="w-1/2 pr-2"
                value={author ? transformPersonName(author) : undefined}
                disabled
                label={t('created')}
              />
              <div className="flex items-end w-1/2">
                <Controller
                  control={control}
                  name="timeOffReasonId"
                  rules={{ required: true }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <SelectDropdown
                      value={value}
                      placeholder={t('placeholders.vacation')}
                      noDataMessage={t('noSuchTimeOff')}
                      onSelectChange={onChange}
                      items={timeOffReasons}
                      disabled={!isEditingAllowed}
                      errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                      label={t('timeOff.type')}
                      className="w-full"
                      required
                    />
                  )}
                />
                {isTimeOffReasonEditingAllowed && (
                  <EditButton
                    onClick={() => openTimeOffReasonDialog(id => setValue('timeOffReasonId', id))}
                    className="ml-2 h-9.5 mb-4"
                  />
                )}
              </div>
            </div>
            <Controller
              control={control}
              name="period"
              render={({ field: { value, onChange } }) => (
                <RangeDatePicker
                  timezone={timezone}
                  value={value}
                  type="dateTime"
                  onChange={onChange}
                  label={t('period')}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </Dialog.Body>
          <Dialog.Footer>
            <SaveButton
              onClick={handleSubmit(mutateTimeOff)}
              disabled={!isEditingAllowed || isSubmitting}
              isCreate={isCreate}
              spinner={isSubmitting}
            />
            <CloseButton onClick={closeDialog} />

            {!isCreate && (
              <ArchiveButton
                className="mr-auto"
                onClick={() =>
                  showConfirmation({
                    title: t('timeOff.cancel'),
                    description: t('timeOff.cancelConfirmation'),
                    onConfirm: handleCancelTimeOff,
                  })
                }
              />
            )}
          </Dialog.Footer>
          {timeOffReasonDialog}
          {confirmationModal}
        </>
      )
    },
  )

const TimeOffDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('timeOff.name')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <div className="flex w-full mb-4">
          <PlaceholderInput label={t('created')} className="w-1/2 pr-2" />
          <PlaceholderInput label={t('timeOff.type')} className="w-1/2" />
        </div>

        <PlaceholderInput label={t('period')} className="w-full" />
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}

export type OpenCreateTimeOffDialogFunc = (dto: {
  initialStartDate: Date
  initialEmployeeId: number
  initialEndDate?: Date
}) => void
export type OpenEditTimeOffDialogFunc = (id: number) => void
export const useOpenTimeOffDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogIsCreate = useRef<boolean>(true)

  // create
  const dialogStartDate = useRef<Date | undefined>()
  const dialogEndDate = useRef<Date | undefined>()
  const dialogEmployeeId = useRef<number | undefined>()
  // edit
  const dialogTimeOffId = useRef<number | undefined>()

  const openCreateTimeOffDialog: OpenCreateTimeOffDialogFunc = ({
    initialStartDate,
    initialEmployeeId,
    initialEndDate,
  }: {
    initialStartDate: Date
    initialEmployeeId: number
    initialEndDate?: Date
  }) => {
    dialogIsCreate.current = true
    dialogStartDate.current = initialStartDate
    dialogEndDate.current = initialEndDate
    dialogEmployeeId.current = initialEmployeeId
    openPopup()
  }

  const openEditTimeOffDialog: OpenEditTimeOffDialogFunc = timeOffId => {
    dialogIsCreate.current = false
    dialogTimeOffId.current = timeOffId
    openPopup()
  }

  const timeOffDialog = isOpen ? (
    <TimeOffDialog
      closeDialog={closePopup}
      isCreate={dialogIsCreate.current}
      initialEmployeeId={dialogEmployeeId.current}
      initialTimeOffId={dialogTimeOffId.current}
      initialEndDate={dialogEndDate.current}
      initialStartDate={dialogStartDate.current}
    />
  ) : null

  return { openCreateTimeOffDialog, openEditTimeOffDialog, timeOffDialog }
}

const getDefaultPeriod = ({
  isCreate,
  initialEndDate,
  initialStartDate,
  timeOffById,
  timezone,
}: {
  isCreate: boolean
  timeOffById: ServerTimeOffByIdType | undefined
  initialStartDate: Date | undefined
  initialEndDate: Date | undefined
  timezone: string
}): [Date, Date] => {
  const start = (isCreate ? initialStartDate : timeOffById?.start) ?? createCurrentDate(timezone)
  const end =
    (isCreate ? initialEndDate : timeOffById?.end) ??
    addDays(initialStartDate ?? createCurrentDate(timezone), 1)

  return [start, end]
}

type TimeOffDialogData = {
  period: [Date, Date]
  timeOffReasonId: number
}
