import { useAreManyEmployeesAllowed } from '@expane/logic/billing'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { useWebPersistedState } from '@expane/logic/useWebPersistedState'
import { ThemeModeSwitch } from '@expane/ui'
import { CREATE_BUSINESS_KEY } from 'pages/CreateBusinessPage/logic'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronForwardSharp } from 'react-icons/io5'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { CreateBusinessType, NavigationRouteType, routes } from 'routes/logic'
import { firebase } from 'services/firebase'
import { reportError } from 'services/sentry'
import { ProgressBar } from 'ui/ProgressBar'
import { LanguageSwitch } from 'widgets/LanguageSwitch'
import { useFeatureFlags } from '@expane/logic/featureFlags'

interface PersistStateType {
  createBusinessType: CreateBusinessType
  activeStep: number
}

export const useCreateBusinessSteps = (isAuthorized = true) => {
  const user = firebase.getCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()

  const { storage, updateStorage } = useWebPersistedState<PersistStateType>(
    CREATE_BUSINESS_KEY,
    reportError,
  )

  const { enabledModules } = useBusinessModulesSettings({
    enabled: isAuthorized,
  })
  const { areManyEmployeesAllowed } = useAreManyEmployeesAllowed({
    enabled: isAuthorized,
  })
  const { getFeatureFlag } = useFeatureFlags()

  const setActiveStep = useCallback(
    (step?: number) =>
      step &&
      updateStorage({
        createBusinessType: storage ? storage.createBusinessType : CreateBusinessType.quick,
        activeStep: step,
      }),
    [storage, updateStorage],
  )

  const changeCreateBusinessType = (type: CreateBusinessType) => {
    updateStorage({
      createBusinessType: type,
      activeStep: storage?.activeStep ?? 1,
    })
  }

  const createBusinessStepsRoutes = useMemo(
    () =>
      routes.getCreateBusinessStepsRoutes(
        storage?.createBusinessType ?? CreateBusinessType.quick,
        enabledModules,
        areManyEmployeesAllowed,
        getFeatureFlag,
      ),
    [storage, enabledModules, areManyEmployeesAllowed, getFeatureFlag],
  )

  useEffect(() => {
    if (!user && location.pathname !== '/create-business/authentication')
      navigate('/create-business/authentication')
    if (user && !storage) {
      navigate('/bookings')
    }

    const currentRoute = findRoutePathByStep(storage?.activeStep, createBusinessStepsRoutes)

    if (currentRoute?.path !== location.pathname) {
      setActiveStep(findRouteStepNumber(location.pathname, createBusinessStepsRoutes)?.step)
    }
  }, [location.pathname, user, storage, navigate, createBusinessStepsRoutes, setActiveStep])

  const handleNextStep = (params?: { twice?: boolean }) => {
    const indexOfCurrentRoute = createBusinessStepsRoutes.findIndex(
      el => el.path === location.pathname,
    )
    const nextStep = createBusinessStepsRoutes[indexOfCurrentRoute + (params?.twice ? 2 : 1)]

    navigate(nextStep.path)
  }

  return {
    createBusinessStepsRoutes,
    activeStep: storage?.activeStep ?? 1,
    createBusinessType: storage?.createBusinessType ?? CreateBusinessType.quick,
    proceedToNextStep: handleNextStep,
    changeCreateBusinessType,
  }
}

export const CreateBusinessPage: FC = () => {
  const { createBusinessStepsRoutes, activeStep } = useCreateBusinessSteps(false)

  const isProgressBar = checkIsProgressBar(createBusinessStepsRoutes, activeStep)

  return (
    <div className="p-page flex-1 flex-centered flex-col bg-main h-full overflow-hidden">
      <div className="absolute top-0.5 right-3 flex gap-2 items-center">
        <ThemeModeSwitch reportError={reportError} />
        <LanguageSwitch />
      </div>

      <div className={'flex-1 flex-centered'}>
        {isProgressBar && (
          <ProgressBar
            currentStep={activeStep}
            steps={createBusinessStepsRoutes}
            containerClassName="w-56 mr-4"
          />
        )}

        <div className="flex-centered">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const findRouteStepNumber = (
  currentRouteName: string | undefined,
  stepsArray: Array<{ path: string; step: number }>,
): { path: string; step: number } | undefined =>
  stepsArray.find(item => item.path === currentRouteName)

const findRoutePathByStep = (
  stepNumber: number | undefined,
  stepsArray: Array<{ path: string; step: number }>,
) => stepsArray.find(route => route.step === stepNumber)

export const ProceedToNextStep: FC<{ onClick: () => void; className?: string | undefined }> = ({
  onClick,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <button
      className={
        'flex items-center ml-4 text-lg text-primary-500 hover:text-primary-700 ' + className
      }
      onClick={onClick}
    >
      {t('createBusiness.next')} <IoChevronForwardSharp size="1.2rem" className="ml-1" />
    </button>
  )
}

const checkIsProgressBar = (
  createBusinessStepsRoutes: Array<NavigationRouteType & { step: number }>,
  activeStep: number,
) => {
  const routesToNoShowProgress = createBusinessStepsRoutes.filter(
    route =>
      route.name === 'createBusiness.authentication' ||
      route.name === 'createBusiness.phoneVerification' ||
      route.name === 'createBusiness.finish',
  )

  return !routesToNoShowProgress.some(route => route.step === activeStep)
}
