import { utcToZonedTime } from '@expane/date'

export type TimeOffToParse = {
  start?: string | Date | null
  end?: string | Date | null
}
export const parseDatesInTimeOffGqlResponse = <T extends TimeOffToParse>(
  timeOff: T,
  timezone: string,
) => {
  if (timeOff.start) {
    timeOff.start = utcToZonedTime(timeOff.start, timezone)
  }
  if (timeOff.end) {
    timeOff.end = utcToZonedTime(timeOff.end, timezone)
  }
  return timeOff
}
