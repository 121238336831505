import { getApp, initializeApp } from 'firebase/app'
import {
  Auth,
  AuthCredential,
  getAuth,
  GoogleAuthProvider,
  IdTokenResult,
  isSignInWithEmailLink as fbIsSignInWithEmailLink,
  linkWithCredential,
  OAuthProvider,
  onAuthStateChanged,
  PhoneAuthProvider,
  RecaptchaVerifier,
  sendSignInLinkToEmail as fbSendSignInLinkToEmail,
  signInWithEmailAndPassword as signEmailPasswordFB,
  signInWithEmailLink as fbSignInWithEmailLink,
  signInWithPopup,
  signOut as fbSignOut,
  Unsubscribe,
  User,
} from 'firebase/auth'
import { config } from 'config'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { LoginWithPopupResponse, LoginWithPopupType, XHasuraClaims } from '@expane/logic/auth'
import { store } from 'store'
import { getWebPreferredUserLanguage } from '@expane/i18n'
import { LANGUAGE_LOCAL_STORAGE_KEY } from 'i18n'

const SERVER_STATUS_COLLECTION = 'serverStatus'
const DOC_ID = '1'

let auth: Auth | undefined

let phoneAuthProvider: PhoneAuthProvider | undefined
let googleAuthProvider: GoogleAuthProvider | undefined
let appleAuthProvider: OAuthProvider | undefined

let unsubscribeToServerStatus: () => void | undefined

function initGoogleAuth() {
  googleAuthProvider = new GoogleAuthProvider()
  googleAuthProvider.addScope('https://www.googleapis.com/auth/userinfo.email')

  return googleAuthProvider
}

function initAppleAuth() {
  appleAuthProvider = new OAuthProvider('apple.com')

  return appleAuthProvider
}

function init() {
  try {
    // Already initialised, to not initialise new app on fast refresh
    if (getApp()) return
  } catch {}

  const app = initializeApp({
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    projectId: config.FIREBASE_PROJECT_ID,
    storageBucket: config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
    appId: config.FIREBASE_APP_ID,
  })

  auth = getAuth(app)
  phoneAuthProvider = new PhoneAuthProvider(auth)

  initGoogleAuth()
  initAppleAuth()
}

const signInWithEmailAndPassword = async (email: string, password: string) => {
  if (auth) return await signEmailPasswordFB(auth, email, password)
}

// ===PHONE AUTH====
async function addPhoneProvider(phone: string) {
  const applicationVerifier = new RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'invisible',
    },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    auth!,
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return await phoneAuthProvider!.verifyPhoneNumber(phone, applicationVerifier)
}

async function confirmCode(verificationId: string | undefined, verificationCode: string) {
  if (verificationId) return PhoneAuthProvider.credential(verificationId, verificationCode)
}

// === AUTH ===
let unsubscribeAuthStateChange: Unsubscribe | undefined
function subscribeAuthStateChange(listener: (user: User | null) => void) {
  unsubscribeAuthStateChange?.()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  unsubscribeAuthStateChange = onAuthStateChanged(auth!, listener)
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const signOut = () => fbSignOut(auth!)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const getCurrentUser = (): User | null => auth!.currentUser

const getToken = async (refresh?: boolean): Promise<IdTokenResult | undefined> =>
  await getCurrentUser()?.getIdTokenResult(refresh)

const refreshToken = async (): Promise<IdTokenResult | undefined> => {
  return await getToken(true)
}

const getXHasuraClaims = async (): Promise<XHasuraClaims | string | undefined> => {
  const token = await getToken()
  return token?.claims?.['https://hasura.io/jwt/claims']
}

function sendSignInLinkToEmail(email: string, url: string): Promise<void> {
  getAuth().languageCode = getWebPreferredUserLanguage(LANGUAGE_LOCAL_STORAGE_KEY)
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return fbSendSignInLinkToEmail(auth!, email, {
      url: url,
      handleCodeInApp: true,
    })
      .then(resolve)
      .catch(error => reject(error))
  })
}

function isSignInWithEmailLink(url: string): boolean {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return fbIsSignInWithEmailLink(auth!, url)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function checkEmailLinkComplete(url: string, email: string): Promise<any> {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return fbSignInWithEmailLink(auth!, email, url)
      .then(resolve)
      .catch(error => reject(error))
  })
}

function linkUserWithCredential(user: User, credential: AuthCredential) {
  return linkWithCredential(user, credential)
}

function subscribeToServerStatus() {
  unsubscribeToServerStatus?.()

  const db = getFirestore(getApp())

  unsubscribeToServerStatus = onSnapshot(doc(db, SERVER_STATUS_COLLECTION, DOC_ID), doc => {
    store.serverStatus.setOffline(doc.data()?.offline ?? true)
  })
}

const safetyLoginWithPopup = async (
  loginType: LoginWithPopupType,
): Promise<LoginWithPopupResponse> => {
  if (!auth) return LoginWithPopupResponse.failed

  const locale = getWebPreferredUserLanguage(LANGUAGE_LOCAL_STORAGE_KEY)

  try {
    if (loginType === LoginWithPopupType.google) {
      const googleProvider = googleAuthProvider ?? initGoogleAuth()
      auth.languageCode = locale
      await signInWithPopup(auth, googleProvider)
    } else {
      const appleProvider = appleAuthProvider ?? initAppleAuth()
      appleProvider.setCustomParameters({ locale })

      await signInWithPopup(auth, appleProvider)
    }

    store.auth.setIsWaitingForAuthAfterAuthPopup(true)

    return LoginWithPopupResponse.success
  } catch (e) {
    if (e instanceof Error) {
      if (e.message.includes('auth/popup-closed-by-user'))
        return LoginWithPopupResponse.closedByUser
      else if (e.message.includes('auth/user-cancelled'))
        return LoginWithPopupResponse.canceledByUser
    }
    return LoginWithPopupResponse.failed
  }
}

export const firebase = {
  addPhoneProvider,
  auth,
  checkEmailLinkComplete,
  confirmCode,
  getCurrentUser,
  getXHasuraClaims,
  init,
  isSignInWithEmailLink,
  linkUserWithCredential,
  refreshToken,
  safetyLoginWithPopup,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signOut,
  subscribeAuthStateChange,
  subscribeToServerStatus,
}

export type { User } from 'firebase/auth'
