import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, requestAnonymously, useQuery } from '../../api'
import {
  ANONYMOUSLY_BOOKINGS_REVIEWS,
  ARCHIVED_REVIEWS_QUERY_KEY,
  EMPLOYEES_REVIEWS_AS_CLIENT,
  REVIEWS_QUERY_KEY,
} from './queryKeys'
import {
  bookingReviewFragment,
  employeeReviewFragment,
  reviewAsClientFragment,
  reviewFragment,
} from './review.fragments'
import { BookingReviewType, ReviewAsClientType, ReviewType } from './logic'
import { ServerEmployeeReviewType } from '../../generated/graphql-types'
import { AS_CLIENT_QUERY_KEY } from '../client'
import { DEFAULT_TIMEZONE, utcToZonedTime } from '@expane/date'

export function useFetchAnonymouslyBookingReviewsByBusinessId(
  dto: {
    businessId: number | undefined
    branchId: number | undefined
  },
  timezone: string | undefined,
) {
  return useQuery<BookingReviewType[]>(
    [ANONYMOUSLY_BOOKINGS_REVIEWS, dto],
    async () => {
      const result = await requestAnonymously(
        gql`
          ${bookingReviewFragment}
          query ($businessId: Int!, $branchId: Int!) {
            bookingReviews(
              where: { businessId: { _eq: $businessId }, branchId: { _eq: $branchId } }
              order_by: { createdAt: desc }
            ) {
              ...bookingReviewType
            }
          }
        `,
        dto,
      )

      if (Array.isArray(result?.bookingReviews)) {
        return result.bookingReviews.map(review => ({
          ...review,
          createdAt: utcToZonedTime(review.createdAt, timezone || DEFAULT_TIMEZONE),
        }))
      } else {
        reportError(new Error('bookingReviews is not an array'), 'error', { result })
        return []
      }
    },
    {
      enabled: Boolean(dto.businessId) && Boolean(dto.branchId),
      queryName: 'useFetchAnonymouslyBookingReviewsByBusinessId',
      onError: reportGqlError,
    },
  )
}

export function useFetchEmployeeReviewsAsClient() {
  return useQuery<ServerEmployeeReviewType[]>(
    [EMPLOYEES_REVIEWS_AS_CLIENT],
    async () => {
      const result = await request(
        gql`
          ${employeeReviewFragment}
          query {
            employeeReviews {
              ...employeeReviewType
            }
          }
        `,
      )

      if (Array.isArray(result?.employeeReviews)) {
        return result.employeeReviews
      } else {
        reportError(new Error('employeeReviews is not an array'), 'error', { result })
        return []
      }
    },
    {
      queryName: 'useFetchEmployeeReviewsAsClient',
      onError: reportGqlError,
    },
  )
}

export function useFetchReviews(branchId: number | undefined, timezone: string | undefined) {
  return useQuery<ReviewType[]>(
    [REVIEWS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${reviewFragment}
          query ($branchId: Int!) {
            reviews(
              where: { booking: { branchId: { _eq: $branchId } }, archived: { _is_null: true } }
              order_by: { createdAt: desc }
            ) {
              ...reviewType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.reviews)) {
        return result.reviews.map(review => ({
          ...review,
          createdAt: utcToZonedTime(review.createdAt, timezone || DEFAULT_TIMEZONE),
        }))
      } else {
        reportError(new Error('reviews is not an array'), 'error', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchReviews',
      onError: reportGqlError,
    },
  )
}

export function useFetchArchivedReviews(
  branchId: number | undefined,
  timezone: string | undefined,
) {
  return useQuery<ReviewType[]>(
    [REVIEWS_QUERY_KEY, ARCHIVED_REVIEWS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${reviewFragment}
          query ($branchId: Int!) {
            reviews(
              where: { booking: { branchId: { _eq: $branchId } }, archived: { _is_null: false } }
              order_by: { createdAt: desc }
            ) {
              ...reviewType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.reviews)) {
        return result.reviews.map(review => ({
          ...review,
          createdAt: utcToZonedTime(review.createdAt, timezone || DEFAULT_TIMEZONE),
        }))
      } else {
        reportError(new Error('reviews is not an array'), 'error', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchArchivedReviews',
      onError: reportGqlError,
    },
  )
}

export function useFetchReviewsAsClient(
  branchId: number | undefined,
  timezone: string | undefined,
) {
  return useQuery<ReviewAsClientType[]>(
    [REVIEWS_QUERY_KEY, AS_CLIENT_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${reviewAsClientFragment}
          query ($branchId: Int!) {
            reviews(
              where: { booking: { branchId: { _eq: $branchId } } }
              order_by: { createdAt: desc }
            ) {
              ...reviewAsClientType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.reviews)) {
        return result.reviews.map(review => ({
          ...review,
          createdAt: utcToZonedTime(review.createdAt, timezone || DEFAULT_TIMEZONE),
        }))
      } else {
        reportError(new Error('reviews is not an array'), 'error', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchReviewsAsClient',
      onError: reportGqlError,
    },
  )
}
