import { useFetchCurrentBranchTimezone, usePaySalary } from '@expane/data'
import { zonedTimeToUtc } from '@expane/date'
import { convertNotPaidSalariesToServer } from '@expane/logic/salaryIssues/server'
import { Button, CloseButton, Dialog } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { FC } from 'react'
import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkDoneOutline } from 'react-icons/io5'
import { store } from 'store'
import { IssueSalaryDialogFormValues } from 'widgets/SalaryIssueDialog/index'

interface SalaryIssueDialogFooterProps {
  handleSubmit: UseFormHandleSubmit<IssueSalaryDialogFormValues>
  totalNotPaidSalaries: number
  isShownIssueButton: boolean | undefined
  closeDialog: () => void
  startPeriod: Date
  endPeriod: Date
}

export const SalaryIssueDialogFooter: FC<SalaryIssueDialogFooterProps> = ({
  handleSubmit,
  totalNotPaidSalaries,
  isShownIssueButton,
  closeDialog,
  startPeriod,
  endPeriod,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: paySalary, isLoading } = usePaySalary()
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const [openSnackbar] = useSnackbar()

  const mutateSalary: SubmitHandler<IssueSalaryDialogFormValues> = async ({
    countedNotPaidSalaries,
    accountId,
  }) => {
    if (!countedNotPaidSalaries || !timezone) {
      openSnackbar(t('submitError'), 'error')
      closeDialog()
      return
    }

    const salaryRequestData = convertNotPaidSalariesToServer(countedNotPaidSalaries, timezone)

    const excludedNoughtSalaryIssues = salaryRequestData.filter(salary => salary.value !== 0)

    const employeeIds = countedNotPaidSalaries.map(({ employeeId }) => employeeId)

    const result = await paySalary({
      accountId,
      startPeriod: zonedTimeToUtc(startPeriod, timezone),
      endPeriod: zonedTimeToUtc(endPeriod, timezone),
      salaryRateIssues: excludedNoughtSalaryIssues,
      employeeIds,
    })

    if (result?.paySalary?.success) openSnackbar(t('salary.salaryIssuedSuccessfully'), 'success')
    else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  return (
    <>
      <Dialog.Footer>
        {isShownIssueButton ? (
          <Button
            onClick={handleSubmit(mutateSalary)}
            spinner={isLoading}
            disabled={totalNotPaidSalaries <= 0 || isLoading}
            Icon={IoCheckmarkDoneOutline}
          >
            {t('issue')}
          </Button>
        ) : null}

        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
