import { ServerBillingInfoType } from '../../generated/graphql-types'
import { request, reportGqlError, useQueryOriginal, LONG_STALE_TIME } from '../../api'
import { gql } from 'graphql-request'
import { billingInfoFragment } from './billingInfo.fragments'
import { BILLING_INFO_QUERY_KEY, CURRENT_BUSINESS_MAX_EMPLOYEES_QUERY_KEY } from './queryKeys'
import { DEFAULT_TIMEZONE, utcToZonedTime } from '@expane/date'

export function useFetchLastBillingInfo(timezone: string | undefined) {
  return useQueryOriginal(
    [BILLING_INFO_QUERY_KEY],
    async () => {
      const result = await request(gql`
        ${billingInfoFragment}
        query {
          billingInfos(
            where: { paid: { _eq: true }, type: { _eq: 2 } }
            limit: 1
            order_by: { fromDate: desc }
          ) {
            ...billingInfoType
          }
        }
      `)

      if (result.billingInfos?.[0])
        return {
          ...result.billingInfos[0],
          fromDate: utcToZonedTime(result.billingInfos[0].fromDate, timezone ?? DEFAULT_TIMEZONE),
          toDate: utcToZonedTime(result.billingInfos[0].toDate, timezone ?? DEFAULT_TIMEZONE),
        } as ServerBillingInfoType

      return null
    },
    { onError: reportGqlError, enabled: Boolean(timezone) },
  )
}

export function useFetchLastBusinessCurrentPlanMaxEmployees(enabled = true) {
  return useQueryOriginal<number | null>(
    [BILLING_INFO_QUERY_KEY, CURRENT_BUSINESS_MAX_EMPLOYEES_QUERY_KEY],
    async () => {
      const result = await request(gql`
        query {
          businessCurrentPlanIdView {
            plan {
              maxEmployees
            }
          }
        }
      `)

      if (result.businessCurrentPlanIdView?.[0])
        return result.businessCurrentPlanIdView[0].plan.maxEmployees

      return null
    },
    { onError: reportGqlError, enabled, staleTime: LONG_STALE_TIME },
  )
}
