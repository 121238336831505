import { permissions } from '@expane/logic/permission'
import { TECHNICAL_SUPPORT_TELEGRAM_CHAT } from '@expane/logic/settings'
import { useFetchMyPermissions } from 'gql/employee'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { URLContainer } from 'ui/URLContainer'
import { ManipulateSuperAdminWidget } from './ManipulateSuperAdminWidget'
import { AccountDeletionWidget } from './AccountDeletionWidget'

export const TechnicalSupportSettings: FC = () => {
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()
  const isManipulateSuperAdminAllowed = Boolean(myPermissions?.includes(permissions.business.set))

  return (
    <SettingsWrapper>
      <div className={'mb-2 flex text-gray-500'}>
        <p className={labelClassName}>
          <a
            className={'underline cursor-pointer'}
            href={TECHNICAL_SUPPORT_TELEGRAM_CHAT}
            target={'_blank'}
          >
            {t('technicalSupport.clickText')}
          </a>{' '}
          {t('technicalSupport.text')}
        </p>
      </div>
      <URLContainer url={TECHNICAL_SUPPORT_TELEGRAM_CHAT} />

      {isManipulateSuperAdminAllowed && <ManipulateSuperAdminWidget />}

      <AccountDeletionWidget />
    </SettingsWrapper>
  )
}

export const labelClassName = 'text-sm font-medium text-label-color'
