import { ServerSalaryIssueType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import {
  convertSalaryIssuesToReferralPercentageSalaryItems,
  SalaryItem,
} from '@expane/logic/salaryIssues/converting'
import { getReferralPercentageSalaryIssues } from '@expane/logic/salaryIssues/filters'
import { EmptyPlaceholder, Hint, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoReaderOutline } from 'react-icons/io5'
import { TransactionDialog } from 'widgets/TransactionDialog'

interface ReferralPercentageListProps {
  salaryIssuesByEmployeeId: ServerSalaryIssueType[]
}

export const ReferralPercentageList: FC<PropsWithBranchId<ReferralPercentageListProps>> = ({
  salaryIssuesByEmployeeId,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const dateFormatting = useDateFormatting()

  const { dialog: transactionDialog, openEditDialog: editTransactionDialog } =
    useOpenDialog(TransactionDialog)

  const referralPercentageSalaryItems = convertSalaryIssuesToReferralPercentageSalaryItems(
    getReferralPercentageSalaryIssues(salaryIssuesByEmployeeId),
  )

  const columns = useMemo<ColumnDef<SalaryItem>[]>(
    () => [
      {
        accessorKey: 'date',
        header: t('dateTitle'),
        cell: data => dateFormatting('shortDate', data.getValue<Date>()),
      },
      {
        accessorKey: 'client',
        header: t('client.name'),
      },
      {
        accessorKey: 'name',
        header: t('service.name'),
      },
      {
        accessorKey: 'price',
        header: () => (
          <span className="w-full text-right">
            {t('price')} <Hint>{t('salary.sellPriceHint')}</Hint>
          </span>
        ),
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
      },
      {
        accessorKey: 'totalSum',
        header: () => <span className="w-full text-right">{t('salary.name')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
      },
      {
        accessorKey: 'isSalaryPaid',
        header: t('salary.Paid'),
        cell: data =>
          data.getValue<boolean>() ? <IoCheckmark size="1rem" className="ml-auto" /> : '-',
      },
    ],

    [convertNumberToMoney, dateFormatting, t],
  )

  return (
    <>
      {referralPercentageSalaryItems.length === 0 ? (
        <EmptyPlaceholder Icon={IoReaderOutline} text={t('salary.noDataAboutReferralPercentage')} />
      ) : (
        <Table
          columns={columns}
          data={referralPercentageSalaryItems}
          onRowClick={referralPercentage => {
            if (referralPercentage.transactionId)
              editTransactionDialog(referralPercentage.transactionId)
          }}
        />
      )}
      {transactionDialog}
    </>
  )
}
