import {
  LoginWithPopupResponse,
  LoginWithPopupType,
  PRODUCTION_TEST_USER_EMAILS,
  PRODUCTION_TEST_USER_PASSWORD,
} from '@expane/logic/auth'
import { generateAuthFormErrorMessage, validateEmail } from '@expane/logic/form'
import { ReportErrorFunc } from '@expane/logic/utils'
import { AppleButton, Button, GoogleButton, Input, Paper } from '@expane/ui'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoMailOutline } from 'react-icons/io5'
import { useSnackbar } from '../Snackbar'
import { AuthEmailSentWidget } from './AuthEmailSentWidget'
import { AuthOnLoginWithPopupWidget } from './AuthOnLoginWithPopupWidget'

interface AuthFormWidgetProps {
  pathname: string
  redirectedFrom?: string | undefined
  reportError: ReportErrorFunc
  safetyLoginWithPopup: (loginType: LoginWithPopupType) => Promise<LoginWithPopupResponse>
  sendAuthEmail: (email: string, url: string) => Promise<void>
  signInWithEmailAndPassword?: (email: string, password: string) => Promise<unknown>
}

export const AuthFormWidget: FC<AuthFormWidgetProps> = ({
  pathname,
  redirectedFrom,
  reportError,
  signInWithEmailAndPassword,
  sendAuthEmail,
  safetyLoginWithPopup,
}) => {
  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { formState, control, handleSubmit, setError } = useForm<{ email: string }>()
  const [isLinkSent, setIsLinkSent] = useState(false)
  const [isStartLoginWithPopup, setIsStartLoginWithPopup] = useState(false)

  const handleAuthButtonPress = handleSubmit(async ({ email }) => {
    // email on firebase and bd is always in lower case
    const normalizedEmail = email.toLocaleLowerCase()

    const linkParam = redirectedFrom ? '?from=' + redirectedFrom : '?from=' + pathname

    try {
      if (PRODUCTION_TEST_USER_EMAILS.includes(normalizedEmail)) {
        await signInWithEmailAndPassword?.(normalizedEmail, PRODUCTION_TEST_USER_PASSWORD)
        return
      }

      await sendAuthEmail(normalizedEmail, window.location.href + linkParam)
      setIsLinkSent(true)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e?.code === 'auth/invalid-email') {
        setError('email', { type: 'invalid-email' })
      } else
        reportError(e as Error, 'error', {
          normalizedEmail,
          url: window.location.href + linkParam,
        })
    }
  })

  const handleAuthWithPopup = async (loginType: LoginWithPopupType) => {
    setIsStartLoginWithPopup(true)
    const response = await safetyLoginWithPopup(loginType)
    if (response === LoginWithPopupResponse.failed) {
      openSnackbar(t('submitError'), 'error')
    }

    if (response === LoginWithPopupResponse.closedByUser)
      openSnackbar(t('authForm.popUpClosed'), 'warning')

    if (response === LoginWithPopupResponse.canceledByUser)
      openSnackbar(t('authForm.popUpCanceled'), 'warning')

    setIsStartLoginWithPopup(false)
  }

  const handleFormSwitch = () => {
    setIsLinkSent(false)
  }

  const disabled = formState.isSubmitting || isStartLoginWithPopup

  const authFormContent = (
    <Paper
      className="w-128 overflow-hidden p-6"
      onKeyDown={e => {
        if (e.key === 'Enter') return handleAuthButtonPress()
      }}
    >
      <h2 className="text-2xl text-primary-600 mb-7 text-center">{t('authForm.authorization')}</h2>
      <h3 className="mb-8 text-center text-main-color">{t('authForm.tiredOfPasswords')}</h3>

      <Controller
        name="email"
        control={control}
        rules={{
          required: true,
          validate: {
            validateEmail,
          },
        }}
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <Input
            type="email"
            placeholder={t('placeholders.email')}
            disabled={formState.isSubmitting}
            errorMessage={{
              isShown: Boolean(error),
              text: generateAuthFormErrorMessage(error?.type, t),
            }}
            autoComplete="on"
            {...field}
          />
        )}
      />

      <Button
        className={buttonStyle}
        onClick={handleAuthButtonPress}
        Icon={IoMailOutline}
        disabled={disabled}
        spinner={formState.isSubmitting}
      >
        {t('authForm.login')}
      </Button>

      <p className="my-2 text-center text-secondary-color">{t('or')}:</p>

      <div className="flex gap-2">
        <GoogleButton
          onClick={() => handleAuthWithPopup(LoginWithPopupType.google)}
          disabled={disabled}
        />

        <AppleButton
          onClick={() => handleAuthWithPopup(LoginWithPopupType.apple)}
          disabled={disabled}
        />
      </div>
    </Paper>
  )

  return isLinkSent ? (
    <AuthEmailSentWidget onClick={handleFormSwitch} />
  ) : isStartLoginWithPopup ? (
    <AuthOnLoginWithPopupWidget />
  ) : (
    authFormContent
  )
}

export const buttonStyle = 'w-full'
