import {
  MOVEMENT_TYPES,
  ServerMovementInSupplierMutualSettlementType,
  ServerSupplierMutualSettlementType,
  TRANSACTION_TYPES,
} from '@expane/data'
import { calcMovementTotalPrice, calcTotalMovementProductsPrice } from '../movement'
import { TFunction } from 'react-i18next'

export interface Period {
  start: Date
  end: Date
}

type SupplierExtraInfo = {
  startBalance: number
  arrival: number
  expense: number
  endBalance: number
}

export type SupplierWithInfo = ServerSupplierMutualSettlementType & {
  info: SupplierExtraInfo
}

export const addInfoForSuppliers = (
  suppliers: ServerSupplierMutualSettlementType[],
  period: Period,
): SupplierWithInfo[] => {
  return suppliers.map(supplier => ({
    ...supplier,
    info: getMutualSettlementsInfoFromSupplier(period, supplier),
  }))
}

export const getMutualSettlementsInfoFromSupplier = (
  period: Period | undefined,
  supplier: ServerSupplierMutualSettlementType | undefined,
): SupplierExtraInfo => {
  if (!period) return { startBalance: 0, arrival: 0, expense: 0, endBalance: 0 }

  const { movementsBeforePeriod, movementsInPeriod } = filterMovementsFromSupplier(period, supplier)

  const before = calcArrivalAndReturnedAmount(movementsBeforePeriod)
  const inPeriod = calcArrivalAndReturnedAmount(movementsInPeriod)

  // Сумма задолженности перед контрагентом, по этому с минусом
  const startBalance =
    -(before.arrival.total - before.arrival.paid) - (before.returned.total - before.returned.paid)

  // Приход - сумма товаров, которые мы получили от контрагента, с вычетом возвращенных товаров
  const arrival = inPeriod.arrival.total - inPeriod.returned.total

  // Расход - сумма за товары, которую мы оплатили, с вычетом возвратов от контрагента
  const expense = inPeriod.arrival.paid - inPeriod.returned.paid

  const endBalance = startBalance + expense - arrival

  return { startBalance, endBalance, arrival, expense }
}

const calcArrivalAndReturnedAmount = (
  movements: ServerMovementInSupplierMutualSettlementType[],
) => {
  const arrival = calcTotalAndPaidAmount(
    movements.filter(movement => movement.type === MOVEMENT_TYPES.arrival.id),
  )
  const returned = calcTotalAndPaidAmount(
    movements.filter(movement => movement.type === MOVEMENT_TYPES.return.id),
  )

  return { arrival, returned }
}

const calcTotalAndPaidAmount = (movements: ServerMovementInSupplierMutualSettlementType[]) => {
  const total = movements.reduce(
    (total, movement) =>
      total +
      calcMovementTotalPrice({
        amount: calcTotalMovementProductsPrice(movement.movementProducts),
        discount: movement.discount ? movement.discount : undefined,
        discountType: movement.discountType ? movement.discountType : undefined,
      }),
    0,
  )

  const paid = movements.reduce((paid, movement) => {
    const transactionsTotal = movement.transactions.reduce(
      (total, transaction) => total + transaction.amount,
      0,
    )

    return paid + transactionsTotal
  }, 0)

  return {
    total,
    paid,
  }
}

const filterMovementsFromSupplier = (
  period: Period | undefined,
  supplier: ServerSupplierMutualSettlementType | undefined,
): {
  movementsBeforePeriod: ServerMovementInSupplierMutualSettlementType[]
  movementsInPeriod: ServerMovementInSupplierMutualSettlementType[]
} => {
  if (!supplier || !period) return { movementsBeforePeriod: [], movementsInPeriod: [] }

  const movements = supplier.movements

  const movementsBeforePeriod = removeTransportCostTransactionsFromMovements(
    movements.filter(movement => movement.createdAt < period.start),
  )

  const movementsInPeriod = removeTransportCostTransactionsFromMovements(
    movements.filter(
      movement => movement.createdAt > period.start && movement.createdAt < period.end,
    ),
  )

  return { movementsBeforePeriod, movementsInPeriod }
}

const removeTransportCostTransactionsFromMovements = (
  movements: ServerMovementInSupplierMutualSettlementType[],
) =>
  movements.map(movement => ({
    ...movement,
    transactions: movement.transactions.filter(
      transaction => transaction.type !== TRANSACTION_TYPES.transportCosts.id,
    ),
  }))

export interface Document {
  id: number
  arrival: number | undefined
  expense: number | undefined
  date: Date
  type: 'movement' | 'transaction'
  number?: string
  name: string
  comment?: string
}

export const transformMovementsToMutualSettlementsList = (
  movements: ServerMovementInSupplierMutualSettlementType[],
  t: TFunction,
  period?: Period,
) => {
  const documents: Document[] = []
  movements.forEach(movement => {
    if (period && movement.createdAt > period.start && movement.createdAt < period.end) {
      const movementTotalPrice = calcMovementTotalPrice({
        amount: calcTotalMovementProductsPrice(movement.movementProducts),
        discount: movement.discount ? movement.discount : undefined,
        discountType: movement.discountType ? movement.discountType : undefined,
      })

      documents.push({
        id: movement.id,
        arrival:
          movement.type === MOVEMENT_TYPES.arrival.id ? movementTotalPrice : -movementTotalPrice,
        expense: undefined,
        date: movement.createdAt,
        type: 'movement',
        number: movement.number,
        name:
          movement.type === MOVEMENT_TYPES.arrival.id
            ? t(MOVEMENT_TYPES.arrival.name)
            : t(MOVEMENT_TYPES.return.name),
        comment: movement.description || undefined,
      })

      movement.transactions.forEach(transaction => {
        if (
          period &&
          transaction.date > period.start &&
          transaction.date < period.end &&
          transaction.type !== TRANSACTION_TYPES.transportCosts.id
        )
          documents.push({
            id: transaction.id,
            arrival: undefined,
            expense:
              transaction.type === TRANSACTION_TYPES.productsPurchase.id
                ? transaction.amount
                : -transaction.amount,
            date: transaction.date,
            type: 'transaction',
            name: t(
              transaction.type === TRANSACTION_TYPES.productsPurchase.id
                ? t(TRANSACTION_TYPES.productsPurchase.name)
                : t(TRANSACTION_TYPES.productsReturn.name),
            ),
            comment: transaction.comment || undefined,
          })
      })
    }
  })

  return documents.sort((a, b) => b.date.getTime() - a.date.getTime())
}
