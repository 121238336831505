import { utcToZonedTime } from '@expane/date'
import { ServerCardType, ServerGiftCardType } from '../../generated/graphql-types'

export type GiftCardType = ServerCardType & Required<Pick<ServerCardType, 'code'>>

export type ClientGiftCardType = ServerGiftCardType &
  Required<Pick<ServerGiftCardType, 'code'>> & { type: 2 }

export type CardToParse = {
  activatedAt?: string | Date | null
  canceledDate?: string | Date | null
  createdAt?: string | Date | null
}
export const parseDatesInCardGqlResponse = <T extends CardToParse>(card: T, timezone: string) => {
  if (card.activatedAt) {
    card.activatedAt = utcToZonedTime(new Date(card.activatedAt), timezone)
  }
  if (card.canceledDate) {
    card.canceledDate = utcToZonedTime(new Date(card.canceledDate), timezone)
  }
  if (card.createdAt) {
    card.createdAt = utcToZonedTime(new Date(card.createdAt), timezone)
  }

  return card
}
