import {
  ServerTagType,
  useArchiveTag,
  useCreateTag,
  useFetchTagById,
  useUpdateTag,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { CloseButton, Dialog, Input, Modal, Textarea, useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ColorPicker } from 'ui/ColorPicker'
import { ArchiveButton, RestoreButton, SaveButton } from 'widgets/Buttons'

export const TagsDialog: FC<DialogProps> = ({ id: tagId, closeDialog, isCreate }) => {
  const { data: tagById } = useFetchTagById(tagId)

  if (!isCreate && !tagById) return null

  return <TagDialogLogic tagById={tagById} closeDialog={closeDialog} isCreate={isCreate} />
}

const TagDialogLogic: FC<TagDialogLogicProps> = ({ tagById, closeDialog, isCreate }) => {
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()
  const { formState, control, handleSubmit } = useForm<TagsFormValues>({
    defaultValues: {
      name: tagById?.name ?? '',
      color: tagById?.color ?? 'yellow-300',
      description: tagById?.description ?? '',
    },
  })

  const { mutateAsync: createMutation } = useCreateTag()
  const { mutateAsync: updateMutation } = useUpdateTag()
  const { mutateAsync: archiveMutation, isLoading: isLoadingOnArchive } = useArchiveTag()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const mutateTag: SubmitHandler<TagsFormValues> = async ({ name, color, description }) => {
    if (isCreate) {
      const result = await createMutation({ name, color, description })

      if (result?.insertTag?.id) {
        openSnackbar(t('tag.createdSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else if (tagById) {
      const result = await updateMutation({
        id: tagById.id,
        tagSetInput: { name, color, description },
      })

      if (result?.updateTagById?.id) {
        openSnackbar(t('tag.updatedSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    }
    closeDialog()
  }

  const mutateArchiveTag = async (archived: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await archiveMutation({ id: tagById!.id, archived })

    if (result?.updateTagById?.id)
      openSnackbar(
        archived
          ? t('tag.archivingSuccessful', { name: tagById?.name })
          : t('tag.restorationSuccessful', { name: tagById?.name }),
        'success',
      )
    else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const { showArchiveConfirmationPopup, archiveConfirmationModal } =
    useShowArchiveConfirmationPopup(tagById?.name ?? '', () => mutateArchiveTag(true))

  const { warningModal, showWarningPopup } = useShowWarningPopup(t('warning'), t('tag.warning'))

  const handleOnArchive = () => {
    if (tagById?.clientTags?.length !== 0) showWarningPopup()
    else showArchiveConfirmationPopup()
  }

  const isEditingAllowed = myPermissions?.includes(permissions.tag.set)
  const archived = Boolean(tagById?.archived)

  const showSaveButton = isEditingAllowed && !tagById?.archived

  if (!isCreate && tagById === undefined) return null

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (showSaveButton && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateTag)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('tag.name')}</Dialog.Title>

          <Dialog.Body>
            <div className="flex w-148">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('title')}
                    placeholder={t('placeholders.tagName')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.name),
                      text: t('formError.required'),
                    }}
                    value={value}
                    onChange={onChange}
                    containerClassName={`w-1/3 mr-3`}
                    disabled={!isEditingAllowed || archived}
                    autoFocus
                  />
                )}
              />

              <Controller
                name="color"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ColorPicker
                    onChange={onChange}
                    value={value}
                    colors={TAG_COLOR_LIST}
                    label={t('color') + ':'}
                    disabled={!isEditingAllowed || archived}
                  />
                )}
              />
            </div>

            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={t('description')}
                  placeholder={t('placeholders.tagDescription')}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed || archived}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {showSaveButton && (
              <SaveButton
                onClick={handleSubmit(mutateTag)}
                disabled={formState.isSubmitting || !formState.isDirty}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />

            {isEditingAllowed &&
              !isCreate &&
              (tagById?.archived ? (
                <RestoreButton
                  className="mr-auto"
                  onClick={() => mutateArchiveTag(false)}
                  disabled={isLoadingOnArchive}
                  spinner={isLoadingOnArchive}
                />
              ) : (
                <ArchiveButton
                  className="mr-auto"
                  onClick={handleOnArchive}
                  disabled={isLoadingOnArchive}
                  spinner={isLoadingOnArchive}
                />
              ))}
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {warningModal}
      {archiveConfirmationModal}
      {confirmPopup}
    </>
  )
}

interface TagDialogLogicProps extends Omit<DialogProps, 'id'> {
  tagById: ServerTagType | undefined
}

type TagsFormValues = {
  name: string
  color: string
  description: string
}

export const TAG_COLOR_LIST = [
  'yellow-300',
  'yellow-600',
  'red-500',
  'pink-400',
  'indigo-400',
  'blue-900',
  'purple-500',
  'purple-700',
  'black',
]

// we just have this string in code and it guarantees these classes will be present on production
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COLOR_STYLES = `bg-yellow-300 border-yellow-300 ring-yellow-300 text-yellow-300
  bg-yellow-600 border-yellow-600 ring-yellow-600 text-yellow-600
  bg-red-500 border-red-500 ring-red-500 text-red-500
  bg-pink-400 border-pink-400 ring-pink-400 text-pink-400
  bg-indigo-400 border-indigo-400 ring-indigo-400 text-indigo-400
  bg-blue-900 border-blue-900 ring-blue-900 text-blue-900
  bg-purple-500 border-purple-500 ring-purple-500 text-purple-500
  bg-purple-700 border-purple-700 ring-purple-700 text-purple-700
  bg-black border-black ring-black text-black text-white bg-white border-white ring-white`
