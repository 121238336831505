import { useFetchSalarySettingsByEmployeeId } from '@expane/data'
import { createCurrentDate, getDate, isSameDay } from '@expane/date'
import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { RATE_OPTIONS } from '@expane/logic/salarySettings'
import { HorizontalButtonSwitch, Input, RadioGroupButtons } from '@expane/ui'
import { DateTimePicker } from '@expane/widgets'
import { translateData } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, Controller, UseFormClearErrors, useWatch } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { store } from 'store'
import { PREFERRED_PAYMENT_OPTIONS, SalarySettingFormValues } from '../logic'

interface CommonSettingProps {
  control: Control<SalarySettingFormValues>
  clearErrors: UseFormClearErrors<SalarySettingFormValues>
  disabled?: boolean
  employeeId?: number
  type?: 'single' | 'group'
  timezone: string
  areEmployeesFirstSalarySetting?: boolean | undefined
}

export const CommonSetting: FC<CommonSettingProps> = observer(
  ({
    control,
    disabled,
    clearErrors,
    employeeId,
    type = 'single',
    timezone,
    areEmployeesFirstSalarySetting,
  }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()

    const { data: salarySettings } = useFetchSalarySettingsByEmployeeId(
      employeeId,
      timezone,
      branchId,
    )

    const lastSalarySettings = salarySettings?.find(setting => setting.end === null)

    const lastSalarySettingType = lastSalarySettings?.rateType

    const isFirstSalarySetting = areEmployeesFirstSalarySetting

    const { getModuleSetting } = useBusinessModulesSettings()
    const showManagingEmployee = getModuleSetting('managingEmployee')

    const watchedEnd = useWatch({ control, name: 'end' })
    const watchedType = useWatch({ control, name: 'rateType' })

    const isMonthlyType = watchedType?.id === RATE_OPTIONS[0].id

    return (
      <div className="flex pb-4">
        <Controller
          control={control}
          name="start"
          rules={{
            validate: {
              // проверки startLessThanNow, ifMonthlyRate, ifLastRateWasMonthly происходят
              // тогда когда мы настраиваем только одному сотруднику, поэтому проверка type !== 'group'
              // когда группе настраиваем то мы можем поставить только с 1го числа - ifGroupSettings
              startLessThanNow: value =>
                (isFirstSalarySetting && type !== 'group') || value > createCurrentDate(timezone),
              ifMonthlyRate: value => {
                // если ставка за месяц, то можем поставить только с первого числа
                if (!isFirstSalarySetting && isMonthlyType && type !== 'group')
                  return getDate(value) === 1

                return true
              },
              ifLastRateWasMonthly: value => {
                // Если предыдущая ЗП была месячная, то можем поставить только с первого числа
                if (lastSalarySettingType === RATE_OPTIONS[0].id) return getDate(value) === 1

                return true
              },
              // Если настраиваем группе, если ранее зп не была настроена, то можно назначить любой датой
              // иначе можем поставить только с первого числа
              ifGroupSettings: value =>
                type === 'group' && !areEmployeesFirstSalarySetting ? getDate(value) === 1 : true,
              ifStartDateAlreadyInUse: value => {
                const isStartDateAlreadyInUse = salarySettings?.some(setting =>
                  isSameDay(value, setting.start),
                )

                return !isStartDateAlreadyInUse
              },
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DateTimePicker
              timezone={timezone}
              className="w-40 mr-2"
              label={t('startDate')}
              hint={t('salarySetting.startHint')}
              value={value}
              type="date"
              onChange={onChange}
              disabled={disabled}
              errorMessage={{
                isShown: Boolean(error),
                text: getError(error?.type, t),
              }}
            />
          )}
        />
        {watchedEnd && (
          <Controller
            control={control}
            name="end"
            render={({ field: { value, onChange } }) => (
              <DateTimePicker
                timezone={timezone}
                className="w-44 mr-2"
                label={t('endDate')}
                value={value}
                type="date"
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        )}

        <div className={'w-44 pr-2'}>
          <Controller
            name="rateType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroupButtons
                label={t('salary.rateType')}
                options={translateData(RATE_OPTIONS, t)}
                value={value}
                onChange={item => {
                  clearErrors('start')
                  onChange(item)
                }}
                disabled={disabled}
              />
            )}
          />
        </div>

        <Controller
          name="rateValue"
          control={control}
          rules={{ validate: checkOnlyPositiveAmount }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type="number"
              label={t('amount')}
              placeholder={PLACEHOLDERS.servicePrice}
              value={value}
              onChange={onChange}
              containerClassName="w-44 pr-2"
              autoFocus
              errorMessage={{
                isShown: Boolean(error),
                text: t('formError.invalidValue'),
              }}
              disabled={disabled}
            />
          )}
        />

        {showManagingEmployee && (
          <Controller
            name="referralPercentage"
            control={control}
            rules={{ validate: checkOnlyPositiveAmount }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label={t('salary.referralPercentage')}
                hint={t('salary.referralPercentageHint')}
                type="number"
                placeholder={PLACEHOLDERS.servicePrice}
                value={value}
                onChange={onChange}
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.invalidValue'),
                }}
                disabled={disabled}
              />
            )}
          />
        )}

        <Controller
          control={control}
          name="preferredPayment"
          render={({ field: { onChange, value } }) => (
            <HorizontalButtonSwitch
              label={t('salary.preferredPayment')}
              value={value}
              containerClassName="ml-auto"
              onChange={onChange}
              options={translateData(PREFERRED_PAYMENT_OPTIONS, t)}
              disabled={disabled}
            />
          )}
        />
      </div>
    )
  },
)

const getError = (errorType: string | undefined, t: TFunction): string => {
  if (errorType === 'startLessThanNow') return t('salarySetting.startLessThanNow')
  if (errorType === 'ifMonthlyRate') return t('salarySetting.startMonthlyError')
  if (errorType === 'ifLastRateWasMonthly') return t('salarySetting.ifLastRateWasMonthly')
  if (errorType === 'ifGroupSettings') return t('salarySetting.ifGroupSettings')
  if (errorType === 'ifStartDateAlreadyInUse') return t('salarySetting.ifStartDateAlreadyInUse')

  return ''
}
