/*
  Types for popup login. Only for WEB
 */
export enum LoginWithPopupType {
  google,
  apple,
}

/*
  Responses from popup login. Only for WEB
 */
export enum LoginWithPopupResponse {
  success,
  failed,
  closedByUser,
  canceledByUser,
}

export const PRODUCTION_TEST_USER_EMAILS = ['tustt988@gmail.com', 'support@ibusiness.com']
export const PRODUCTION_TEST_USER_PASSWORD = 'RaWHtNKHRFufK24'

export type AllowedRole = 'employee' | 'super' | 'client' | 'new_owner' | 'referrer'

export interface XHasuraClaims {
  'x-hasura-default-role'?: AllowedRole
  'x-hasura-business-id'?: string
  'x-hasura-employee-id'?: string
  'x-hasura-client-id'?: string
  'x-hasura-user-email': string
}

export type AuthMode = 'employee' | 'new' | 'client' | 'referrer'

const mapClaimsRoleToAuthMode: Record<AllowedRole, AuthMode> = {
  super: 'employee',
  employee: 'employee',
  new_owner: 'new',
  client: 'client',
  referrer: 'referrer',
}

export const getAuthMode = (xHasuraClaims: XHasuraClaims | null): AuthMode | null => {
  if (!xHasuraClaims || !xHasuraClaims['x-hasura-default-role']) return null

  return mapClaimsRoleToAuthMode[xHasuraClaims['x-hasura-default-role']] || null
}
