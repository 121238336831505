import { BranchWithSchedule, ScheduleDto, useUpdateBranchById } from '@expane/data'
import { currencyItems, getCurrencyCodeById, getCurrencyIdByCode } from '@expane/logic/currency'
import { permissions } from '@expane/logic/permission'
import { filterOnlyWeeklySchedules } from '@expane/logic/schedule'
import { getTimezoneById, getTimezoneByName } from '@expane/logic/timezone'
import { Checkbox, Input, SelectDropdown, Textarea } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { FC, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ApplyButton } from 'widgets/Buttons'
import { ScheduleInfoWidget } from 'widgets/ScheduleInfoWidget'
import { TimezonesSelect } from 'widgets/TimezonesSelect'
import { DEFAULT_TIMEZONE } from '@expane/date'
import { BranchFormData, transformSchedulesForSelect } from './logic'
import { BranchAddress } from './BranchAddress'
import { checkValidPhone } from '@expane/logic/phone'
import { PhoneInput } from 'ui/PhoneInput'
import { showPhoneErrorMessage } from 'logic/form'
import { PLACEHOLDERS } from '@expane/logic/form'

interface BranchBlockProps {
  branch: BranchWithSchedule
  schedules: ScheduleDto[]
  myPermissions: string[]
  className?: string
}

export const BranchBlock: FC<BranchBlockProps> = ({
  className,
  branch,
  schedules,
  myPermissions,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: updateBranchById } = useUpdateBranchById()

  const { formState, control, handleSubmit, setValue } = useForm<BranchFormData>({
    defaultValues: {
      name: branch.name,
      scheduleId: branch.schedule?.id,
      description: branch.description ?? undefined,
      timezone: getTimezoneByName(branch.timezone)?.id,
      displayCoins: branch.displayCoins,
      currencyId: getCurrencyIdByCode(branch.currency),
      phone: branch.phone ?? '',
      address: branch.address ?? '',
      geocode: branch.geocode || null,
    },
  })

  const [openSnackBar] = useSnackbar()

  const handleUpdatingBranch = async (data: BranchFormData) => {
    const {
      name,
      description,
      scheduleId,
      timezone,
      displayCoins,
      currencyId,
      address,
      phone,
      geocode,
    } = data

    const result = await updateBranchById({
      id: branch.id,
      branchSetInput: {
        name,
        description,
        scheduleId,
        timezone: getTimezoneById(timezone)?.name ?? DEFAULT_TIMEZONE,
        displayCoins,
        currency: getCurrencyCodeById(currencyId),
        address,
        phone,
        geocode,
      },
    })

    if (result.updateBranchById?.id) openSnackBar(t('branch.updateSuccess'), 'success', 3000)
    else openSnackBar(t('submitError'), 'error', 3000)
  }

  const watchedScheduleId = useWatch({ control, name: 'scheduleId' })
  const schedule = useMemo(
    () => schedules.find(schedule => schedule.id === watchedScheduleId),
    [watchedScheduleId, schedules],
  )

  const isEditingAllowed = myPermissions.includes(permissions.branch.set)

  return (
    <div className={className}>
      <div className="flex gap-2">
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              containerClassName="w-1/2 "
              label={t('title')}
              placeholder={t('placeholders.businessName')}
              required
              hint={t('branchesHint')}
              errorMessage={{
                isShown: Boolean(formState.errors.name),
                text: t('formError.required'),
              }}
              disabled={!isEditingAllowed}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="currencyId"
          render={({ field: { value, onChange } }) => (
            <SelectDropdown
              label={t('currency')}
              value={value}
              onSelectChange={onChange}
              items={currencyItems}
              className="w-1/2"
            />
          )}
        />
      </div>

      <Controller
        name="displayCoins"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            label={t('displayCoins')}
            checked={value}
            onChange={onChange}
            containerClassName="mb-4"
          />
        )}
      />

      <div className="flex items-center gap-2">
        <Controller
          control={control}
          name="scheduleId"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <SelectDropdown
              label={t('schedule.name')}
              value={value}
              items={transformSchedulesForSelect(filterOnlyWeeklySchedules(schedules))}
              placeholder={t('placeholders.defaultSelect')}
              required
              noDataMessage={t('noSchedule')}
              className="w-1/2"
              onSelectChange={onChange}
              errorMessage={{
                isShown: Boolean(formState.errors.scheduleId),
                text: t('formError.required'),
              }}
              disabled={!isEditingAllowed}
            />
          )}
        />

        <Controller
          control={control}
          name="timezone"
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => (
            <TimezonesSelect
              value={value}
              onSelectChange={onChange}
              className="mr-2 grow"
              containerClassName="w-1/2"
              errorMessage={{
                isShown: Boolean(formState.errors.timezone),
                text: t('formError.required'),
                reserveIndent: true,
              }}
              required
            />
          )}
        />
      </div>

      <ScheduleInfoWidget schedule={schedule} />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Textarea
            label={t('description')}
            placeholder={t('placeholders.branchDescription')}
            disabled={!isEditingAllowed}
            {...field}
          />
        )}
      />

      <Controller
        name="phone"
        control={control}
        rules={{
          validate: value => {
            if (value) return checkValidPhone(value)

            return true
          },
        }}
        render={({ field: { onChange, value }, formState }) => (
          <PhoneInput
            label={t('branchPhone')}
            placeholder={PLACEHOLDERS.phone}
            errorMessage={{
              isShown: Boolean(formState.errors.phone),
              text: showPhoneErrorMessage(formState.errors.phone?.type ?? '', t),
            }}
            value={value ?? ''}
            disabled={!isEditingAllowed}
            onChange={onChange}
            containerClassName="w-1/2 mt-4"
          />
        )}
      />

      <BranchAddress control={control} disabled={!isEditingAllowed} setValue={setValue} />

      <ApplyButton
        className="mt-4 self-start"
        disabled={!formState.isDirty || !isEditingAllowed}
        onClick={handleSubmit((data: BranchFormData) => handleUpdatingBranch(data))}
      />
    </div>
  )
}
