import { ServerAccountType } from '@expane/data'
import { getPercentOfAmount } from '../utils'
import { TFunction } from 'i18next'

export const checkOnlyPositiveAmount = (value: number | string | undefined) => {
  try {
    return Number(value) >= 0
  } catch {
    return false
  }
}

export const checkWithdrawPossibility = ({
  currentAccount,
  value,
}: {
  currentAccount: ServerAccountType | undefined
  value: number
}) => {
  if (!currentAccount) return true

  const credit = currentAccount.maximumDebt ?? 0
  const commissionAmount = currentAccount.commission
    ? getPercentOfAmount(value, currentAccount.commission)
    : 0

  // TODO: когда разберемся с комиссией счетов поправить, так как не везде она может быть
  return value + commissionAmount <= (currentAccount.balance ?? 0) + credit
}

export const checkArrayNotEmpty = <T>(value: Array<T>) => (!value ? false : value?.length !== 0)

/**
 * @description **Validates an email address based on specific criteria.**
 *
 * @description The function checks both the local part and the domain part of an email address.
 * The local part (before the '@') must not start or end with a dot (.), underscore (_),
 * percent (%), plus (+), or hyphen (-). It should not contain consecutive dots, underscores,
 * percents, pluses, or hyphens. It must be 2 to 64 characters long, consisting of Latin letters,
 * numbers, or allowed symbols.
 *
 * @description The domain part (after the '@') must not start or end with a hyphen (-) and must be
 * 1 to 63 characters long, consisting of Latin letters, numbers, or hyphens. It should match
 * a top-level domain, consisting of Latin letters, and should be 2 to 6 characters long.
 * This part can repeat from 1 to 2 times, allowing for domains like '.com' or '.co.uk'.
 *
 * @param {string} value - The email address to be validated.
 * @returns {boolean} **true** if the email address is valid according to the defined criteria, **false** otherwise.
 */
export const validateEmail = (value: string): boolean => {
  const localPartRegex = /^(?![._%+-])(?!.*[._%+-]{2})[a-zA-Z0-9._%+-]{2,64}(?<![._%+-])$/
  // '^'  Indicates the start of the line
  // '(?![._%+-])' The first character should not be a dot (.), underscore (_), percent (%), plus (+), or hyphen (-)
  // '(?!.*[._%+-]{2})' The string should not contain consecutive dots (.), underscores (_), percents (%), pluses (+), or hyphens (-)
  // '[a-zA-Z0-9._%+-]{2,64}' Matches any character that is a Latin letter, number, dot (.), underscore (_), percent (%), plus (+), or hyphen (-)
  // '(?<![._%+-])' The last character should not be a dot (.), underscore (_), percent (%), plus (+), or hyphen (-)
  // '$' Indicates the end of the line

  const domainPartRegex = /^(?!-)([A-Za-z0-9-]{1,63})(?<!-)(\.([A-Za-z]{2,6})){1,2}$/
  // '^' Indicates the start of the line
  // '(?!-)' The first character should not be a hyphen (-)
  // '([A-Za-z0-9-]{1,63})' Matches any character that is a Latin letter, number, or hyphen (-)
  // '(?<!-)' The last character should not be a hyphen (-)
  // '(\\.([A-Za-z]{2,6})){1,2}' Matches a top-level domain, consisting of Latin letters, and should be 2 to 6 characters long.
  // {1,2} This part can repeat from 1 to 2 times, allowing for one or two domains (e.g., .com or .co.uk)
  // '$' Indicates the end of the line

  const parted = value.split('@')

  // Reject those options where there is no "@" or several of them
  if (parted.length !== 2) return false

  const isLocalPartCorrect = localPartRegex.test(parted[0])
  const isDomainPartCorrect = domainPartRegex.test(parted[1])

  return isLocalPartCorrect && isDomainPartCorrect
}

// использовать для проверки полей дней, минут
// TODO: улучшить под конкретную категорию?
export const validateValueIsIntegerNumber = (value: string) => {
  const number = Number(value)

  return isFinite(number) && Math.floor(number) === number && number > 0
}

export const validateAllowedLettersNumbersSymbols = (value: string) => {
  // Регулярное выражение, которое включает латинские и кириллические буквы, цифры,
  // стандартные и типографские двойные кавычки, пробелы, подчеркивание, дефисы
  // и стандартный и типографский апострофы
  const validPattern = /^[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ0-9"“”\s_\-‘’\\']*$/

  return validPattern.test(value)
}

// использовать для поля суммы
export const validateAmount = (value: string, allowNought?: boolean) => {
  // - Разрешает пустое поле или 0
  if (!Boolean(value) || allowNought) {
    return true
  }

  if (value === '0') {
    return false
  }

  // Регулярное выражение, которое:
  // - Не допускает начала с нуля, если за ним следуют другие цифры
  // - Допускает число, начинающееся с нуля, только если за ним следует точка или запятая
  // - Ограничивает количество знаков после точки или запятой до двух
  // - Исключает пробелы внутри числа
  const validPattern = /^(0|([1-9]\d*))([\.,]\d{1,2})?$/

  return validPattern.test(value)
}

export const validateName = (value: string) => {
  // - Разрешает пустое поле
  if (!Boolean(value)) {
    return true
  }

  // Регулярное выражение, которое включает латинские и кириллические буквы,
  // пробелы, дефисы и стандартный и апострофы
  const validPattern = /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ'‘’\u02BC\u2018\u2019\u02B9 -]+$/

  return validPattern.test(value)
}

export const validateMaxNumberLength = (value: string) => value.length <= MAX_NUMBER_FIELD_LENGTH
export const validateMaxNameLength = (value: string) => value.length <= MAX_NAME_FIELD_LENGTH
export const validateMinNameLength = (value: string) => value.length >= MIN_NAME_FIELD_LENGTH

// recurring bookings
export const validateMaxDayInterval = (value: number) => value <= MAX_DAY_INTERVAL
const MAX_DAY_INTERVAL = 50

export const validateMaxWeekInterval = (value: number) => value <= MAX_WEEK_INTERVAL
const MAX_WEEK_INTERVAL = 10

export const validateMaxMonthInterval = (value: number) => value <= MAX_MONTH_INTERVAL
const MAX_MONTH_INTERVAL = 12

const MAX_NUMBER_FIELD_LENGTH = 10
export const MIN_NAME_FIELD_LENGTH = 2
export const MAX_NAME_FIELD_LENGTH = 255

/**
 * Регулярний вираз для перевірки допустимих символів в path в налаштуваннях лендінгу
 */
export const validatePath = (value: string) => {
  const validPattern = /^[a-zA-Z0-9._+-]+$/

  return validPattern.test(value)
}

export const generateAuthFormErrorMessage = (
  type: string | undefined,
  t: TFunction,
): string | undefined => {
  if (type === 'required') return t('formError.required')
  if (type === 'validateEmail') return t('authForm.wrongEmail')
}

export const PLACEHOLDERS = {
  bot: 'ExpaneBot',
  accountCommission: '1.7',
  accountMaximumDebt: '1000',
  giftCardCode: 'XXXX-XXXX-XXXX-XXXX',
  maxDiscount: '10',
  phone: '(99) 123-45-67',
  servicePrice: '100',
  subscriptionDurations: '30',
  token: '0xxx00xxx0x0x0x00000x0x0xx00xx00x00xxxxx',
}
