import { ReportErrorFunc } from '@expane/logic/utils'
import { FC } from 'react'
import { useColorTheme } from '@expane/logic/theme'
import { ThemePicker } from '@expane/ui'
import { useTranslation } from 'react-i18next'

interface ThemePickerWidgetProps {
  reportError: ReportErrorFunc
  className?: string
}

export const ThemePickerWidget: FC<ThemePickerWidgetProps> = ({ reportError, className = '' }) => {
  const { t } = useTranslation()
  const { themeColor, updateTheme } = useColorTheme(reportError)

  return (
    <ThemePicker
      className={className}
      value={themeColor}
      onChange={updateTheme}
      label={t('choosingColorTheme')}
    />
  )
}
