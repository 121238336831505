import { useWebPersistedState } from '@expane/logic/useWebPersistedState'
import { Button, Paper } from '@expane/ui'
import { CREATE_BUSINESS_KEY, stepStyle } from 'pages/CreateBusinessPage/logic'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateBusinessType } from 'routes/logic'
import { queryClient } from 'services/api'
import { history } from 'services/navigation'
import { reportError } from 'services/sentry'

export const CompletedStep: FC = () => {
  const { t } = useTranslation()
  const { clearPersistedStorage } = useWebPersistedState<CreateBusinessType>(
    CREATE_BUSINESS_KEY,
    reportError,
  )

  const addAdditionalData = async () => {
    clearPersistedStorage()
    queryClient.invalidateQueries()
    history.push({ pathname: '/bookings' })
  }

  return (
    <Paper className={stepStyle + ' w-128'}>
      <p className="text-2xl mb-4 text-primary-600 text-center">
        {t('createBusiness.congratulations')}
      </p>
      <p className="text-xl text-center text-main-color">
        {t('createBusiness.successfulCreation')}
      </p>
      <Button className="w-full mt-14" onClick={addAdditionalData}>
        {t('createBusiness.goToMain')}
      </Button>
    </Paper>
  )
}
