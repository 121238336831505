import i18next from 'i18next'
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  isSameDay,
  set,
  startOfWeek,
  zonedTimeToUtc,
} from '@expane/date'

export type RecurringType = {
  interval: number | null
  intervalType: number | null
  intervalWeekDays: RecurringWeekDay[] | null
  endType: number
  endDate: Date | null
  endAfterQtyRepeat: number | null
}
export type RecurringWeekDay = {
  id: number
  shortDay: string
  date: Date
}

export const INTERVAL_TYPE = {
  day: 1,
  week: 2,
  month: 3,
}

export const END_TYPE = {
  date: 1,
  after: 2,
}

export const endTypes: { id: number; name: string }[] = [
  { id: END_TYPE.date, name: 'recurringBookings.endTypes.date' },
  { id: END_TYPE.after, name: 'recurringBookings.endTypes.after' },
]

export const intervalTypes: { id: number; name: string }[] = [
  { id: INTERVAL_TYPE.day, name: 'recurringBookings.intervalTypes.day' },
  { id: INTERVAL_TYPE.week, name: 'recurringBookings.intervalTypes.week' },
  { id: INTERVAL_TYPE.month, name: 'recurringBookings.intervalTypes.month' },
]

// Функция для получения коротких названий дней недели
export const getShortWeekdays = (date: Date) => {
  const locale = i18next.language

  const mondayOfTheWeek = startOfWeek(date, { weekStartsOn: 1 })
  const shortWeekdays: RecurringWeekDay[] = []

  for (let i = 0; i < 7; i++) {
    const day = addDays(mondayOfTheWeek, i) // Прибавляем дни к понедельнику для получения всей недели
    const shortDay = day.toLocaleDateString(locale, { weekday: 'short' })
    // id это порядковый номер в неделе как в date fns начинается с 0
    shortWeekdays.push({ id: i, shortDay, date: day })
  }

  return shortWeekdays
}
export const getRecurringBookingsDates = (
  recurring: RecurringType,
  startDate: Date,
  timezone: string | undefined,
): Date[] => {
  const dates: Date[] = []

  if (!timezone) return dates

  const repeats =
    (recurring.interval &&
      recurring.intervalType &&
      getRepeatsOfRecurringBookings({
        startDate,
        interval: recurring.interval,
        intervalType: recurring.intervalType,
        endAfterQtyRepeat: recurring.endAfterQtyRepeat,
        endDate: recurring.endDate,
      })) ??
    0

  const interval = recurring.interval ?? 1

  for (let i = 0; i < repeats; i++) {
    if (recurring.intervalType === INTERVAL_TYPE.day) {
      const date = addDays(startDate, i * interval)

      // записи создаются до даты окончания
      if (recurring.endDate && isSameDay(date, recurring.endDate)) return dates

      dates.push(zonedTimeToUtc(addDays(startDate, i * interval), timezone))
    } else if (recurring.intervalType === INTERVAL_TYPE.week) {
      if (recurring.intervalWeekDays)
        recurring.intervalWeekDays.map(day => {
          const dayDateWithBookingTime = set(day.date, {
            hours: startDate.getHours(),
            minutes: startDate.getMinutes(),
          })

          const date = addWeeks(dayDateWithBookingTime, i * interval)

          // первая дата, которая раньше первого букинга не должна учитываться
          if (date < startDate) return dates

          // записи создаются до даты окончания
          if (recurring.endDate && isSameDay(date, recurring.endDate)) return dates

          // ппоследняя дата, которая после даты окончания не должна учитываться
          if (recurring.endDate && date > recurring.endDate) return dates

          dates.push(zonedTimeToUtc(date, timezone))
        })
    } else if (recurring.intervalType === INTERVAL_TYPE.month) {
      const date = addMonths(startDate, i * interval)

      // записи создаются до даты окончания
      if (recurring.endDate && isSameDay(date, recurring.endDate)) return dates

      dates.push(zonedTimeToUtc(date, timezone))
    }
  }

  return dates
}

const getRepeatsOfRecurringBookings = ({
  startDate,
  interval,
  intervalType,
  endAfterQtyRepeat,
  endDate,
}: {
  startDate: Date
  interval: number
  intervalType: number
  endAfterQtyRepeat: number | null
  endDate: Date | null
}): number => {
  if (endAfterQtyRepeat) return endAfterQtyRepeat
  else if (endDate) {
    let repeats = 0
    let currentDate = startDate

    while (currentDate <= endDate) {
      repeats++

      // Вычисляем следующую дату повторения
      let nextDate: Date

      switch (intervalType) {
        case INTERVAL_TYPE.day:
          nextDate = addDays(currentDate, interval)
          break
        case INTERVAL_TYPE.week:
          nextDate = addWeeks(currentDate, interval)
          break
        case INTERVAL_TYPE.month:
          nextDate = addMonths(currentDate, interval)
          break
        default:
          throw new Error('Not valid invalid interval type')
      }

      // Проверяем, выходит ли следующая дата за пределы endDate
      if (nextDate > endDate) {
        break // Если следующая дата выходит за пределы, завершаем цикл
      }

      currentDate = nextDate // Обновляем текущую дату для следующего повторения
    }

    return repeats
  }

  return 1 // Возвращаем 1, если нет конечной даты и количества повторений
}

export const getAreRecurringBookingsWithInvalidDate = ({
  dates,
  startDate,
  timezone,
}: {
  dates: Date[]
  startDate: Date
  timezone: string | undefined
}) => {
  if (!timezone) return true

  // так как dates приходят уже конвертированные к UTC необходимо startDate тоже конвертировать
  const startDateToUtc = zonedTimeToUtc(startDate, timezone)

  return dates.some(date => date >= addYears(startDateToUtc, 1))
}
