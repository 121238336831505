import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, useQuery } from '../../api'
import { ServerClientNoteBriefType, ServerClientNoteType } from '../../generated/graphql-types'
import { compareDesc, DEFAULT_TIMEZONE, utcToZonedTime } from '@expane/date'
import { CLIENT_NOTES_BRIEFS_QUERY_KEY, CLIENT_NOTES_QUERY_KEY } from './queryKeys'
import { clientNoteBriefFragment, clientNoteFragment } from './clientNote.fragments'

export function useFetchClientNotesBrief(clientId: number, timezone: string | undefined) {
  return useQuery<ServerClientNoteBriefType[] | undefined>(
    [CLIENT_NOTES_QUERY_KEY, CLIENT_NOTES_BRIEFS_QUERY_KEY, { clientId }],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${clientNoteBriefFragment}
            query ($clientId: Int!) {
              clientNotes(
                order_by: { createdAt: desc }
                where: { clientId: { _eq: $clientId }, archived: { _is_null: true } }
              ) {
                ...clientNoteBriefType
              }
            }
          `,
          { clientId },
        )
      } catch (error) {
        reportGqlError(error)
      }

      if (result?.clientNotes) {
        return result.clientNotes
          .map(clientNote => ({
            ...clientNote,
            createdAt: utcToZonedTime(clientNote.createdAt, timezone ?? DEFAULT_TIMEZONE),
          }))
          .sort((a, b) => compareDesc(a.date, b.date))
      }

      reportError(new Error('Error while trying to fetch clientNotes'), 'error', {
        clientId,
        result,
      })
    },
    {
      queryName: 'useFetchClientNotes',
      onError: reportGqlError,
      enabled: Boolean(timezone),
    },
  )
}

export function useFetchClientNotes(clientId: number, timezone: string | undefined) {
  return useQuery(
    [CLIENT_NOTES_QUERY_KEY, { clientId }],
    async (): Promise<ServerClientNoteType[]> => {
      const result = await request(
        gql`
          ${clientNoteFragment}
          query ($clientId: Int!) {
            clientNotes(where: { clientId: { _eq: $clientId }, archived: { _is_null: true } }) {
              ...clientNoteType
            }
          }
        `,
        { clientId },
      )

      if (Array.isArray(result?.clientNotes)) {
        return result.clientNotes.map(item => ({
          ...item,
          createdAt: utcToZonedTime(item.createdAt, timezone ?? DEFAULT_TIMEZONE),
        }))
      } else {
        reportError(new Error('clientNotes is not an array'), 'warning', { clientId, result })
        return []
      }
    },
    {
      queryName: 'useFetchClientNotes',
      onError: reportGqlError,
      enabled: Boolean(clientId) && Boolean(timezone),
    },
  )
}
