import {
  ServerCardTemplateBriefType,
  ServerProductType,
  ServerServiceBriefWithProductType,
  ServerSupplierBriefType,
} from '@expane/data'
import { safeWebLocalStorage } from '@expane/logic/safeWebLocalStorage'
import { transformPersonName } from '@expane/logic/utils'
import i18next from 'i18next'
import { formatPhoneNumber } from 'logic/ui'
import { RouteType } from 'routes/logic'

export type QuickFindMenuItemTypes =
  | 'page'
  | 'employee'
  | 'client'
  | 'supplier'
  | 'service'
  | 'subscription'
  | 'giftCard'
  | 'product'

export type QuickFindFoundIn = 'label' | 'additionalInfo'

export interface QuickFindMenuItem {
  gid: string // generated unique id, used to store recent searches
  type: QuickFindMenuItemTypes
  label: string
  path?: string
  id?: number
  additionalInfo?: string
  indexes?: { start: number; end: number }
  foundIn?: QuickFindFoundIn
}

export const MAX_ITEMS_COUNT = 6 as const

const filterBySearchValue = (
  array: QuickFindMenuItem[],
  searchValue: string,
): QuickFindMenuItem[] => {
  if (searchValue === '') return array
  const searchValueWithoutSymbols = searchValue.replaceAll(/ |-|\(|\)/g, '')

  return array
    .map(item => {
      let foundIn: QuickFindFoundIn = 'label'
      let indexes = findIndexes(item.label, searchValueWithoutSymbols)

      if (indexes.start === -1) {
        if (item.additionalInfo) {
          indexes = findIndexes(item.additionalInfo, searchValueWithoutSymbols)
          foundIn = 'additionalInfo'
        }
      }

      return { ...item, foundIn, indexes }
    })
    .filter(({ indexes }) => indexes.start !== -1)
}

const generateDialogGID = (type: QuickFindMenuItemTypes, id: number): string => `${type}-${id}`
const generatePageGID = (path: string): string => `page-${path}`

export const transformPersonsForQuickInput = (
  data: { firstName: string; lastName: string | null; id: number; phone: string | null }[],
  type: QuickFindMenuItemTypes,
): QuickFindMenuItem[] =>
  data.map(({ firstName, lastName, id, phone }) => ({
    type,
    label: transformPersonName({ firstName, lastName }),
    id,
    additionalInfo: formatPhoneNumber(phone ?? ''),
    gid: generateDialogGID(type, id),
  }))

export const transformSuppliersForQuickInput = (
  data: ServerSupplierBriefType[],
): QuickFindMenuItem[] =>
  data.map(supplier => ({
    type: 'supplier',
    label: supplier.name,
    id: supplier.id,
    additionalInfo: formatPhoneNumber(supplier.phone ?? ''),
    gid: generateDialogGID('supplier', supplier.id),
  }))

export const transformServicesForQuickInput = (
  data: ServerServiceBriefWithProductType[],
): QuickFindMenuItem[] =>
  data.map(service => ({
    type: 'service',
    label: service.name,
    id: service.id,
    gid: generateDialogGID('service', service.id),
  }))

export const transformCardsForQuickInput = (
  data: ServerCardTemplateBriefType[],
  type: 'subscription' | 'giftCard',
): QuickFindMenuItem[] =>
  data.map(card => ({
    gid: generateDialogGID(type, card.id),
    type: type,
    label: card.name,
    id: card.id,
  }))

export const transformProductsForQuickInput = (data: ServerProductType[]): QuickFindMenuItem[] =>
  data.map(product => ({
    gid: generateDialogGID('product', product.id),
    type: 'product',
    label: product.name,
    id: product.id,
    additionalInfo: product.vendorCode ?? undefined,
  }))

export const transformRoutesForQuickInput = (data: RouteType[]): QuickFindMenuItem[] => {
  const result: QuickFindMenuItem[] = []

  for (const el of data) {
    // if it is layout route - just return its children
    // see definition of RouteType
    if (!el.path) {
      if (el.children) {
        result.push(...transformRoutesForQuickInput(el.children))
      }
      continue
    }
    if (el.parentRoute) {
      result.push({
        gid: generatePageGID(el.path),
        type: 'page',
        label: i18next.t(el.parentRoute.name) + ' > ' + i18next.t(el.name),
        path: el.path,
      })
    } else {
      result.push({
        gid: generatePageGID(el.path),
        type: 'page',
        label: i18next.t(el.name),
        path: el.path,
      })
    }
  }

  return result
}

const findIndexes = (searchString: string, searchValue: string): { start: number; end: number } => {
  let start = -1
  let end = -1
  if (!searchValue || !searchString) return { start, end }

  let searchedLetter = 0

  for (let i = 0; i < searchString.length; i += 1) {
    // break if searchValue has ended
    if (searchedLetter === searchValue.length) break

    // increment end index if found characters which need to skip
    if (
      charactersToSkip.includes(searchString[i]) &&
      !charactersToSkip.includes(searchValue[searchedLetter])
    ) {
      end += 1
      continue
    }

    // compare characters
    if (searchString[i].toLocaleLowerCase() === searchValue[searchedLetter].toLocaleLowerCase()) {
      if (searchedLetter === 0) {
        start = i
        end = i + 1
        searchedLetter += 1
      } else if (end === i) {
        end += 1
        searchedLetter += 1
      }
    } else {
      // reset if characters not equal
      start = -1
      end = -1
      searchedLetter = 0
    }
  }

  if (start !== -1) {
    // для удачного поиска длинна найденной и искомой строки - должны совпадать
    const foundString = searchString.slice(start, end).replaceAll(/ |-|\(|\)/g, '')
    if (foundString.length !== searchValue.length) start = -1
  }

  return { start, end }
}

const charactersToSkip: string[] = [' ', '-', '(', ')']

const RESENT_SEARCHES_KEY = 'resentSearches/v1'

type ResentSearch = string
const getResentSearches = (): ResentSearch[] => {
  try {
    const resentSearches = safeWebLocalStorage.getItem(RESENT_SEARCHES_KEY)
    if (!resentSearches) return []

    const parsedResentSearches = JSON.parse(resentSearches)
    if (
      !Array.isArray(parsedResentSearches) ||
      parsedResentSearches.some(item => typeof item !== 'string')
    ) {
      safeWebLocalStorage.removeItem(RESENT_SEARCHES_KEY)
      return []
    }

    return parsedResentSearches
  } catch (error) {
    return []
  }
}

export const storeResentSearch = (item: QuickFindMenuItem) => {
  const searchToSave = item.gid
  const resentSearches = getResentSearches()

  // we don't need to save the same search twice
  if (resentSearches.some(search => search === searchToSave)) return

  if (resentSearches.length >= MAX_ITEMS_COUNT) {
    resentSearches.pop()
  }

  resentSearches.unshift(searchToSave)
  safeWebLocalStorage.setItem(RESENT_SEARCHES_KEY, JSON.stringify(resentSearches))
}

export const getDataToDisplay = (
  items: QuickFindMenuItem[],
  searchValue: string,
): QuickFindMenuItem[] => {
  if (!searchValue) {
    // Why ts does not complain about null? :)
    const result: QuickFindMenuItem[] = new Array(MAX_ITEMS_COUNT).fill(null)
    const recentSearches = getResentSearches()
    const recentSearchesHash = recentSearches.reduce((acc, search, index) => {
      acc[search] = index
      return acc
    }, {} as { [search: string]: number })

    // If item's gid is in recentSearchesHash, it means that this item was recently searched
    // Add in the order of search history
    let itemsCountRemaining = MAX_ITEMS_COUNT
    for (const item of items) {
      const index = recentSearchesHash[item.gid]
      if (index !== undefined) {
        result[index] = item
        itemsCountRemaining--
      }

      // No need to go through all items if we already have all needed
      if (itemsCountRemaining === 0) {
        break
      }
    }

    // Fill all the null results with random items
    let allItemsIndex = 0
    for (const itemInResultIdx in result) {
      const itemInResult = result[itemInResultIdx]
      if (itemInResult !== null) continue

      while (allItemsIndex < items.length) {
        const item = items[allItemsIndex]
        allItemsIndex++

        if (!recentSearchesHash[item.gid]) {
          result[itemInResultIdx] = item
          break
        }
      }
    }

    return result
  }

  const filteredItems = filterBySearchValue(items, searchValue)
  return filteredItems.slice(0, MAX_ITEMS_COUNT)
}
