import { gql } from 'graphql-request'
import {
  createCurrentDate,
  DEFAULT_TIMEZONE,
  endOfDay,
  formatISO,
  startOfDay,
  startOfMonth,
  utcToZonedTime,
} from '@expane/date'
import { Maybe } from 'graphql/jsutils/Maybe'
import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import {
  ServerBookingAsClientType,
  ServerBookingBoolExp,
  ServerBookingByIdType,
  ServerBookingHistoryType,
  ServerCheckAvailabilityForBookingRoles,
  ServerOrderBy,
  ServerTimeOption,
  ServerTransactionInBookingType,
} from '../../generated/graphql-types'
import { EmployeeWithSchedule } from '../employee'
import {
  AVAILABILITY_CLIENTS_FOR_BOOKING_QUERY_KEY,
  AVAILABILITY_FOR_BOOKING_QUERY_KEY,
  BOOKING_AS_CLIENT_QUERY_KEY,
  BOOKING_HISTORY_QUERY_KEY,
  BOOKINGS_BY_IDS_QUERY_KEY,
  BOOKINGS_QUERY_KEY,
} from './queryKeys'
import { bookingByIdQueryKeys, parseDatesInBookingGqlResponse, WarningType } from './logic'
import {
  bookingAsClientFragment,
  bookingByIdFragment,
  bookingHistoryFragment,
} from './booking.fragments'
import { SERVICE_TYPE } from '../service'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { transactionInBookingFragment } from '../transaction/transaction.fragments'

export type NotGroupBooking = Omit<
  ServerBookingByIdType,
  'bookingClients' | 'service' | 'isGroupBooking' | 'employee'
> & {
  isGroupBooking: false
  employee: EmployeeWithSchedule
}
export type GroupBooking = Omit<
  ServerBookingByIdType,
  'client' | 'bookingServices' | 'isGroupBooking' | 'employee'
> & {
  isGroupBooking: true
  employee: Maybe<EmployeeWithSchedule>
}
export type BookingUnion = NotGroupBooking | GroupBooking

const BOOKING_BY_DATE_GQL_WITHOUT_CANCELED = gql`
  ${bookingByIdFragment}
  query ($startDate: timestamptz!, $endDate: timestamptz!, $branchId: Int!) {
    bookings(
      order_by: { startDate: asc }
      where: {
        canceledDate: { _is_null: true }
        startDate: { _gte: $startDate, _lt: $endDate }
        branchId: { _eq: $branchId }
      }
    ) {
      ...bookingByIdType
    }
  }
`
const BOOKING_BY_DATE_GQL_WITH_CANCELED = gql`
  ${bookingByIdFragment}
  query ($startDate: timestamptz!, $endDate: timestamptz!, $branchId: Int!) {
    bookings(
      order_by: { startDate: asc }
      where: { startDate: { _gte: $startDate, _lt: $endDate }, branchId: { _eq: $branchId } }
    ) {
      ...bookingByIdType
    }
  }
`

export function useFetchBookingsOfDate(
  date: Date | Date[],
  timezone: string | undefined,
  branchId: number | undefined,
  options?: { includeCanceled?: boolean },
) {
  const includeCanceled = options?.includeCanceled ?? false
  let startDate: Date
  let endDate: Date
  if (Array.isArray(date)) {
    startDate = startOfDay(date[0])
    endDate = endOfDay(date[1])
  } else {
    startDate = startOfDay(date)
    endDate = endOfDay(startDate)
  }

  const queryKey = [
    ...bookingByIdQueryKeys,
    { startDate: formatISO(startDate), endDate: formatISO(endDate), branchId },
  ]
  if (includeCanceled) queryKey.push('with cancelled')

  return useQuery<BookingUnion[]>(
    queryKey,
    async () => {
      const dto = { startDate, endDate, branchId }
      const result = await request(
        includeCanceled ? BOOKING_BY_DATE_GQL_WITH_CANCELED : BOOKING_BY_DATE_GQL_WITHOUT_CANCELED,
        dto,
      )

      if (Array.isArray(result?.bookings)) {
        return result.bookings.map(booking =>
          parseDatesInBookingGqlResponse(booking, timezone ?? DEFAULT_TIMEZONE),
        )
      }

      reportError(new Error('bookings is not an array'), 'warning', { result })
      return []
    },
    {
      queryName: 'useFetchBookingsOfDate',
      onError: reportGqlError,
      onSuccess: bookings =>
        bookings.forEach(booking =>
          queryClient.setQueryData([...bookingByIdQueryKeys, booking.id], booking),
        ),
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchBookingById(
  id: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<BookingUnion | undefined>(
    [...bookingByIdQueryKeys, id],
    async () => {
      const dto = { id, branchId }
      const result = await request(
        gql`
          ${bookingByIdFragment}
          query ($id: Int!, $branchId: Int!) {
            bookingById(id: $id) {
              ...bookingByIdType
            }
          }
        `,
        dto,
      )

      if (result?.bookingById) {
        return parseDatesInBookingGqlResponse(result.bookingById, timezone ?? DEFAULT_TIMEZONE)
      }

      reportError(new Error('bookingById does not exist'), 'warning', { dto, result })
      return undefined
    },
    {
      queryName: 'useFetchBookingById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchBookingsByIds(
  ids: number[],
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<BookingUnion[]>(
    [
      ...bookingByIdQueryKeys,
      BOOKINGS_BY_IDS_QUERY_KEY,
      {
        ids,
        timezone,
        branchId,
      },
    ],
    async () => {
      const dto = { ids, branchId }
      const result = await request(
        gql`
          ${bookingByIdFragment}
          query ($ids: [Int!], $branchId: Int!) {
            bookings(where: { id: { _in: $ids } }) {
              ...bookingByIdType
            }
          }
        `,
        dto,
      )

      if (Array.isArray(result?.bookings)) {
        return result.bookings.map(booking =>
          parseDatesInBookingGqlResponse(booking, timezone ?? DEFAULT_TIMEZONE),
        )
      }

      reportError(new Error('BookingsByIds is not an array'), 'warning', { result, dto })
      return []
    },
    {
      queryName: 'useFetchBookingsByIds',
      onError: reportGqlError,
      enabled: Boolean(ids.length) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchBookingsByEmployeeId(
  employeeId: number | undefined | null,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<BookingUnion[] | undefined>(
    [...bookingByIdQueryKeys, { employeeId, branchId }],
    async () => {
      const result = await request(
        gql`
          ${bookingByIdFragment}
          query ($employeeId: Int!, $branchId: Int!) {
            bookings(
              where: {
                employeeId: { _eq: $employeeId }
                canceledDate: { _is_null: true }
                branchId: { _eq: $branchId }
              }
            ) {
              ...bookingByIdType
            }
          }
        `,
        { employeeId, branchId },
      )

      if (result.bookings) {
        return result.bookings.map(booking =>
          parseDatesInBookingGqlResponse(booking, timezone ?? DEFAULT_TIMEZONE),
        )
      }

      reportError(new Error('Error while trying to return bookings'), 'error', {
        employeeId,
        result,
      })

      return undefined
    },
    {
      queryName: 'useFetchBookingsByEmployeeId',
      onError: reportGqlError,
      enabled: Boolean(employeeId) && Boolean(timezone) && Boolean(branchId),
      refetchOnWindowFocus: 'always',
      refetchOnMount: 'always',
      refetchInterval: 30 * 1000,
    },
  )
}

type CheckAvailabilityForBookingAsClientType = {
  role: ServerCheckAvailabilityForBookingRoles.Client
  date: Date
  servicesIds: number[]
  employeeId?: number
  branchId: number | undefined
}

type CheckAvailabilityForBookingAsEmployeeCommonType = {
  role: ServerCheckAvailabilityForBookingRoles.Employee
  date: Date
  servicesIds: number[]
  employeeId?: number
  branchId: number | undefined
  locationId: number | undefined
}

type CheckAvailabilityForSingleBookingAsEmployeeType =
  CheckAvailabilityForBookingAsEmployeeCommonType & {
    clientId: number
  }

type CheckAvailabilityForGroupBookingAsEmployeeType =
  CheckAvailabilityForBookingAsEmployeeCommonType & {
    clientIds: number[]
  }

type CheckAvailabilityForBookingType =
  | CheckAvailabilityForBookingAsClientType
  | CheckAvailabilityForSingleBookingAsEmployeeType
  | CheckAvailabilityForGroupBookingAsEmployeeType

export type TimeOption = Omit<ServerTimeOption, 'employeeId'> & {
  date: Date
  employeeId?: number
  locationId: number
  warningType: WarningType | null
}

export function useCheckAvailabilityForBooking(
  dto: CheckAvailabilityForBookingType,
  timezone: string | undefined,
) {
  return useQuery<TimeOption[]>(
    [
      AVAILABILITY_FOR_BOOKING_QUERY_KEY,
      dto.role,
      dto.date,
      dto.servicesIds,
      dto.employeeId,
      dto.branchId,
      dto.role === 'employee' ? dto.locationId : undefined,
    ],
    async () => {
      const result = await request<{ checkAvailabilityForBooking: TimeOption[] }>(
        gql`
          query (
            $date: timestamptz!
            $employeeId: Int
            $servicesIds: [Int!]!
            $clientId: Int
            $clientIds: [Int!]
            $role: checkAvailabilityForBookingRoles!
            $branchId: Int!
            $locationId: Int
          ) {
            checkAvailabilityForBooking(
              date: $date
              role: $role
              clientId: $clientId
              clientIds: $clientIds
              employeeId: $employeeId
              servicesIds: $servicesIds
              branchId: $branchId
              locationId: $locationId
            ) {
              date
              locationId
              employeeId
              warningType
            }
          }
        `,
        dto,
      )

      if (Array.isArray(result?.checkAvailabilityForBooking)) {
        return result.checkAvailabilityForBooking.map(timeOption => ({
          ...timeOption,
          date: utcToZonedTime(timeOption.date, timezone ?? DEFAULT_TIMEZONE),
        }))
      }

      return []
    },
    {
      queryName: 'useCheckAvailabilityForBooking',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(dto.branchId),
    },
  )
}

export const useCheckClientsAvailabilityForBooking = ({
  startDate,
  endDate,
  branchId,
}: {
  startDate: Date
  endDate: Date
  branchId: number | undefined
}) => {
  return useQuery<number[]>(
    [AVAILABILITY_CLIENTS_FOR_BOOKING_QUERY_KEY, startDate, endDate, branchId],
    async () => {
      const result = await request<{ getAvailableClients: { clientsIds: number[] } }>(
        gql`
          query ($startDate: timestamptz!, $endDate: timestamptz!, $branchId: Int!) {
            getAvailableClients(startDate: $startDate, endDate: $endDate, branchId: $branchId) {
              clientsIds
            }
          }
        `,
        { startDate, endDate, branchId },
      )

      if (result?.getAvailableClients) {
        return result.getAvailableClients.clientsIds
      }

      return []
    },
    {
      queryName: 'useCheckClientsAvailabilityForBooking',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

type BookingHistoryType = ServerBookingHistoryType & {
  transactions: ServerTransactionInBookingType[]
}
export type HistoryNotGroupBooking = Omit<BookingHistoryType, 'service' | 'isGroupBooking'> & {
  isGroupBooking: false
}
export type HistoryGroupBooking = Omit<BookingHistoryType, 'bookingServices' | 'isGroupBooking'> & {
  isGroupBooking: true
}
export type HistoryBookingUnion = HistoryNotGroupBooking | HistoryGroupBooking

// TODO: Add pagination
export function useFetchClientBookingHistory(
  id: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<HistoryBookingUnion[] | undefined>(
    [
      BOOKINGS_QUERY_KEY,
      BOOKING_HISTORY_QUERY_KEY,
      TRANSACTIONS_QUERY_KEY,
      { clientId: id, branchId },
    ],
    async () => {
      if (id === undefined) return undefined
      const result = await request(
        gql`
          ${bookingHistoryFragment}
          ${transactionInBookingFragment}
          query ($id: Int!, $branchId: Int!) {
            bookings(
              where: {
                branchId: { _eq: $branchId }
                _or: [{ clientId: { _eq: $id } }, { bookingClients: { clientId: { _eq: $id } } }]
              }
              order_by: { startDate: desc }
            ) {
              ...bookingHistoryType
              transactions(where: { clientId: { _eq: $id } }) {
                ...transactionInBookingType
              }
            }
          }
        `,
        { id, branchId },
      )

      if (Array.isArray(result?.bookings)) {
        return result.bookings.map(booking =>
          parseDatesInBookingGqlResponse(booking, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        return undefined
      }
    },
    {
      queryName: 'useFetchClientBookingHistory',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

// --- AS CLIENT ---
export type BookingsView = 'future' | 'completed'

export function useFetchBookingByIdAsClient(
  bookingId: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerBookingAsClientType | undefined>(
    [BOOKINGS_QUERY_KEY, BOOKING_AS_CLIENT_QUERY_KEY, bookingId, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${bookingAsClientFragment}
          query ($bookingId: Int!, $branchId: Int!) {
            bookingById(id: $bookingId) {
              ...bookingAsClientType
            }
          }
        `,
        { bookingId, branchId },
      )

      if (!result?.bookingById) {
        reportError(new Error('Error while fetching booking by id'), 'error', { bookingId })
        return
      }

      const { bookingById } = result

      return parseDatesInBookingGqlResponse(bookingById, timezone ?? DEFAULT_TIMEZONE)
    },
    {
      queryName: 'useFetchBookingByIdAsClient',
      enabled: Boolean(bookingId) && Boolean(timezone) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}

export function useFetchBookingsAsClient({
  timezone,
  type,
  enabled,
  branchId,
}: {
  timezone: string | undefined
  type?: BookingsView
  enabled?: boolean
  branchId: number | undefined
}) {
  const startDate = createCurrentDate(timezone)
  const requestType = type ?? 'future'

  const bookingBoolExp: ServerBookingBoolExp = {
    branchId: { _eq: branchId },
    startDate: { ...(requestType === 'future' ? { _gte: startDate } : { _lte: startDate }) },
    canceledDate: { _is_null: true },
  }

  const orderByOperator = requestType === 'future' ? ServerOrderBy.Asc : ServerOrderBy.Desc

  return useQuery<ServerBookingAsClientType[] | undefined>(
    [BOOKINGS_QUERY_KEY, BOOKING_AS_CLIENT_QUERY_KEY, requestType, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${bookingAsClientFragment}
          # branchId використовується всередині bookingAsClientFragment
          query ($branchId: Int!, $bookingBoolExp: booking_bool_exp!, $orderByOperator: order_by!) {
            bookings(where: $bookingBoolExp, order_by: { startDate: $orderByOperator }) {
              ...bookingAsClientType
            }
          }
        `,
        { bookingBoolExp, branchId, orderByOperator },
      )

      if (Array.isArray(result.bookings)) {
        return result.bookings.map(booking =>
          parseDatesInBookingGqlResponse(booking, timezone ?? DEFAULT_TIMEZONE),
        )
      }

      reportError(new Error('Error while trying to return bookings as client'), 'error', {
        startDate,
        result,
      })
    },
    {
      queryName: 'useFetchBookingsAsClient',
      onError: reportGqlError,
      refetchOnWindowFocus: 'always',
      refetchOnMount: 'always',
      refetchInterval: 30 * 1000,
      enabled: enabled && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchBookingsByStartOfMonthDateByService(
  date: Date,
  serviceId: number,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  const startDate = startOfMonth(date)

  return useQuery<ServerBookingAsClientType[]>(
    [BOOKINGS_QUERY_KEY, BOOKING_AS_CLIENT_QUERY_KEY, startDate, serviceId],
    async () => {
      const result = await request(
        gql`
          ${bookingAsClientFragment}
          query ($startDate: timestamptz!, $type: smallint!, $serviceId: Int!, $branchId: Int!) {
            bookings(
              order_by: { startDate: asc }
              where: {
                canceledDate: { _is_null: true }
                startDate: { _gte: $startDate }
                service: { type: { _eq: $type } }
                serviceId: { _eq: $serviceId }
              }
            ) {
              ...bookingAsClientType
            }
          }
        `,
        { startDate, type: SERVICE_TYPE.group, serviceId, branchId },
      )

      if (Array.isArray(result?.bookings)) {
        return result.bookings.map(booking =>
          parseDatesInBookingGqlResponse(booking, timezone ?? DEFAULT_TIMEZONE),
        )
      }

      return []
    },
    {
      queryName: 'useFetchBookingsByStartOfMonthDateByService',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}
