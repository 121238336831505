import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enFile from './en.json'
import ruFile from './ru.json'
import ukFile from './uk.json'
import { getI18nInitSettings, getWebPreferredUserLanguage } from '@expane/i18n'

export const LANGUAGE_LOCAL_STORAGE_KEY = 'language'

i18next.use(initReactI18next).init(
  getI18nInitSettings({
    resources: { ukFile, ruFile, enFile },
    localLanguage: getWebPreferredUserLanguage(LANGUAGE_LOCAL_STORAGE_KEY),
  }),
)

export default i18next
