import {
  BranchWithSchedule,
  EmployeeScheduleDto,
  ScheduleDto,
  ServerEmployeeType,
  useCreateEmployeeSchedule,
  useFetchBranchById,
  useFetchBranches,
  useFetchEmployeeById,
  useFetchEmployeeScheduleById,
  useFetchSchedules,
  useMutateDynamicDates,
  validateEmployeeScheduleStartDate,
  WeeklySchedule,
} from '@expane/data'
import { createCurrentDate, isAfter, isBefore, startOfDay, zonedTimeToUtc } from '@expane/date'
import { getIsScheduleTypeDynamic, SCHEDULE_TYPES } from '@expane/logic/employeeSchedule'
import { permissions } from '@expane/logic/permission'
import { transformPersonName } from '@expane/logic/utils'
import {
  CloseButton,
  Dialog,
  Modal,
  PlaceholderInput,
  RadioGroupButtons,
  SelectDropdown,
  Spinner,
  usePopupOpenState,
} from '@expane/ui'
import { DateTimePicker, useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { translateData } from 'logic/utils'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { useOpenScheduleDialog } from 'widgets/ScheduleDialog'
import { getDefaultValuesForForm, ScheduleDialogFormValues } from './logic'
import { ScheduleCalendar } from './ScheduleCalendar'

type CreateEmployeeScheduleDialogProps = {
  closeDialog: () => void
  employeeId: number
  isCreate: true
  employeeScheduleId: null
}
type EditEmployeeScheduleDialogProps = {
  closeDialog: () => void
  employeeId: null
  isCreate: false
  employeeScheduleId: number
}
type EmployeeScheduleDialogProps =
  | CreateEmployeeScheduleDialogProps
  | EditEmployeeScheduleDialogProps

const EmployeeScheduleDialog: FC<EmployeeScheduleDialogProps> = ({
  closeDialog,
  employeeScheduleId,
  isCreate,
  employeeId,
}) => {
  const mutableCloseFunction = useRef(closeDialog)
  const branchId = store.branch.branchId
  const { data: branch } = useFetchBranchById(branchId)
  const { data: branches, isLoading: isLoadingBranches } = useFetchBranches()
  const { data: myPermissions, isLoading: isMyPermissionsLoading } = useFetchMyPermissions()
  const branchSchedule = branch?.schedule
  const timezone = branch?.timezone

  const { data: employeeScheduleById, isLoading: isLoadingEmployeeSchedule } =
    useFetchEmployeeScheduleById(isCreate ? undefined : employeeScheduleId, timezone)
  const { data: employee, isLoading: isLoadingEmployee } = useFetchEmployeeById(
    isCreate ? employeeId : employeeScheduleById?.employeeId,
    timezone,
  )
  const isLoadingEmployeeScheduleEnsureIsCreate = isCreate ? false : isLoadingEmployeeSchedule
  const { data: schedules, isLoading: isLoadingSchedules } = useFetchSchedules()

  const isLoading =
    isLoadingEmployeeScheduleEnsureIsCreate ||
    isLoadingSchedules ||
    isMyPermissionsLoading ||
    isLoadingEmployee ||
    isLoadingBranches ||
    !timezone

  const isNoData =
    (!isCreate && !employeeScheduleById) ||
    !schedules ||
    !branchSchedule ||
    !myPermissions ||
    !employee ||
    !branches

  useErrorOpeningDialog(!isLoading && isNoData, mutableCloseFunction.current)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={mutableCloseFunction.current}>
      <Dialog>
        {isLoading ? (
          <EmployeeScheduleDialogPlaceholder
            closeDialog={mutableCloseFunction.current}
            branches={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              branches!
            }
          />
        ) : (
          // @ts-expect-error employeeId can be null or number
          <LoadedEmployeeScheduleDialog
            branchId={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              branchId!
            }
            timezone={timezone}
            employeeId={employeeId}
            employee={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              employee!
            }
            myPermissions={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              myPermissions!
            }
            employeeScheduleById={employeeScheduleById}
            isCreate={isCreate}
            mutableCloseFunction={mutableCloseFunction}
            closeDialog={closeDialog}
            schedules={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              schedules!
            }
            branchSchedule={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              branchSchedule!
            }
            branches={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              branches!
            }
          />
        )}
      </Dialog>
    </Modal>
  )
}

type LoadedCommonEmployeeScheduleDialogProps = {
  branchSchedule: WeeklySchedule
  branches: BranchWithSchedule[]
  closeDialog: () => void
  employee: ServerEmployeeType
  mutableCloseFunction: MutableRefObject<() => void>
  myPermissions: string[]
  schedules: ScheduleDto[]
  timezone: string
  branchId: number
}

type LoadedCreateEmployeeScheduleDialogProps = LoadedCommonEmployeeScheduleDialogProps & {
  employeeId: number
  employeeScheduleById: undefined
  isCreate: true
}
type LoadedEditEmployeeScheduleDialogProps = LoadedCommonEmployeeScheduleDialogProps & {
  employeeId: null
  employeeScheduleById: EmployeeScheduleDto
  isCreate: false
}

type LoadedEmployeeScheduleDialogProps =
  | LoadedCreateEmployeeScheduleDialogProps
  | LoadedEditEmployeeScheduleDialogProps

const LoadedEmployeeScheduleDialog: FC<LoadedEmployeeScheduleDialogProps> = ({
  branchSchedule,
  branches,
  closeDialog,
  employee,
  employeeId,
  employeeScheduleById,
  isCreate,
  mutableCloseFunction,
  myPermissions,
  schedules,
  timezone,
  branchId,
}) => {
  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()
  const defaultValues = getDefaultValuesForForm(
    employeeScheduleById,
    timezone,
    isCreate ? branchId : employeeScheduleById?.branch.id,
  )
  const [pickedDate, setPickedDate] = useState(
    getDefaultPickedDate(
      defaultValues.scheduleStartDate,
      employeeScheduleById?.endDate ?? null,
      timezone,
    ),
  )

  const { formState, control, handleSubmit, setValue } = useForm<ScheduleDialogFormValues>({
    defaultValues,
  })
  const scheduleType = useWatch({
    control,
    name: 'scheduleType',
  })
  const isScheduleTypeDynamic = getIsScheduleTypeDynamic(scheduleType.id)
  const defaultDynamicDates = [...defaultValues.dynamicDates]

  const pickedScheduleId = useWatch({
    control,
    name: 'schedule',
  })
  const pickedSchedule = schedules?.find(schedule => schedule.id === pickedScheduleId)

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const { openEditDialog, dialog } = useOpenScheduleDialog()
  useEffect(() => {
    // посылаем модифицированное закрытие через нажатие на фон
    mutableCloseFunction.current = closePopups
  }, [closePopups, mutableCloseFunction])

  const { mutateAsync: createEmployeeSchedule } = useCreateEmployeeSchedule()
  const { mutateAsync: createDynamicDates } = useMutateDynamicDates()

  if (!timezone) return null

  const isEditAllowed = myPermissions.includes(permissions.schedule.set)
  const isEditPastAllowed = myPermissions.includes(permissions.schedule.editPast)

  const submitHandler: SubmitHandler<ScheduleDialogFormValues> = async data => {
    const isDynamic = getIsScheduleTypeDynamic(data.scheduleType.id)

    if (isCreate) {
      try {
        await createEmployeeSchedule({
          employeeScheduleInsertInput: {
            scheduleId: data.schedule,
            employeeId,
            startDate: zonedTimeToUtc(data.scheduleStartDate, timezone),
            isDynamic,
            dynamicDates: isDynamic
              ? {
                  data: data.dynamicDates.map(dynamicDate => ({
                    ...dynamicDate,
                    date: zonedTimeToUtc(dynamicDate.date, timezone),
                    generatedId: undefined,
                  })),
                }
              : undefined,
            branchId: data.branchId ?? branchId,
          },
        })
        openSnackbar(t('schedule.successfullyReassigned'), 'success')
      } catch (error) {
        openSnackbar(t('submitError'), 'error')
      }
    } else {
      try {
        if (isDynamic) {
          const dynamicDateIdsToDelete = defaultValues.dynamicDates.reduce((ids, dynamicDate) => {
            const presentDynamicDate = data.dynamicDates.some(
              presentDynamicDate => presentDynamicDate.id === dynamicDate.id,
            )
            if (!presentDynamicDate) ids.push(dynamicDate.id)
            return ids
          }, [] as string[])
          await createDynamicDates({
            dynamicScheduleDates: data.dynamicDates.map(dynamicDate => ({
              ...dynamicDate,
              date: zonedTimeToUtc(dynamicDate.date, timezone),
              generatedId: undefined,
              employeeScheduleId: employeeScheduleById.id,
            })),
            dynamicDateIdsToDelete,
          })

          openSnackbar(t('schedule.successfullyReassigned'), 'success')
        }
      } catch (error) {
        openSnackbar(t('submitError'), 'error')
      }
    }
    closeDialog()
  }

  return (
    <>
      <Dialog.Title>
        {t('schedule.name')} - {transformPersonName(employee)}
      </Dialog.Title>

      <Dialog.Body className="w-246 h-118">
        <div className="flex flex-col overflow-y-auto h-full">
          <div className="flex gap-2 w-full">
            <Controller
              name="scheduleType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroupButtons
                  label={t('schedule.type.name')}
                  options={translateData(Object.values(SCHEDULE_TYPES), t)}
                  value={value}
                  className="w-1/4"
                  onChange={option => {
                    onChange(option)

                    const isScheduleTypeDynamic = getIsScheduleTypeDynamic(option.id)
                    if (isScheduleTypeDynamic) setValue('schedule', null)
                  }}
                  disabled={!isCreate}
                />
              )}
            />

            {!isScheduleTypeDynamic && (
              <Controller
                name="schedule"
                control={control}
                rules={{
                  required: !isScheduleTypeDynamic,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <SelectDropdown
                    value={value ?? undefined}
                    label={t('schedule.name')}
                    items={schedules}
                    placeholder={t('chooseTemplate')}
                    noDataMessage={t('noSchedule')}
                    onSelectChange={onChange}
                    className="w-1/4"
                    disabled={!isCreate}
                    onEditClick={id => openEditDialog(id)}
                    required={!isScheduleTypeDynamic}
                    errorMessage={{
                      isShown: Boolean(error),
                      text: t('formError.required'),
                    }}
                  />
                )}
              />
            )}

            <Controller
              name="scheduleStartDate"
              control={control}
              rules={{
                required: true,
                validate: {
                  isDateValid: async startDate => {
                    if (!employeeId) return true
                    return await validateEmployeeScheduleStartDate(startDate, employeeId, branchId)
                  },
                  past: startDate => {
                    if (
                      isBefore(startDate, startOfDay(createCurrentDate(timezone))) &&
                      !isEditPastAllowed
                    )
                      return false

                    return true
                  },
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DateTimePicker
                  timezone={timezone}
                  required
                  label={t('startDate')}
                  value={value}
                  type="date"
                  onChange={date => {
                    onChange(date)
                    setPickedDate(date)
                  }}
                  className="w-1/4"
                  disabled={!isCreate}
                  errorMessage={{
                    isShown: Boolean(error),
                    text:
                      error?.type === 'past'
                        ? t('schedule.editPast')
                        : t('schedule.startDateError'),
                  }}
                />
              )}
            />

            {branches.length > 1 && (
              <Controller
                name="branchId"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <SelectDropdown
                    value={value}
                    onSelectChange={onChange}
                    className="w-1/4"
                    label={t('branch.name')}
                    items={branches}
                    disabled={!isCreate}
                    required
                    errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                  />
                )}
              />
            )}
          </div>

          <DateTimePicker
            timezone={timezone}
            type="week"
            className="w-1/4 pl-2 mb-1 self-end"
            onChange={setPickedDate}
            value={pickedDate}
            customDatePopupPlacement="bottom-start"
            nextPreviousButtons
          />

          <div className="overflow-auto flex-1">
            <ScheduleCalendar
              branchSchedule={branchSchedule}
              isEditAllowed={isEditAllowed}
              isEditPastAllowed={isEditPastAllowed}
              pickedSchedule={pickedSchedule}
              control={control}
              defaultDynamicDates={defaultDynamicDates}
              pickedDate={pickedDate}
              scheduleEndDate={employeeScheduleById?.endDate ?? undefined}
            />
          </div>

          {dialog}
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        {(isCreate || (isScheduleTypeDynamic && isEditAllowed)) && (
          <SaveButton
            onClick={handleSubmit(submitHandler)}
            disabled={!formState.isDirty || formState.isSubmitting}
            spinner={formState.isSubmitting}
            isCreate={isCreate}
          />
        )}

        <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />
      </Dialog.Footer>
      {confirmPopup}
    </>
  )
}

const EmployeeScheduleDialogPlaceholder: FC<{
  branches: BranchWithSchedule[]
  closeDialog: () => void
}> = ({ branches, closeDialog }) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('schedule.name')}</Dialog.Title>
      <Dialog.Body className="w-246 h-118">
        <div className="flex flex-col overflow-y-auto h-full">
          <div className="flex w-full gap-2">
            <RadioGroupButtons
              label={t('schedule.type.name')}
              options={translateData(Object.values(SCHEDULE_TYPES), t)}
              value={SCHEDULE_TYPES.schedule}
              className="w-1/4"
              disabled
              onChange={() => {
                return
              }}
            />

            <PlaceholderInput label={t('schedule.name')} className="w-1/4" />
            <PlaceholderInput label={t('startDate')} className="w-1/4" />
            {branches.length > 1 && <PlaceholderInput label={t('branch.name')} className="w-1/4" />}
          </div>

          <PlaceholderInput className="w-1/4 self-end" />

          <div className="overflow-auto flex-1 pr-2">
            <Spinner expandCentered />
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <CloseButton onClick={closeDialog} disabled />
      </Dialog.Footer>
    </>
  )
}

export const useEmployeeScheduleDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()
  const dialogIsCreate = useRef(true)
  const dialogEmployeeId = useRef<number | null>(null)
  const dialogEmployeeScheduleId = useRef<number | null>(null)

  const openCreateEmployeeScheduleDialog = (employeeId: number) => {
    dialogIsCreate.current = true
    dialogEmployeeId.current = employeeId
    dialogEmployeeScheduleId.current = null
    openPopup()
  }
  const openEditEmployeeScheduleDialog = (employeeScheduleId: number) => {
    dialogIsCreate.current = false
    dialogEmployeeId.current = null
    dialogEmployeeScheduleId.current = employeeScheduleId
    openPopup()
  }

  const employeeScheduleDialog = isOpen ? (
    // @ts-expect-error TS can't understand that employeeId can be number or null
    <EmployeeScheduleDialog
      isCreate={dialogIsCreate.current}
      employeeId={dialogEmployeeId.current}
      employeeScheduleId={dialogEmployeeScheduleId.current}
      closeDialog={closePopup}
    />
  ) : null

  return {
    employeeScheduleDialog,
    openCreateEmployeeScheduleDialog,
    openEditEmployeeScheduleDialog,
  }
}

const getDefaultPickedDate = (
  startDate: Date,
  endDate: Date | null,
  timezone: string | undefined,
) => {
  const today = startOfDay(createCurrentDate(timezone))
  const start = startOfDay(startDate)
  const end = endDate ? startOfDay(endDate) : null

  if (isBefore(today, start)) return start
  if (end && isAfter(today, end)) return end

  return today
}
